import { DEFAULT_CHAIN_ID, ETH_NETWORKS, FORTMATIC_API_KEY, PORTIS_DAPP_ID, PUBLIC_NETWORK_URL } from '@/constants';

import { FortmaticConnector } from '@web3-react/fortmatic-connector';
import { InjectedConnector } from '@web3-react/injected-connector';
import { PortisConnector } from '@web3-react/portis-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { WalletLinkConnector } from '@web3-react/walletlink-connector';

const SUPPORTED_NETWORK_IDS = Object.keys(ETH_NETWORKS).map((networkId) => parseInt(networkId));

export const injected = new InjectedConnector({
  supportedChainIds: SUPPORTED_NETWORK_IDS,
});

// single net only
export const portis = new PortisConnector({ dAppId: PORTIS_DAPP_ID as string, networks: [DEFAULT_CHAIN_ID] });

// single net only
export const fortmatic = new FortmaticConnector({ apiKey: FORTMATIC_API_KEY as string, chainId: DEFAULT_CHAIN_ID });

// mainnet only
export const walletconnect = new WalletConnectConnector({
  rpc: { 1: PUBLIC_NETWORK_URL[1] },
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
  pollingInterval: 15000,
});

// mainnet only
export const walletlink = new WalletLinkConnector({
  url: PUBLIC_NETWORK_URL[1],
  appName: 'Cozy',
});
