import { Box } from '@chakra-ui/react';
import React from 'react';
import Text from '@/components/shared/Text';

interface Props {
  amount: string;
  amountColor?: string;
  label: string;
  unit?: string;
}

const SmallStat: React.FC<Props> = ({ amount, amountColor, label, unit }: Props) => {
  return (
    <Box flex={1}>
      <Text fontSize="sm" color="gray.500" fontWeight="600" mb={1}>
        {label}
      </Text>
      <Text color={amountColor} fontSize="md" fontWeight="600">
        {amount} {unit}
      </Text>
    </Box>
  );
};
export default SmallStat;
