export default [
  {
    constant: false,
    inputs: [{ name: 'owner_', type: 'address' }],
    name: 'setOwner',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      { name: '_target', type: 'address' },
      { name: '_data', type: 'bytes' },
    ],
    name: 'execute',
    outputs: [{ name: 'response', type: 'bytes32' }],
    payable: true,
    stateMutability: 'payable',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      { name: '_code', type: 'bytes' },
      { name: '_data', type: 'bytes' },
    ],
    name: 'execute',
    outputs: [
      { name: 'target', type: 'address' },
      { name: 'response', type: 'bytes32' },
    ],
    payable: true,
    stateMutability: 'payable',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'cache',
    outputs: [{ name: '', type: 'address' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: false,
    inputs: [{ name: 'authority_', type: 'address' }],
    name: 'setAuthority',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'owner',
    outputs: [{ name: '', type: 'address' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: false,
    inputs: [{ name: '_cacheAddr', type: 'address' }],
    name: 'setCache',
    outputs: [{ name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'authority',
    outputs: [{ name: '', type: 'address' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ name: '_cacheAddr', type: 'address' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  { payable: true, stateMutability: 'payable', type: 'fallback' },
  {
    anonymous: true,
    inputs: [
      { indexed: true, name: 'sig', type: 'bytes4' },
      { indexed: true, name: 'guy', type: 'address' },
      { indexed: true, name: 'foo', type: 'bytes32' },
      { indexed: true, name: 'bar', type: 'bytes32' },
      { indexed: false, name: 'wad', type: 'uint256' },
      { indexed: false, name: 'fax', type: 'bytes' },
    ],
    name: 'LogNote',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: true, name: 'authority', type: 'address' }],
    name: 'LogSetAuthority',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: true, name: 'owner', type: 'address' }],
    name: 'LogSetOwner',
    type: 'event',
  },
];
