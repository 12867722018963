import { Box, Flex } from '@chakra-ui/react';

import React from 'react';
import Text from '@/components/shared/Text';

interface Props {
  color?: string;
  subValue: string;
  title: string;
  value: string;
}

const EarningsTabDetailsRow: React.FC<Props> = ({ color, subValue, title, value }: Props) => {
  return (
    <Flex justifyContent="space-between" alignItems="center" py="1rem" px="1rem">
      <Text color="gray.500" fontWeight="600" fontSize="md">
        {title}
      </Text>
      <Box textAlign="right">
        <Text fontWeight="600" fontSize="md" color={color ? color : null}>
          {value}
        </Text>
        <Text fontSize="sm">{subValue}</Text>
      </Box>
    </Flex>
  );
};
export default EarningsTabDetailsRow;
