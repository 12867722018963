const APPROVALS_POLLING_INTERVAL = 2500;

import React from 'react';
import { defaultProvider } from '@/helpers/transactionHelpers';
import erc20Abi from '@/abis/erc20Abi';
import { ethers } from 'ethers';
import { isEth } from '@/helpers/assetHelpers';
import { useAsync } from 'react-async-hook';

const NOTHING_APPROVED = { cozyIsApproved: false, investIsApproved: false, proxyIsApproved: false };

interface ApprovalsParams {
  chainId: number;
  investContractAddress?: string;
  marketContractAddress: string;
  proxyAddress: string;
  walletAddress: string;
  underlyingAddress: string;
}

const useCheckApprovals = ({
  chainId,
  investContractAddress,
  marketContractAddress,
  proxyAddress,
  walletAddress,
  underlyingAddress,
}: ApprovalsParams): {
  cozyIsApproved: boolean;
  investIsApproved: boolean;
  proxyIsApproved: boolean;
} => {
  const [result, setResult] = React.useState(NOTHING_APPROVED);
  const [checkSuccessful, setCheckSuccessful] = React.useState(false);

  const allowanceChecks = useAsync(checkAllowance, [
    chainId,
    investContractAddress,
    marketContractAddress,
    proxyAddress,
    walletAddress,
    underlyingAddress,
  ]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      allowanceChecks
        .execute(chainId, investContractAddress, marketContractAddress, proxyAddress, walletAddress, underlyingAddress)
        .then((result) => {
          setResult(result);
          setCheckSuccessful(true);
        })
        .catch((error) => {
          console.log('Error checking approvals', error);
          setResult(NOTHING_APPROVED);
        });
    }, APPROVALS_POLLING_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  });

  if (checkSuccessful) {
    return result;
  } else {
    if (allowanceChecks.status === 'success') {
      return allowanceChecks.result;
    } else {
      return NOTHING_APPROVED;
    }
  }
};

const checkAllowance = async (
  chainId,
  investContractAddress,
  marketContractAddress,
  proxyAddress,
  walletAddress,
  underlyingAddress,
): Promise<{ cozyIsApproved: boolean; investIsApproved: boolean; proxyIsApproved: boolean }> => {
  if (isEth(underlyingAddress)) {
    return { cozyIsApproved: true, investIsApproved: true, proxyIsApproved: true };
  }

  const provider = defaultProvider(chainId);

  const underlyingContract = new ethers.Contract(underlyingAddress, erc20Abi, provider);

  const cozyAllowance = proxyAddress ? await underlyingContract.allowance(proxyAddress, marketContractAddress) : 0;
  const investAllowance =
    investContractAddress && proxyAddress ? await underlyingContract.allowance(proxyAddress, investContractAddress) : 0;
  const proxyAllowance = proxyAddress ? await underlyingContract.allowance(walletAddress, proxyAddress) : 0;

  const cozyIsApproved = cozyAllowance > 0;
  const investIsApproved = investAllowance > 0;
  const proxyIsApproved = proxyAllowance > 0;

  return {
    cozyIsApproved,
    investIsApproved,
    proxyIsApproved,
  };
};

export default useCheckApprovals;
