import { Account, Comptroller, Market, PositionTypes } from '@/types';
import { Box, Flex, Modal, ModalContent, ModalOverlay, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import React, { useState } from 'react';

import ActivityTab from './manage_modal/ActivityTab/ActivityTab';
import BorrowBalanceTab from './manage_modal/BalanceTabs/BorrowBalanceTab';
import BorrowLimitModalPanel from './manage_modal/BorrowingPowerModalPanel/BorrowingPowerModalPanel';
import BorrowLimitModalPanelNoFundsState from './manage_modal/BorrowTab/BorrowTabNoFundsState';
import BorrowTab from './manage_modal/BorrowTab/BorrowTab';
import ConnectWalletGateModal from './ConnectWalletGateModal';
import ManageModalHeader from './manage_modal/ManageModalHeader';
import ManageModalTabLists from './manage_modal/ManageModalTabList';
import RepayTab from './manage_modal/RepayTab/RepayTab';
import TriggerPanel from './manage_modal/TriggerPanel';
import { calculateAdditionalBorrow } from '@/helpers/borrowingPowerHelpers';
import { calculateBorrowLimitStats } from '@/helpers/borrowLimitHelpers';
import { findPositionByMarketId } from '@/helpers/findHelpers';
import { useGetModalSize } from '@/helpers/manageModalHelpers';
import useManageRouterParams from '@/hooks/useManageRouterParams';
import { useWeb3React } from '@web3-react/core';

interface Props {
  account: Account;
  comptroller: Comptroller;
  markets: Market[];
  onClose: () => void;
}

export enum BorrowModalTabs {
  BorrowBalance,
  Borrow,
  Repay,
  Activity,
}

const TAB_NAMES = {
  [BorrowModalTabs.BorrowBalance]: 'Balance',
  [BorrowModalTabs.Borrow]: 'Borrow',
  [BorrowModalTabs.Repay]: 'Pay Back',
  [BorrowModalTabs.Activity]: 'Activity',
};

const TABS = [BorrowModalTabs.BorrowBalance, BorrowModalTabs.Borrow, BorrowModalTabs.Repay, BorrowModalTabs.Activity];

const SHOW_BORROW_LIMIT_TABS = [BorrowModalTabs.Borrow, BorrowModalTabs.Repay];

const BorrowModal: React.FC<Props> = ({ account, comptroller, markets, onClose }: Props) => {
  const { tab, market, setTab } = useManageRouterParams(markets);
  const { account: walletAccount } = useWeb3React();
  const [borrowAmount, setBorrowAmount] = useState('0');
  const [repayAmount, setRepayAmount] = useState('0');
  const hasTrigger = market?.triggerAddress != null;

  const { borrowLimitUsdTotal } = calculateBorrowLimitStats(account, 0, 0);

  const size = useGetModalSize(hasTrigger, walletAccount, borrowLimitUsdTotal);

  const onModalClose = () => {
    setBorrowAmount('0');
    setRepayAmount('0');
    onClose();
  };

  if (market == null) return null;

  const position = findPositionByMarketId(account, market.id);

  const usdAdditionalBorrow = calculateAdditionalBorrow(tab, borrowAmount, repayAmount, market, position);

  const showBorrowLimit = SHOW_BORROW_LIMIT_TABS.includes(tab);

  return (
    <Modal isOpen={market != null} onClose={onModalClose} size={size}>
      <ModalOverlay />
      <ModalContent backgroundColor="transparent">
        <Box backgroundColor="white" borderRadius="lg" mb={6}>
          <Flex direction={{ base: 'column', md: 'row' }}>
            <Box flex={1}>
              {walletAccount == null && <ConnectWalletGateModal />}
              {walletAccount != null && borrowLimitUsdTotal === 0 && <BorrowLimitModalPanelNoFundsState />}

              {walletAccount != null && borrowLimitUsdTotal != 0 && (
                <>
                  <ManageModalHeader market={market} />

                  <Tabs isFitted index={tab} onChange={setTab} borderBottomRadius="2xl">
                    <ManageModalTabLists tabs={TABS.map((tab) => TAB_NAMES[tab])} />
                    <TabPanels>
                      <TabPanel>
                        <BorrowBalanceTab market={market} position={position} />
                      </TabPanel>
                      <TabPanel>
                        <BorrowTab
                          account={account}
                          borrowAmount={borrowAmount}
                          comptroller={comptroller}
                          onModalClose={onModalClose}
                          market={market}
                          setBorrowAmount={setBorrowAmount}
                          usdAdditionalBorrow={usdAdditionalBorrow}
                        />
                      </TabPanel>
                      <TabPanel>
                        <RepayTab
                          account={account}
                          comptroller={comptroller}
                          market={market}
                          onModalClose={onModalClose}
                          position={position}
                          repayAmount={repayAmount}
                          setRepayAmount={setRepayAmount}
                        />
                      </TabPanel>
                      <TabPanel p={0}>
                        <ActivityTab account={account} market={market} positionType={PositionTypes.Borrow} />
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </>
              )}
            </Box>

            {borrowLimitUsdTotal > 0 && market.triggerAddress && (
              <TriggerPanel market={market} positionType={PositionTypes.Borrow} />
            )}
          </Flex>
        </Box>

        {showBorrowLimit && <BorrowLimitModalPanel account={account} usdAdditionalBorrow={usdAdditionalBorrow} />}
      </ModalContent>
    </Modal>
  );
};
export default BorrowModal;
