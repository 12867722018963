import { Account, EventTypes, Market, PositionTypes } from '@/types';

import { ActivityFilters } from '@/hooks/useActivityFilterRouterParams';
import ActivityItem from './ActivityItem';
import { Box } from '@chakra-ui/react';
import Divider from '@/components/shared/Divider';
import React from 'react';
import Text from '@/components/shared/Text';

interface Props {
  account: Account;
  filterBy?: ActivityFilters;
  limitNumItems?: number;
  market?: Market;
  positionType?: PositionTypes;
  seenNotificationIds?: string[];
}

const FILTER_BY_ALL = [
  EventTypes.BorrowEvent,
  EventTypes.MintEvent,
  EventTypes.RedeemEvent,
  EventTypes.RepayEvent,
  EventTypes.TriggerEvent,
  EventTypes.LiquidationEvent,
];

const FILTER_BY_FUNDS_ACTIVITY = [
  EventTypes.BorrowEvent,
  EventTypes.MintEvent,
  EventTypes.RedeemEvent,
  EventTypes.RepayEvent,
];

const FILTER_BY_LIQUIDATIONS = [EventTypes.LiquidationEvent];

const FILTER_BY_PROTECTION_ACTIVITY = [EventTypes.TriggerEvent];

const FILTER_BY_MAPPING = {
  [ActivityFilters.All]: FILTER_BY_ALL,
  [ActivityFilters.FundsActivity]: FILTER_BY_FUNDS_ACTIVITY,
  [ActivityFilters.Liquidations]: FILTER_BY_LIQUIDATIONS,
  [ActivityFilters.ProtectionActivity]: FILTER_BY_PROTECTION_ACTIVITY,
};

const EVENT_TYPE_POSITION_TYPE_MAPPING = {
  [PositionTypes.Borrow]: [
    EventTypes.BorrowEvent,
    EventTypes.RepayEvent,
    EventTypes.TriggerEvent,
    EventTypes.LiquidationEvent,
  ],
  [PositionTypes.Supply]: [
    EventTypes.MintEvent,
    EventTypes.RedeemEvent,
    EventTypes.TriggerEvent,
    EventTypes.LiquidationEvent,
  ],
};

const RecentActivity: React.FC<Props> = ({
  account,
  filterBy,
  limitNumItems = Infinity,
  market,
  positionType,
  seenNotificationIds,
}: Props) => {
  const activityItems = account?.activityItems;

  const filteredActivityItems = activityItems
    ?.filter((item) => {
      const eventTypeMatches = filterByEventType(filterBy, item.eventType);
      const marketMatches = market == null || market.id === item.event.marketId;
      const positionTypeMatches =
        positionType == null || EVENT_TYPE_POSITION_TYPE_MAPPING[positionType].includes(item.eventType);

      return eventTypeMatches && marketMatches && positionTypeMatches;
    })
    .slice(0, limitNumItems);

  return (
    <Box flex={1}>
      {(filteredActivityItems == null || filteredActivityItems?.length === 0) && (
        <Text textAlign="center" fontSize="sm" mt="3em" mx="2em">
          Activity and alerts about protection events will appear here
        </Text>
      )}

      {filteredActivityItems?.map((item, index) => {
        const isNotFinalItem = index != filteredActivityItems.length - 1;
        const hasBeenSeen = seenNotificationIds?.includes(item.event.id);
        const relevantPosition = account?.positions?.find((position) => position.marketId === item.event.marketId);

        return (
          <Box key={index}>
            <ActivityItem item={item} hasBeenSeen={hasBeenSeen} position={relevantPosition} />
            {isNotFinalItem && <Divider width={null} />}
          </Box>
        );
      })}
    </Box>
  );
};

const filterByEventType = (filterBy, eventType) => {
  if (filterBy == null) return true;

  const eventTypeList = FILTER_BY_MAPPING[filterBy];

  return eventTypeList.includes(eventType);
};

export default RecentActivity;
