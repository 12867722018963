import React, { createContext } from 'react';

import { useDisclosure } from '@chakra-ui/react';

export const WalletModalContext = createContext({ onClose: undefined, isOpen: undefined, onOpen: undefined });

interface Props {
  children: React.ReactNode;
}

export const WalletModalContextProvider = ({ children }: Props): JSX.Element => {
  const { onClose, isOpen, onOpen } = useDisclosure();

  return <WalletModalContext.Provider value={{ onClose, isOpen, onOpen }}> {children} </WalletModalContext.Provider>;
};
