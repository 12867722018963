export default [
  // Read-Only Functions
  'function allowance(address owner, address spender) view returns (uint256)',
  'function balanceOf(address owner) view returns (uint256)',
  'function decimals() view returns (uint8)',
  'function symbol() view returns (string)',

  // Authenticated Functions
  'function approve(address spender, uint value) returns (boolean)',
  'function transfer(address to, uint amount) returns (boolean)',
  'function transferFrom(address from, address to, uint amount) returns (boolean)',

  // Events
  'event Transfer(address indexed from, address indexed to, uint amount)',
];
