import Button from '@/components/shared/Button';
import { ButtonType } from '@/types';
import React from 'react';
import { useRouter } from 'next/router';

const BorrowLimitModalDepositButton: React.FC = () => {
  const router = useRouter();

  const { pathname } = router;

  if (pathname === '/funds') {
    return null;
  }

  return (
    <Button appButtonType={ButtonType.Primary} onClick={() => router.push('/funds')}>
      Deposit
    </Button>
  );
};
export default BorrowLimitModalDepositButton;
