import { useBalance, useErc20Balance } from '@/hooks/web3React';

import { HeadingProps } from '@chakra-ui/react';
import React from 'react';
import Skeleton from '../shared/Skeleton';
import Text from '@/components/shared/Text';
import { formatTokenAmount } from '@/helpers/formatHelpers';
import { isEth } from '@/helpers/assetHelpers';

interface Props extends HeadingProps {
  balanceLabel: string;
  tokenAddress: string;
  tokenDecimals: number;
  tokenSymbol: string;
}

const Balance = React.memo(
  ({ tokenAddress, balanceLabel, tokenSymbol, tokenDecimals, ...props }: Props): JSX.Element => {
    const ethBalance = useBalance();
    const erc20Balance = useErc20Balance(tokenAddress);

    const displayBalance = isEth(tokenAddress) ? ethBalance : erc20Balance;

    if (displayBalance == null) {
      return <Skeleton height={'10px'} width="80px" />;
    }

    const balanceValue = formatTokenAmount(displayBalance.toString(), tokenSymbol, tokenDecimals, 6);

    return (
      <Text {...props}>
        {balanceValue} {balanceLabel}
      </Text>
    );
  },
);

export default Balance;
