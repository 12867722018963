import { BigNumber, ethers } from 'ethers';
import { useEffect, useState } from 'react';
import { useWeb3React, useWeb3React as useWeb3ReactCore } from '@web3-react/core';

import { ETH_TOKEN_CONTRACT_ADDRESS } from '@/constants';
import React from 'react';
import { defaultProvider } from '@/helpers/transactionHelpers';
import erc20abi from '@/abis/erc20Abi';
import { injected } from '@/connectors';
import { isMobile } from 'react-device-detect';
import useCheckIfQueryParamsHydrated from '@/hooks/useCheckIfQueryParamsHydrated';

const BALANCE_POLLING_INTERVAL = 10000;

export const useEagerConnect = (): boolean => {
  const { activate, active } = useWeb3ReactCore(); // specifically using useWeb3ReactCore because of what this hook does
  const [tried, setTried] = useState(false);
  const queryParamsHydrated = useCheckIfQueryParamsHydrated();

  useEffect(() => {
    injected.isAuthorized().then((isAuthorized) => {
      if (isAuthorized) {
        if (queryParamsHydrated) {
          activate(injected, undefined, true)
            .then(() => setTried(true))
            .catch(() => {
              setTried(true);
            });
        }
      } else {
        if (isMobile && window.ethereum) {
          activate(injected, undefined, true).catch(() => {
            setTried(true);
          });
        } else {
          setTried(true);
        }
      }
    });
  }, [activate, queryParamsHydrated]); // intentionally only running on mount (make sure it's only mounted once :))

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (active) {
      setTried(true);
    }
  }, [active]);

  return tried;
};

/**
 * Use for network and injected - logs user in
 * and out after checking what network theyre on
 */
export const useInactiveListener = (suppress = false): void => {
  const { active, error, activate } = useWeb3ReactCore(); // specifically using useWeb3React because of what this hook does

  useEffect(() => {
    const { ethereum } = window;

    if (ethereum && ethereum.on && !active && !error && !suppress) {
      const handleChainChanged = () => {
        // eat errors
        activate(injected, undefined, true).catch((error) => {
          console.error('Failed to activate after chain changed', error);
        });
      };

      const handleAccountsChanged = (accounts: string[]) => {
        if (accounts.length > 0) {
          // eat errors
          activate(injected, undefined, true).catch((error) => {
            console.error('Failed to activate after accounts changed', error);
          });
        }
      };

      ethereum.on('chainChanged', handleChainChanged);
      ethereum.on('accountsChanged', handleAccountsChanged);

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener('chainChanged', handleChainChanged);
          ethereum.removeListener('accountsChanged', handleAccountsChanged);
        }
      };
    }
    return undefined;
  }, [active, error, suppress, activate]);
};

export const useBalance = (): BigNumber => {
  const { account, library, chainId } = useWeb3React();
  const [balance, setBalance] = React.useState();

  React.useEffect((): any => {
    if (!!account && !!library) {
      let stale = false;

      getEthBalance(account, library, setBalance, stale);

      const interval = setInterval(() => {
        getEthBalance(account, library, setBalance, stale);
      }, BALANCE_POLLING_INTERVAL);

      return () => {
        clearInterval(interval);
        stale = true;
        setBalance(undefined);
      };
    }
  }, [account, library, chainId]); // ensures refresh if referential identity of library doesn't change across chainIds

  return balance;
};

export const useErc20Balance = (contractAddress: string): BigNumber => {
  const { account, chainId } = useWeb3React();
  const [balance, setBalance] = React.useState(null);

  React.useEffect((): any => {
    let stale = false;

    const provider = defaultProvider(chainId);

    if (contractAddress != ETH_TOKEN_CONTRACT_ADDRESS) {
      const contract = new ethers.Contract(contractAddress, erc20abi, provider);
      getContractBalance(account, contract, setBalance, stale);

      const interval = setInterval(() => {
        getContractBalance(account, contract, setBalance, stale);
      }, BALANCE_POLLING_INTERVAL);

      return () => {
        clearInterval(interval);
        stale = true;
        setBalance(undefined);
      };
    }
  }, [account, chainId, contractAddress]);

  return balance;
};

const getEthBalance = (account, library, setBalance, stale) => {
  library
    .getBalance(account)
    .then((balance: any) => {
      if (!stale) {
        setBalance(balance);
      }
    })
    .catch(() => {
      if (!stale) {
        setBalance(null);
      }
    });
};

const getContractBalance = (account, contract, setBalance, stale) => {
  contract
    .balanceOf(account)
    .then((balance: BigNumber) => {
      if (!stale) {
        setBalance(balance);
      }
    })
    .catch(() => {
      if (!stale) {
        setBalance(null);
      }
    });
};
