import { Market, Position } from '@/types';

import Container from '@/components/shared/Container';
import Divider from '@/components/shared/Divider';
import EarningsTabDetailsRow from './EarningsTabDetailsRow';
import { MIN_DISPLAY_AMOUNT } from '@/helpers/formatHelpers';
import React from 'react';
import { calculatePositionStats } from '@/helpers/positionHelpers';
import { useTheme } from '@chakra-ui/react';

interface Props {
  isWithdrawSummary?: boolean;
  market: Market;
  position: Position;
}

const EarningsTabDetails: React.FC<Props> = ({ market, position, isWithdrawSummary }: Props) => {
  const theme = useTheme();

  const { successColor } = theme.utilityColors;
  const {
    formattedBorrowTokenBalance,
    formattedInvestTokenBalance,
    formattedNetInvestEarnings,
    formattedNetUSDInvestEarnings,
    formattedUSDBorrowBalance,
    formattedUSDInvestBalance,
    netUSDInvestEarnings,
  } = calculatePositionStats(position, market);

  return (
    <Container p="0">
      <EarningsTabDetailsRow
        value={formattedUSDInvestBalance}
        subValue={`${formattedInvestTokenBalance} ${market.underlyingSymbol}`}
        title={isWithdrawSummary ? 'Withdraw Investment' : 'Investment Value'}
      />
      <EarningsTabDetailsRow
        value={formattedUSDBorrowBalance}
        subValue={`${formattedBorrowTokenBalance} ${market.underlyingSymbol}`}
        title={isWithdrawSummary ? '- Pay Back Debt' : '-   Borrowed Debt'}
      />

      <Divider />
      <EarningsTabDetailsRow
        value={formattedNetUSDInvestEarnings}
        subValue={`${formattedNetInvestEarnings} ${market.underlyingSymbol}`}
        title="=   Net Earnings"
        color={netUSDInvestEarnings > MIN_DISPLAY_AMOUNT ? successColor : null}
      />
      {/* 
      TODO: ADD SUPPORT FOR REWARD TOKENS
      <EarningsTabDetailsRow value="$??" subValue="?? ALPHA" title="+   Reward Tokens" /> */}
    </Container>
  );
};
export default EarningsTabDetails;
