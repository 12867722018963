import {
  EthereumTransaction,
  EthereumTransactionTypes,
  Market,
  TransactionContextInterface,
  TransactionStatuses,
} from '@/types';

import { MaxUint256 } from '@ethersproject/constants';
import { cleanUpStateAndNotify } from './transactionHelpers';
import { ethers } from 'ethers';

export interface BaseTransactionParams {
  chainId: number;
  market: Market;
  multicallAddress: string;
  onConfirmed?: () => void;
  proxyAddress: string;
  setIsSubmitting: ({ status: TransactionStatuses }) => void;
  signer: ethers.Signer;
  transactionContext: TransactionContextInterface;
  walletAddress: string;
}

interface ApproveParams {
  market: Market;
  proxyAddress: string;
  setIsSubmitting: ({ status: TransactionStatuses }) => void;
  tokenContract: ethers.Contract;
  transactionContext: TransactionContextInterface;
}

interface TransactionPresenceCheckParams {
  market?: Market;
  status: TransactionStatuses;
  transactions: EthereumTransaction[];
  type: EthereumTransactionTypes;
  walletAddress: string;
}

interface CheckApprovalStatusesOutput {
  hasPendingApproveTransferTransactions: boolean;
  hasSuccessfulApproveTransferTransactions: boolean;
}

export const submitApproveTransaction = async ({
  market,
  proxyAddress,
  setIsSubmitting,
  tokenContract,
  transactionContext,
}: ApproveParams): Promise<void> => {
  try {
    const response = await tokenContract.approve(proxyAddress, MaxUint256);

    const { hash } = response;
    cleanUpStateAndNotify({
      hash,
      market,
      setIsSubmitting,
      transactionContext,
      type: EthereumTransactionTypes.Approve,
    });
  } catch (error) {
    setIsSubmitting({ status: TransactionStatuses.Failed });

    console.debug('Failed to approve token', error);
  }
};

export const transactionTypeIsPresent = ({
  market,
  transactions,
  type,
  status,
  walletAddress,
}: TransactionPresenceCheckParams): boolean => {
  return (
    transactions?.filter((transaction) => {
      const fromAddressMatches = transaction.from == walletAddress;
      const transactionTypMatches = transaction.type === type;
      const transactionStatusMatches = transaction.status === status;
      const marketMatches = market == null || transaction.marketId == null ? true : transaction.marketId == market.id;

      return fromAddressMatches && transactionTypMatches && transactionStatusMatches && marketMatches;
    }).length > 0
  );
};

export const checkApprovalStatuses = (
  transactions: EthereumTransaction[],
  walletAddress: string,
  market?: Market,
): CheckApprovalStatusesOutput => {
  const hasPendingApproveTransferTransactions =
    transactionTypeIsPresent({
      market,
      transactions,
      type: EthereumTransactionTypes.Approve,
      status: TransactionStatuses.Pending,
      walletAddress,
    }) ||
    transactionTypeIsPresent({
      market,
      transactions,
      type: EthereumTransactionTypes.Approve,
      status: TransactionStatuses.Sent,
      walletAddress,
    });

  const hasSuccessfulApproveTransferTransactions = transactionTypeIsPresent({
    market,
    transactions,
    type: EthereumTransactionTypes.Approve,
    status: TransactionStatuses.Confirmed,
    walletAddress,
  });

  return {
    hasPendingApproveTransferTransactions,
    hasSuccessfulApproveTransferTransactions,
  };
};
