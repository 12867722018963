import Toast, { ToastStatuses } from '../shared/Toast';

import React from 'react';

interface Props {
  errorCodesString: string;
}

const ErrorToast: React.FC<Props> = ({ errorCodesString }: Props) => {
  return (
    <Toast status={ToastStatuses.error}>
      Although your transaction was confirmed, an underlying error prevented its successful completion:
      <br />
      <br />
      {errorCodesString}
    </Toast>
  );
};
export default ErrorToast;
