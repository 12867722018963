import {
  COZY_COMPTROLLER_ERROR_CODES,
  COZY_CONTRACT_MATH_ERROR_CODES,
  COZY_MAKER_FAILURE_INFO,
  COZY_MARKET_ERROR_CODES,
} from '@/references/cozyErrorCodes';

import ErrorToast from '@/components/transactions/ErrorToast';
import { LogDescription } from 'ethers/lib/utils';
import React from 'react';
import { TransactionContextInterface } from '@/types';
import { ethers } from 'ethers';

interface TransactionLog {
  topics: Array<string>;
  data: string;
}

const errorCodeDescriptions = {
  [`COMPTROLLER_REJECTION,BORROW_COMPTROLLER_REJECTION,INSUFFICIENT_LIQUIDITY`]: "Your transaction was rejected because you didn't have enough borrowing power.",
};

// First code is alays the error code from contract you called
// Second code is the info code from the contract you called
// Third code is optional and refers to different contract, we need to check the
//   first code to know which contract to route the third code to.
export const interpretErrorCodes = (errorCodes: number[]): string[] => {
  const [primaryCode, infoCode, opaqueCode] = errorCodes;

  switch (primaryCode) {
    case COZY_MARKET_ERROR_CODES.COMPTROLLER_REJECTION: {
      return [
        COZY_MARKET_ERROR_CODES[primaryCode],
        COZY_MAKER_FAILURE_INFO[infoCode],
        COZY_COMPTROLLER_ERROR_CODES[opaqueCode],
      ];
    }
    case COZY_MARKET_ERROR_CODES.MATH_ERROR: {
      return [
        COZY_MARKET_ERROR_CODES[primaryCode],
        COZY_MAKER_FAILURE_INFO[infoCode],
        COZY_CONTRACT_MATH_ERROR_CODES[opaqueCode],
      ];
    }
    default: {
      return [COZY_MARKET_ERROR_CODES[primaryCode], COZY_MAKER_FAILURE_INFO[infoCode]];
    }
  }
};

export const showErrorToast = (errorCodes: number[], transactionContext: TransactionContextInterface): void => {
  const { toast } = transactionContext;

  const interpretedErrorCodes = interpretErrorCodes(errorCodes);
  const errorCodeDescription = determineErrorCodeDescription(interpretedErrorCodes);

  toast({
    duration: 20000,
    position: 'bottom-right',
    render: () => <ErrorToast errorCodesString={errorCodeDescription} />,
  });
};

/**
 * @notice Wrapper around ethers' parseLog that returns undefined instead of
 * throwing an error (an error is throw if we try parsing a log with the wrong
 * interface)
 * @param contract: Instance of an ethers Contract
 * @param log: A single `Log` from the tx receipt's logs array
 * @returns The parsed log, or undefined if it could not be parsed
 */
export const parseLog = (contract: ethers.Contract) => (log: TransactionLog): LogDescription => {
  try {
    return contract.interface.parseLog(log);
  } catch (err) {
    return undefined;
  }
};

export const determineErrorCodeDescription = (errorCodes: string[]): string => {
  const errorCodeKey = errorCodes.toString();

  const description = errorCodeDescriptions[errorCodeKey];

  return description ?? errorCodes.join(', ');
};
