import { PositionTypes } from '@/types';
import React from 'react';
import { Stack } from '@chakra-ui/react';
import Text from '@/components/shared/Text';

interface Props {
  positionType: PositionTypes;
}

const topMessageByPositionType = {
  [PositionTypes.Borrow]: 'If the condition in the Trigger Contract above is met, your debt will be forgiven.',
  [PositionTypes.Supply]:
    'If the condition in the Trigger Contract above is met, all debts are forgiven. You will be able withdraw your share of the remaining assets in this market.',
  [PositionTypes.Invest]:
    'Use your Investing Power to invest in DeFi platforms and earn yield. If the above protection trigger for this investment occurs, you’ll get your money back.',
};

const HowItWorks: React.FC<Props> = ({ positionType }: Props) => {
  return (
    <Stack spacing={3} borderRadius="lg" border="1px" borderColor="gray.100" p={4}>
      <Text fontSize="md" fontWeight="600">
        How protection works
      </Text>
      <Text fontSize="sm">{topMessageByPositionType[positionType]}</Text>
    </Stack>
  );
};
export default HowItWorks;
