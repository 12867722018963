import Button from '@/components/shared/Button';
import { ButtonType } from '@/types';
import CozyLogo from '@/components/layout/CozyLogo';
import { Flex } from '@chakra-ui/react';
import Heading from '@/components/shared/Heading';
import React from 'react';
import { trackEvent } from '@/utils/analytics';
import { useRouter } from 'next/router';

const BorrowLimitModalPanelNoFundsState: React.FC = () => {
  const router = useRouter();

  const onClick = () => {
    trackEvent('Deposit CTA Clicked', { location: 'modal' });
    router.push('/funds');
  };

  return (
    <Flex flex={1} my="4em" direction="column" alignItems="center" justifyContent="center">
      <CozyLogo height={74} />
      <Heading maxW="18em" mt={4} mb={6} fontWeight="800" fontSize="2xl" textAlign="center">
        To start using Cozy, increase your Borrowing Power by depositing funds below.
      </Heading>
      <Button onClick={onClick} appButtonType={ButtonType.Primary}>
        Deposit Funds
      </Button>
    </Flex>
  );
};
export default BorrowLimitModalPanelNoFundsState;
