import { Box, BoxProps, useTheme } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

import styled from '@emotion/styled';

interface Props extends BoxProps {
  children: ReactNode;
  hoverable?: boolean;
}
interface HoverProps {
  hoverbackgroundcolor?: string;
}

const HoverableBox = styled(Box)<HoverProps>`
  background-color: white;
  &:hover {
    background-color: ${({ hoverbackgroundcolor }) => hoverbackgroundcolor};
  }
`;

const Card: React.FC<Props> = ({ children, hoverable = false, ...props }: Props) => {
  const theme = useTheme();

  const hoverProps = hoverable ? { hoverbackgroundcolor: theme.colors.gray[50] } : null;

  return (
    <HoverableBox border="1px" borderRadius="lg" borderColor="gray.100" boxShadow="sm" {...hoverProps} {...props}>
      {children}
    </HoverableBox>
  );
};
export default Card;
