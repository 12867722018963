import Toast, { ToastStatuses } from '../shared/Toast';

import React from 'react';

interface Props {
  transactionDescription: string;
}

const ConfirmedToast: React.FC<Props> = ({ transactionDescription }: Props) => {
  return (
    <Toast status={ToastStatuses.success}>Congrats, your {transactionDescription} transaction was confirmed.</Toast>
  );
};
export default ConfirmedToast;
