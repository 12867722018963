export const MARKET_INNER_QUERY = `
  borrowCap
  borrowIndex
  borrowRate
  cash
  collateralFactor
  decimals
  exchangeRate
  id
  interestRateModelAddress
  name
  supplyRate
  symbol
  totalBorrows
  totalSupply
  triggerAddress
  underlyingAddress
  underlyingDecimals
  underlyingName
  underlyingPrice
  underlyingPriceUSD
  underlyingSymbol
`;

export const TRIGGERS = `
  triggers {
    address
    creatorAddress
    description
    id
    name
    platformIds
    triggered
    triggeredAt  
  }
`;

export const COMPTROLLER = `
  comptroller(id: 1) {
      address
      id
      maximillionAddress
      multicallAddress
      priceOracle
  }
`;

export const ACCOUNT = (account: string): string => `
  account(id: "${account}") {
    id
    tokens {
      accrualBlockNumber
      accountBorrowIndex
      cTokenBalance
      enteredMarket
      id
      market{
        ${MARKET_INNER_QUERY}
      }
      storedBorrowBalance
      symbol
      totalUnderlyingBorrowed
      totalUnderlyingRedeemed
      totalUnderlyingRepaid
      totalUnderlyingSupplied
    }
  }
`;

export const LIQUIDATION_EVENTS = (account: string): string => `
  liquidationEvents(where: {fromAddress: "${account}"}) {
    amount
    blockNumber
    blockTime
    cTokenSymbol
    fromAddress
    id
    marketId
    to
    underlyingAmount
    underlyingRepayAmount
    underlyingSymbol
  }
`;

export const MINT_EVENTS = (account: string): string => `
  mintEvents(where: {to: "${account}"}) {
    amount
    blockNumber
    blockTime
    cTokenSymbol
    fromAddress
    id
    marketId
    to
    underlyingAmount
    underlyingSymbol
  }
`;

export const REDEEM_EVENTS = (account: string): string => `
  redeemEvents(where: {fromAddress: "${account}"}) {
    amount
    blockNumber
    blockTime
    cTokenSymbol
    fromAddress
    id
    marketId
    to
    underlyingAmount
    underlyingSymbol
  }
`;

export const BORROW_EVENTS = (account: string): string => `
  borrowEvents(where: {borrower: "${account}"}) {
    amount
    blockNumber
    blockTime
    borrower
    id
    marketId
    underlyingAmount
    underlyingSymbol
  }
`;

export const REPAY_EVENTS = (account: string): string => `
  repayEvents(where: {borrower: "${account}"}) {
    amount
    blockNumber
    blockTime
    borrower
    id
    marketId
    payer
    underlyingAmount
    underlyingSymbol
  }
`;

export const YEARN_ACCOUNT_QUERY = (account: string): string => `
  query {
    account(id: "${account?.toLowerCase()}") {
      vaultPositions {
        balanceTokens
        id
        latestUpdate {
          balanceShares
          blockNumber
          sharesMinted
          sharesReceived
          sharesSent
          sharesBurnt

        }
        shareToken {
          id
          decimals
          name
          symbol
        }
        token {
          id
          decimals
          name
          symbol

        }
        vault {
          id
          latestUpdate {
            pricePerShare
          }
        }
      }
    }
  }
`;

export const YEARN_MARKETS_QUERY = `
    query {
      vaults {
        id
        latestUpdate {
          timestamp
          pricePerShare      
  
        }
        historicalUpdates(first:1, orderBy: timestamp, orderDirection: asc) {
          timestamp
          pricePerShare      
        }       
      }
    }
`;

export const BADGER_ACCOUNT_QUERY = (account: string): string => `
  query {
    account(id: "${account?.toLowerCase()}") {
      vaultBalances {
        netDeposits
        vault {
          id
        }
      }
    }
  }
`;

export const COZY_ACCOUNT_QUERY = (account: string): string => {
  if (account == null) return ``;

  account = account.toLowerCase();

  return `
    query {  
      ${ACCOUNT(account)}
      ${BORROW_EVENTS(account)}
      ${LIQUIDATION_EVENTS(account)}
      ${MINT_EVENTS(account)}
      ${REDEEM_EVENTS(account)}
      ${REPAY_EVENTS(account)}
      ${TRIGGERS}
    }
  `;
};

export const COMPOUND_ACCOUNT_QUERY = (account: string): string => `
  query {
    account(id: "${account?.toLowerCase()}") {
      tokens {
        accrualBlockNumber
        cTokenBalance
        symbol
        market {
          exchangeRate
          id
          supplyRate
        }
      }
    }
  }  
`;

export const COZY_MARKETS_QUERY = `
  query {
    ${COMPTROLLER}
    markets {
      ${MARKET_INNER_QUERY}
    }
    ${TRIGGERS}
  }
`;

export const COMPOUND_MARKETS_QUERY = `
  query {
    markets {
      id
      supplyRate     
    }
  }
`;
