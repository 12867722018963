import { Account, ButtonType, Comptroller, Market, Position, TransactionStatuses } from '@/types';
import { ButtonGroup, Flex, NumberInput, NumberInputField, useDisclosure } from '@chakra-ui/react';
import React, { useContext, useState } from 'react';
import { formatTokenAmount, formatUsdAmount } from '@/helpers/formatHelpers';
import { inputDisplayAmount, onAmountChange, setPercentageAmount } from '@/helpers/manageModalHelpers';
import { repayAmountIsValid, submitRepayTransaction } from '@/helpers/borrowTransactionHelpers';
import { trackEvent, triggerAnalyticsProps } from '@/utils/analytics';
import { useBalance, useErc20Balance } from '@/hooks/web3React';

import Button from '@/components/shared/Button';
import PreRepayStepsModal from '../PreRepaySteps/PreRepayStepsModal';
import Text from '@/components/shared/Text';
import { TransactionContext } from '@/contexts/TransactionContext';
import { isEth } from '@/helpers/assetHelpers';
import useCheckApprovals from '@/hooks/useCheckApprovals';
import { useWeb3React } from '@web3-react/core';

interface Props {
  account: Account;
  comptroller: Comptroller;
  market: Market;
  onModalClose: () => void;
  position: Position;
  setRepayAmount: React.Dispatch<React.SetStateAction<string>>;
  repayAmount: string;
}

const RepayTab: React.FC<Props> = ({
  account,
  comptroller,
  market,
  onModalClose,
  position,
  repayAmount,
  setRepayAmount,
}: Props) => {
  const { account: walletAddress, chainId, library } = useWeb3React();
  const transactionContext = useContext(TransactionContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isRepayAll, setIsRepayAll] = useState(false);
  const [approveTransactionIsSubmitting, setApproveTransactionIsSubmitting] = useState(false);

  const { proxyAddress } = account;
  const { underlyingAddress } = market;

  const { cozyIsApproved, proxyIsApproved } = useCheckApprovals({
    chainId,
    marketContractAddress: market.id,
    proxyAddress,
    walletAddress,
    underlyingAddress,
  });

  const {
    isOpen: preRepayStepsModalIsOpen,
    onClose: preRepayStepsModalOnClose,
    onOpen: preRepayStepsModalOnOpen,
  } = useDisclosure();

  const ethBalance = useBalance();
  const erc20Balance = useErc20Balance(underlyingAddress);
  const relevantBalance = isEth(underlyingAddress) ? ethBalance : erc20Balance;

  const signer = library?.getSigner(walletAddress);

  const borrowBalanceUnderlying = position?.borrowBalanceUnderlying;

  const usdRepayAmount = parseFloat(repayAmount) * market.underlyingPriceUSD;

  const formattedUnderlyingAmount = position
    ? formatTokenAmount(position.borrowBalanceUnderlying, market.underlyingSymbol, 0, 4)
    : 0;

  const trackClick = () => {
    trackEvent('Repay Clicked', {
      amount: repayAmount,
      marketId: market.id,
      asset: market.underlyingSymbol,
      ...triggerAnalyticsProps(market.trigger),
    });
  };
  const onRepay = !proxyIsApproved
    ? () => {
        trackClick();
        preRepayStepsModalOnOpen();
      }
    : () => {
        trackClick();
        setIsSubmitting(true);
        submitRepayTransaction({
          chainId,
          borrowBalanceUnderlying,
          cozyIsApproved,
          isRepayAll,
          market,
          maximillionAddress: comptroller.maximillionAddress,
          multicallAddress: comptroller.multicallAddress,
          proxyAddress,
          setRepayAmount,
          setIsSubmitting: ({ status }) => {
            setIsSubmitting(false);
            if (status == TransactionStatuses.Sent) {
              onModalClose();
            }
          },
          signer,
          transactionContext,
          walletAddress: walletAddress,
          repayAmount,
        });
      };

  const { amountIsValid, errorMessage } = repayAmountIsValid(repayAmount, relevantBalance, market);

  const displayRepayAmount = inputDisplayAmount(repayAmount);

  const repayButton = (
    <Button appButtonType={ButtonType.Primary} onClick={onRepay}>
      Start
    </Button>
  );

  return (
    <Flex direction="column" alignItems="center">
      <PreRepayStepsModal
        approveTransactionIsSubmitting={approveTransactionIsSubmitting}
        isApproved={proxyIsApproved}
        isOpen={preRepayStepsModalIsOpen}
        market={market}
        onClose={preRepayStepsModalOnClose}
        proxyAddress={account?.proxyAddress}
        repayButton={repayButton}
        setApproveTransactionIsSubmitting={setApproveTransactionIsSubmitting}
      />

      <Flex direction="column" alignItems="center" my="2em">
        <Flex>
          <NumberInput
            onChange={(value) => {
              setIsRepayAll(false);
              onAmountChange(setRepayAmount)(value);
            }}
            value={displayRepayAmount}
            variant="unstyled"
            color={parseFloat(repayAmount) == 0 ? 'gray.500' : null}
            isDisabled={position == null}
          >
            <NumberInputField paddingInlineEnd="0" fontSize="5xl" fontWeight="800" textAlign="center" placeholder="0" />
          </NumberInput>
        </Flex>
        <Text fontSize="lg" color="gray.500">
          Equal to {formatUsdAmount(usdRepayAmount, '$0,0.00')}
        </Text>
      </Flex>

      <Flex alignItems="baseline" mt={8} mb="2em">
        <Text fontSize="md" fontWeight="600">
          {formattedUnderlyingAmount} {market.underlyingSymbol}
        </Text>
        <Text fontSize="md" ml={1}>
          Total Balance Due
        </Text>
      </Flex>

      <ButtonGroup isAttached variant="outline" mb="2em">
        <Button
          isDisabled={position == null}
          onClick={() => {
            setIsRepayAll(false);
            setPercentageAmount(borrowBalanceUnderlying.toString(), 25, 0, setRepayAmount);
          }}
          mr="-px"
        >
          25%
        </Button>
        <Button
          isDisabled={position == null}
          onClick={() => {
            setIsRepayAll(false);
            setPercentageAmount(borrowBalanceUnderlying.toString(), 50, 0, setRepayAmount);
          }}
          mr="-px"
        >
          50%
        </Button>
        <Button
          isDisabled={position == null}
          onClick={() => {
            setIsRepayAll(false);
            setPercentageAmount(borrowBalanceUnderlying.toString(), 75, 0, setRepayAmount);
          }}
          mr="-px"
        >
          75%
        </Button>
        <Button
          isDisabled={position == null}
          onClick={() => {
            setIsRepayAll(true);
            setPercentageAmount(borrowBalanceUnderlying.toString(), 100, 0, setRepayAmount);
          }}
        >
          100%
        </Button>
      </ButtonGroup>

      <Button
        appButtonType={ButtonType.Primary}
        isDisabled={position == null || !amountIsValid || isSubmitting}
        isLoading={isSubmitting}
        loadingText={`Pay Back ${market.underlyingSymbol}`}
        mb="2em"
        minW="280px"
        onClick={onRepay}
      >
        {amountIsValid ? `Pay Back ${market.underlyingSymbol}` : errorMessage}
      </Button>
    </Flex>
  );
};
export default RepayTab;
