import { Account } from '@/types';
import { MAX_BORROW_PERCENTAGE } from './borrowingPowerHelpers';

const LIQUIDATION_WARNING_THRESHOLD = 0.8;

export interface BorrowLimitStats {
  borrowLimitUsdLeft: number;
  borrowLimitUsdTotal: number;
  isInLiquidation: boolean;
  isNearLiquidation: boolean;
  percentBorrowed: number;
  projBorrowLimitUsdTotal: number;
  projPercentBorrowed: number;
  projUsdBorrowed: number;
  usdBorrowed: number;
}

export const calculateBorrowLimitStats = (
  account: Account,
  usdAdditionalCollateral = 0,
  usdAdditionalBorrow = 0,
): BorrowLimitStats => {
  const { totalBorrowValueInUsd = 0, totalCollateralValueInUsd = 0 } = account;

  const percentBorrowed = totalCollateralValueInUsd == 0 ? 0 : totalBorrowValueInUsd / totalCollateralValueInUsd;
  const usdBorrowed = totalBorrowValueInUsd;
  const borrowLimitUsdTotal = totalCollateralValueInUsd;
  const borrowLimitUsdLeft = borrowLimitUsdTotal - usdBorrowed;

  // Must be less than 1 but greater than 0
  const projPercentBorrowed = Math.max(
    Math.min(
      1,
      calculateProjectedPercentBorrowed({
        totalCollateralValueInUsd,
        usdAdditionalCollateral,
        totalBorrowValueInUsd,
        usdAdditionalBorrow,
      }),
    ),
    0,
  );

  const projBorrowLimitUsdTotal = totalCollateralValueInUsd + usdAdditionalCollateral;

  const projUsdBorrowed = totalBorrowValueInUsd + usdAdditionalBorrow;
  const isInLiquidation = projUsdBorrowed > projBorrowLimitUsdTotal * MAX_BORROW_PERCENTAGE && projUsdBorrowed > 0;
  const isNearLiquidation = projPercentBorrowed > LIQUIDATION_WARNING_THRESHOLD && projUsdBorrowed > 0;

  return {
    borrowLimitUsdLeft,
    borrowLimitUsdTotal,
    isInLiquidation,
    isNearLiquidation,
    percentBorrowed,
    projBorrowLimitUsdTotal,
    projPercentBorrowed,
    projUsdBorrowed,
    usdBorrowed,
  };
};

const calculateProjectedPercentBorrowed = ({
  totalCollateralValueInUsd,
  usdAdditionalCollateral,
  totalBorrowValueInUsd,
  usdAdditionalBorrow,
}) => {
  if (totalCollateralValueInUsd + usdAdditionalCollateral === 0) {
    return 0;
  }

  return (totalBorrowValueInUsd + usdAdditionalBorrow) / (totalCollateralValueInUsd + usdAdditionalCollateral);
};
