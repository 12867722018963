import { ButtonType, Comptroller, Market, Position, TransactionStatuses } from '@/types';
import React, { useContext, useState } from 'react';
import { depositAmountIsValid, submitDepositTransaction } from '@/helpers/supplyTransactionHelpers';
import { trackEvent, triggerAnalyticsProps } from '@/utils/analytics';

import { BigNumber } from 'ethers';
import Button from '@/components/shared/Button';
import { TransactionContext } from '@/contexts/TransactionContext';
import { useWeb3React } from '@web3-react/core';

interface Props {
  buttonText?: string;
  comptroller: Comptroller;
  cozyIsApproved: boolean;
  depositAmount: string;
  marginBottom: string;
  market: Market;
  markets: Market[];
  minWidth: string;
  onModalClose: () => void;
  position: Position;
  preDepositStepsModalOnOpen: () => void;
  proxyAddress: string;
  proxyIsApproved: boolean;
  relevantBalance: BigNumber;
  setDepositAmount: React.Dispatch<React.SetStateAction<string>>;
  showTextOnLoading: boolean;
}

const DepositTabButton: React.FC<Props> = ({
  buttonText,
  comptroller,
  cozyIsApproved,
  depositAmount,
  marginBottom = '2em',
  market,
  minWidth = '281px',
  onModalClose,
  position,
  preDepositStepsModalOnOpen,
  proxyAddress,
  proxyIsApproved,
  relevantBalance,
  setDepositAmount,
  showTextOnLoading = true,
}: Props) => {
  const { account: walletAddress, chainId, library } = useWeb3React();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const transactionContext = useContext(TransactionContext);
  const { proxyCompletedCheckTryNumber, setProxyCompletedCheckTryNumber } = transactionContext;

  const signer = library?.getSigner(walletAddress);

  const trackClick = () =>
    trackEvent('Deposit Assets Clicked', {
      amount: depositAmount,
      marketId: market.id,
      asset: market.underlyingSymbol,
      ...triggerAnalyticsProps(market.trigger),
    });

  const onDeposit =
    proxyAddress == null || !proxyIsApproved
      ? () => {
          trackClick();
          setProxyCompletedCheckTryNumber(proxyCompletedCheckTryNumber + 1);
          preDepositStepsModalOnOpen();
        }
      : () => {
          trackClick();
          setIsSubmitting(true);
          submitDepositTransaction({
            chainId,
            cozyIsApproved,
            comptroller,
            depositAmount,
            market,
            multicallAddress: comptroller.multicallAddress,
            position,
            proxyAddress,
            signer,
            setDepositAmount,
            setIsSubmitting: ({ status }) => {
              setIsSubmitting(false);

              if (status == TransactionStatuses.Sent) {
                onModalClose();
              }
            },
            transactionContext,
            walletAddress,
          });
        };

  const { amountIsValid, errorMessage } = depositAmountIsValid(depositAmount, relevantBalance, market);

  buttonText = buttonText ?? `Deposit ${market.underlyingSymbol}`;
  if (!amountIsValid) {
    buttonText = errorMessage;
  }

  return (
    <Button
      appButtonType={ButtonType.Primary}
      isDisabled={true}
      isLoading={isSubmitting}
      loadingText={showTextOnLoading ? buttonText : null}
      marginBottom={marginBottom}
      minWidth={minWidth}
      onClick={onDeposit}
    >
      {buttonText}
    </Button>
  );
};
export default DepositTabButton;
