import {
  ActivityItem as ActivityItemType,
  EventTypes,
  MarketTypes,
  Position,
  PositionTypes,
  TriggerEvent,
} from '@/types';
import { Box, Button, Flex, Link, useTheme } from '@chakra-ui/react';
import {
  activityEventTransactionHash,
  activityItemIsEligibleForNotif,
  etherscanTransactionUrl,
} from '@/helpers/activityItemHelpers';
import { formatDate, formatList, formatNumber, formatUsdAmount } from '@/helpers/formatHelpers';

import { BiLinkExternal } from 'react-icons/bi';
import { BorrowModalTabs } from '../modals/BorrowModal';
import Dot from '../shared/Dot';
import { InvestModalTabs } from '../modals/InvestModal';
import React from 'react';
import { SupplyModalTabs } from '../modals/SupplyModal';
import Text from '@/components/shared/Text';
import { useRouter } from 'next/router';
import { useWeb3React } from '@web3-react/core';

interface Props {
  hasBeenSeen?: boolean;
  item: ActivityItemType;
  position: Position;
}

const SHOW_USD_AMOUNT = [EventTypes.BorrowEvent, EventTypes.MintEvent, EventTypes.RepayEvent, EventTypes.RedeemEvent];

const ACTIVITY_TITLES = {
  [EventTypes.BorrowEvent]: ({ event }: ActivityItemType) =>
    `Borrowed ${formatNumber(event.amount)} ${event.underlyingSymbol}`,
  [EventTypes.LiquidationEvent]: () => `Funds liquidated`,
  [EventTypes.MintEvent]: ({ event, marketType }: ActivityItemType) =>
    `${marketType === MarketTypes.NonProtectionMarket ? 'Deposited' : 'Supplied'} ${formatNumber(
      event.underlyingAmount,
    )} ${event.underlyingSymbol}`,
  [EventTypes.RedeemEvent]: ({ event }: ActivityItemType) =>
    `Withdrew ${formatNumber(event.amount)} ${event.underlyingSymbol}`,
  [EventTypes.RepayEvent]: ({ event }: ActivityItemType) =>
    `Paid back ${formatNumber(event.amount)} ${event.underlyingSymbol}`,
  [EventTypes.TriggerEvent]: () => `Protection event occured`,
};

const ACTIVITY_BODIES = {
  [EventTypes.BorrowEvent]: ({ marketType, platforms }: ActivityItemType) => {
    return marketType === MarketTypes.NonProtectionMarket
      ? 'borrowed and transferred to your wallet.'
      : `Borrowing protection for an event on ${formatList(
          platforms.map((p) => p.name),
        )}. Your debt will be automatically forgiven if the trigger event occurs.`;
  },
  [EventTypes.LiquidationEvent]: ({ event }: ActivityItemType) =>
    `Your borrowing limit exceeded 100%, and ${event.underlyingAmount} worth of ${event.underlyingSymbol} of your collateral was liquidated.  `,
  [EventTypes.MintEvent]: ({ marketType, platforms }: ActivityItemType) => {
    return marketType === MarketTypes.NonProtectionMarket
      ? 'to your Cozy Funds, earning interest.'
      : `Supplying protection for an event on ${formatList(
          platforms.map((p) => p.name),
        )}. You will only lose funds if the trigger event occurs. `;
  },
  [EventTypes.RedeemEvent]: ({ marketType, platforms }: ActivityItemType) => {
    return marketType === MarketTypes.NonProtectionMarket
      ? 'from your Cozy Funds.'
      : `from your Supplied Protection for ${formatList(platforms.map((p) => p.name))}.`;
  },
  [EventTypes.RepayEvent]: ({ marketType }: ActivityItemType) =>
    `Your ${marketType === MarketTypes.ProtectionMarket ? 'protected ' : ' '}debt was paid back.`,
  [EventTypes.TriggerEvent]: ({ platforms }: ActivityItemType) =>
    `A protection has been triggered for an event on ${formatList(
      platforms.map((p) => p.name),
    )}. Your collateral is safe, and your debt  has been automatically forgiven. `,
};

const ActivityItem: React.FC<Props> = ({ item, hasBeenSeen = true, position }: Props) => {
  const theme = useTheme();
  const router = useRouter();
  const { chainId } = useWeb3React();

  const isInvestPosition = position.investBalanceUnderlying > 0;

  const isProtectedMarket = item.marketType === MarketTypes.ProtectionMarket;
  const isNonProtectedMarket = item.marketType === MarketTypes.NonProtectionMarket;

  const isBorrowEvent = item.eventType === EventTypes.BorrowEvent;
  const isRepayEvent = item.eventType === EventTypes.RepayEvent;
  const isMintEvent = item.eventType === EventTypes.MintEvent;
  const isRedeemEvent = item.eventType === EventTypes.RedeemEvent;

  const investModal = `/protected-invest?market=${item.event.marketId}&tab=${InvestModalTabs.Activity}`;
  const protectedBorrowModal = `/protected-borrow?market=${item.event.marketId}&tab=${BorrowModalTabs.Activity}`;
  const provideProtectionModal = `/provide?market=${item.event.marketId}&tab=${SupplyModalTabs.Activity}`;
  const borrowModal = `/borrow?market=${item.event.marketId}&tab=${BorrowModalTabs.Activity}`;
  const supplyModal = `/funds?market=${item.event.marketId}&tab=${SupplyModalTabs.Activity}`;

  const onClick = () => {
    // Protected Markets
    if (isProtectedMarket && (isBorrowEvent || isRepayEvent)) {
      if (isInvestPosition) {
        router.push(investModal);
      } else {
        router.push(protectedBorrowModal);
      }
    } else if (isProtectedMarket && (isMintEvent || isRedeemEvent)) {
      router.push(provideProtectionModal);

      // Unprotected Markets
    } else if (isNonProtectedMarket && (isBorrowEvent || isRepayEvent)) {
      router.push(borrowModal);
    } else if (isNonProtectedMarket && (isMintEvent || isRedeemEvent)) {
      router.push(supplyModal);

      // Liquidations and Trigger Events
    } else if (item.eventType === EventTypes.LiquidationEvent) {
      router.push(`/funds?market=${item.event.marketId}&tab=${SupplyModalTabs.Activity}`);
    } else if (
      item.eventType === EventTypes.TriggerEvent &&
      ((item.event as TriggerEvent).positionType === PositionTypes.Borrow ||
        (item.event as TriggerEvent).positionType === PositionTypes.BorrowAndSupply)
    ) {
      router.push(`/protected-borrow?market=${item.event.marketId}&tab=${SupplyModalTabs.Activity}`);
    } else if (
      item.eventType === EventTypes.TriggerEvent &&
      (item.event as TriggerEvent).positionType === PositionTypes.Supply
    ) {
      router.push(`/provide?market=${item.event.marketId}&tab=${SupplyModalTabs.Activity}`);
    }
  };

  const hash = activityEventTransactionHash(item);
  const transactionUrl = etherscanTransactionUrl(hash, chainId);

  return (
    <Box cursor="pointer" _hover={{ backgroundColor: 'gray.50' }} px={6} py={4} onClick={onClick}>
      <Flex fontSize="sm" justifyContent="space-between">
        <Flex alignItems="center">
          {!hasBeenSeen && activityItemIsEligibleForNotif(item) && (
            <Box mr={2} mb="2px">
              <Dot width={8} color={theme.utilityColors.errorColor} />
            </Box>
          )}
          <Text fontWeight="600">
            {ACTIVITY_TITLES[item.eventType](item)}{' '}
            {SHOW_USD_AMOUNT.includes(item.eventType) && (
              <Text fontWeight="400" as="span">
                ({formatUsdAmount(item.usdAmount)})
              </Text>
            )}
          </Text>
        </Flex>
      </Flex>
      <Text textAlign="left" mt={1} fontSize="sm" color="gray.500">
        {ACTIVITY_BODIES[item.eventType](item)}
      </Text>
      <Flex alignItems="center" justifyContent="space-between" mt={2}>
        <Text color="gray.300" fontSize="sm">
          {formatDate(item.blockTime, 'MMM D')}
        </Text>
        {hash != null && (
          <Link isExternal href={transactionUrl}>
            <Button
              onClick={(e) => e.stopPropagation()}
              fontWeight="400"
              colorScheme="purple"
              size="sm"
              variant="link"
              rightIcon={<BiLinkExternal />}
            >
              Transaction
            </Button>
          </Link>
        )}
      </Flex>
    </Box>
  );
};
export default ActivityItem;
