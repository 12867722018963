import React, { useContext } from 'react';

import Button from '@/components/shared/Button';
import { ButtonType } from '@/types';
import CozyLogo from '@/components/layout/CozyLogo';
import { Flex } from '@chakra-ui/react';
import Heading from '@/components/shared/Heading';
import { WalletModalContext } from '@/contexts/WalletModalContext';

const ConnectWalletGateModal: React.FC = () => {
  const { onOpen } = useContext(WalletModalContext);

  return (
    <>
      <Flex flex={1} my="4em" direction="column" alignItems="center" justifyContent="center">
        <CozyLogo height={74} />
        <Heading maxW="18em" mt={4} mb={6} fontWeight="800" fontSize="2xl" textAlign="center">
          You need to connect an Ethereum wallet in order to use Cozy.
        </Heading>
        <Button onClick={onOpen} appButtonType={ButtonType.Primary}>
          Connect Wallet
        </Button>
      </Flex>
    </>
  );
};
export default ConnectWalletGateModal;
