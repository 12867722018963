import { Account, ActivityItem, EventTypes } from '@/types';
import { DEFAULT_CHAIN_ID, ETH_NETWORKS } from '@/constants';

export const activityItemIsEligibleForNotif = (item: ActivityItem): boolean => {
  return item.eventType === EventTypes.LiquidationEvent || item.eventType === EventTypes.TriggerEvent;
};

export const markNotificationsSeen = (
  account: Account,
  numVisibleItems: number,
  seenNotificationIds: string[],
  setSeenNotificationIds: (value: string[] | ((val: string[]) => string[])) => void,
): void => {
  if (account) {
    const visibleNotificationIds = account.activityItems?.slice(0, numVisibleItems).map((item) => item.event.id);
    const allNotificationsSeen = visibleNotificationIds
      ? [...seenNotificationIds, ...visibleNotificationIds]
      : seenNotificationIds;

    setSeenNotificationIds(allNotificationsSeen);
  }
};

export const etherscanAddressUrl = (address: string, chainId: number, anchor?: string): string => {
  const network = networkPrefix(chainId);
  return `https://${network}etherscan.io/address/${address}${conditionallyInsert(anchor)}`;
};

export const etherscanTransactionUrl = (hash: string, chainId: number, anchor?: string): string => {
  const network = networkPrefix(chainId);
  return `https://${network}etherscan.io/tx/${hash}${conditionallyInsert(anchor)}`;
};

export const activityEventTransactionHash = (item: ActivityItem): string => {
  return item.event?.id.split('-')[0];
};

const networkPrefix = (chainId: number): string | null => {
  chainId = chainId ?? DEFAULT_CHAIN_ID;
  if (chainId != 1) {
    return `${ETH_NETWORKS[chainId]}.`;
  } else {
    return '';
  }
};

const conditionallyInsert = (content: string | null): string => {
  return content ? content : '';
};
