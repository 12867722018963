import {
  Account,
  ActivityItem,
  BorrowEvent,
  Event,
  EventTypes,
  LiquidationEvent,
  Market,
  MarketTypes,
  MintEvent,
  Platform,
  Position,
  PositionTypes,
  RedeemEvent,
  RepayEvent,
  Trigger,
  TriggerCreator,
  TriggerEvent,
} from '@/types';
import { DEFAULT_CHAIN_ID, PLATFORMS, SUBGRAPH_URLS, TRIGGER_CREATORS } from '@/constants';

import { COZY_ACCOUNT_QUERY } from './fetchQueries';
import { TransactionContext } from '@/contexts/TransactionContext';
import { VerifiedAddress } from '@/helpers/preloadDataHelpers';
import { ethers } from 'ethers';
import { findPlatformsByTrigger } from '@/helpers/findHelpers';
import { getProxyAddress } from './proxyWallet';
import { graphQLFetcher } from '@/utils/fetcher';
import { urlByHandle } from '@/utils/sybil';
import { useAsync } from 'react-async-hook';
import { useContext } from 'react';
import useSWR from 'swr';
import { useWeb3React } from '@web3-react/core';

const useFetchAccountData = (
  verifiedAddresses?: Record<string, VerifiedAddress>,
): {
  account?: Account;
  loadingAccountData: boolean;
  errorLoadingAccountData: boolean;
} => {
  const { chainId: activeChainId, account: walletAddress } = useWeb3React();
  const chainId = activeChainId ? activeChainId : DEFAULT_CHAIN_ID;

  const transactionContext = useContext(TransactionContext);
  const { proxyDeployTransactionCompleted, proxyCompletedCheckTryNumber } = transactionContext;

  // Leave proxyDeployTransactionCompleted even though it's an unused variable as it retriggers
  // the getProxyAddress call in order to update UI at correct time
  const asyncProxyAddress = useAsync(() => getProxyAddress(chainId, walletAddress), [
    proxyDeployTransactionCompleted,
    proxyCompletedCheckTryNumber,
    walletAddress,
  ]);
  const { result: proxyAddress } = asyncProxyAddress;

  const COZY_API = SUBGRAPH_URLS[chainId];
  const shouldQuery = chainId != null && COZY_API != null && proxyAddress != null;

  const { data: cozyAccountData, error } = useSWR(
    () => (shouldQuery ? `cozyAccountData-${chainId}-${proxyAddress}` : null),
    graphQLFetcher(COZY_API, COZY_ACCOUNT_QUERY(proxyAddress)),
    { refreshInterval: 4000 },
  );

  // If fetch returned error, raise error to user
  if (error) {
    console.log('Error loading account data: ', error);

    return {
      account: { proxyAddress },
      loadingAccountData: false,
      errorLoadingAccountData: true,
    };
  }

  // If not enough data to query, return empty state to user
  if (!shouldQuery) {
    return {
      account: { proxyAddress },
      loadingAccountData: false,
      errorLoadingAccountData: false,
    };
  }

  // If no account data returned, must still be loading & return loading state to user
  if (cozyAccountData == null) {
    return {
      account: { proxyAddress },
      loadingAccountData: true,
      errorLoadingAccountData: false,
    };
  }

  // Otherwise, must have all necessary data and return hydrated data
  const {
    account = {},
    liquidationEvents,
    mintEvents,
    redeemEvents,
    borrowEvents,
    repayEvents,
    triggers,
  } = cozyAccountData;

  const positions = account?.tokens?.map((position) => {
    const { market } = position;

    const accountBorrowIndex = parseFloat(position.accountBorrowIndex);
    const cTokenBalance = parseFloat(position.cTokenBalance);
    const storedBorrowBalance = parseFloat(position.storedBorrowBalance);
    const totalUnderlyingBorrowed = parseFloat(position.totalUnderlyingBorrowed);
    const totalUnderlyingSupplied = parseFloat(position.totalUnderlyingSupplied);
    const totalUnderlyingRedeemed = parseFloat(position.totalUnderlyingRedeemed);
    const totalUnderlyingRepaid = parseFloat(position.totalUnderlyingRepaid);

    const trigger = triggers.find((trigger) => trigger.address === market.triggerAddress);
    const triggerFactor = trigger == null || trigger.triggered == false ? 1 : 0;

    const supplyBalanceUnderlying = cTokenBalance * market.exchangeRate;
    const lifetimeSupplyInterestAccrued = supplyBalanceUnderlying - totalUnderlyingSupplied + totalUnderlyingRedeemed;

    const borrowBalanceUnderlying =
      accountBorrowIndex != 0 ? ((storedBorrowBalance * market.borrowIndex) / accountBorrowIndex) * triggerFactor : 0;
    const lifetimeBorrowInterestAccrued = borrowBalanceUnderlying - totalUnderlyingBorrowed + totalUnderlyingRepaid;

    return {
      ...position,
      borrowBalanceUnderlying,
      lifetimeBorrowInterestAccrued,
      lifetimeSupplyInterestAccrued,
      marketId: market.id,
      market: {
        ...market,
        trigger: supplementWithTriggerData(market, triggers, verifiedAddresses),
      },
      supplyBalanceUnderlying,
    };
  });

  const totalBorrowValueInEth = calculateTotalBorrowValue(positions, 'underlyingPrice');
  const totalCollateralValueInEth = calculateTotalCollateralValue(positions, 'underlyingPrice');
  const totalBorrowValueInUsd = calculateTotalBorrowValue(positions, 'underlyingPriceUSD');
  const totalCollateralValueInUsd = calculateTotalCollateralValue(positions, 'underlyingPriceUSD');

  const triggerEvents = extractTriggerEvents(positions, triggers);

  const activityItems = processEvents({
    borrowEvents,
    liquidationEvents,
    mintEvents,
    positions,
    redeemEvents,
    repayEvents,
    triggerEvents,
  });

  return {
    account: {
      activityItems,
      id: account?.id,
      positions,
      proxyAddress,
      totalBorrowValueInEth,
      totalBorrowValueInUsd,
      totalCollateralValueInEth,
      totalCollateralValueInUsd,
    },

    loadingAccountData: false,
    errorLoadingAccountData: false,
  };
};

const calculateTotalBorrowValue = (positions: Position[], price) => {
  return positions?.reduce((acc, position) => {
    return acc + position.borrowBalanceUnderlying * position.market[price];
  }, 0);
};

const calculateTotalCollateralValue = (positions: Position[], price) => {
  return positions?.reduce((acc, position) => {
    if (!position.enteredMarket) {
      return acc;
    }

    return (
      acc + position.supplyBalanceUnderlying * position.market[price] * parseFloat(position.market.collateralFactor)
    );
  }, 0);
};

export const supplementWithTriggerData = (
  market: Market,
  triggers: Trigger[],
  verifiedAddresses: Record<string, VerifiedAddress>,
): Trigger => {
  const trigger = triggers.find((trigger) => trigger.address === market.triggerAddress);

  if (trigger != null) {
    const checksumCreatorAdrress = ethers.utils.getAddress(trigger.creatorAddress);

    const creator =
      TRIGGER_CREATORS.find((creator) => creator.address === trigger.creatorAddress) ??
      findCreatorInVerifiedAddresses(checksumCreatorAdrress, verifiedAddresses);

    return { ...trigger, creator };
  }
};

const findCreatorInVerifiedAddresses = (address: string, verifiedAddresses): TriggerCreator => {
  if (verifiedAddresses == null) {
    return null;
  }

  const verifiedAddress = verifiedAddresses[address];

  if (verifiedAddress) {
    const {
      twitter: { handle },
    } = verifiedAddress;

    return {
      address,
      name: handle,
      logo: null,
      url: urlByHandle(handle),
    };
  }
};

export const extractTriggerEvents = (positions: Position[], triggers: Trigger[]): TriggerEvent[] => {
  const triggeredTriggers = triggers?.filter((trigger) => trigger.triggered);

  return triggeredTriggers?.reduce((acc, trigger) => {
    const position = positions?.find((position) => {
      return position.market.triggerAddress === trigger.address;
    });
    const positionType = determinePositionType(position);

    if (positionType != null) {
      return [
        ...acc,
        {
          blockTime: trigger.triggeredAt,
          id: trigger.id,
          marketId: position.market.id,
          positionId: position.id,
          positionType: determinePositionType(position),
          underlyingSymbol: position.market.underlyingSymbol,
        },
      ];
    }

    return acc;
  }, []);
};

const determinePositionType = (position?: Position): PositionTypes => {
  const hasBorrowed = position?.totalUnderlyingBorrowed > 0;
  const hasSupplied = position?.totalUnderlyingSupplied > 0;

  if (hasBorrowed && hasSupplied) {
    return PositionTypes.BorrowAndSupply;
  } else if (hasBorrowed) {
    return PositionTypes.Borrow;
  } else if (hasSupplied) {
    return PositionTypes.Supply;
  }
};

const processEvents = ({
  borrowEvents,
  liquidationEvents,
  positions,
  mintEvents,
  redeemEvents,
  repayEvents,
  triggerEvents,
}): ActivityItem[] => {
  const items = [
    ...prepareActivityItems(borrowEvents, EventTypes.BorrowEvent, positions),
    ...prepareActivityItems(liquidationEvents, EventTypes.LiquidationEvent, positions),
    ...prepareActivityItems(mintEvents, EventTypes.MintEvent, positions),
    ...prepareActivityItems(redeemEvents, EventTypes.RedeemEvent, positions),
    ...prepareActivityItems(repayEvents, EventTypes.RepayEvent, positions),
    ...prepareActivityItems(triggerEvents, EventTypes.TriggerEvent, positions),
  ];

  return items.sort((a, b) => b.blockTime - a.blockTime);
};

const prepareActivityItems = (
  events: (BorrowEvent | LiquidationEvent | MintEvent | RedeemEvent | RepayEvent | TriggerEvent)[],
  eventType: EventTypes,
  positions: Position[],
): ActivityItem[] => {
  return events.map((event) => {
    const supplementalActivityItemData = prepareSupplementalActivityItemData(event, positions);

    return { blockTime: event.blockTime, event, eventType, ...supplementalActivityItemData };
  });
};

const prepareSupplementalActivityItemData = (event, positions) => {
  const relevantPosition = positions.find((position) => {
    return position.market.id === event.marketId;
  });

  return {
    usdAmount: calculateUsdAmount(relevantPosition, event),
    marketType: determineMarketType(relevantPosition),
    platforms: determinePlatforms(relevantPosition),
  };
};

const determineMarketType = (relevantPosition: Position): MarketTypes => {
  if (relevantPosition.market.triggerAddress == null) {
    return MarketTypes.NonProtectionMarket;
  } else {
    return MarketTypes.ProtectionMarket;
  }
};

const determinePlatforms = (relevantPosition: Position): Platform[] => {
  const {
    market: { trigger },
  } = relevantPosition;

  if (trigger) {
    return findPlatformsByTrigger(PLATFORMS, trigger);
  }
};

const calculateUsdAmount = (relevantPosition: Position, event: Event): number => {
  return event.underlyingAmount * relevantPosition.market.underlyingPriceUSD;
};

export default useFetchAccountData;
