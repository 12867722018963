import { Box, BoxProps } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

interface Props extends BoxProps {
  children: ReactNode;
}

const Container: React.FC<Props> = ({ children, ...props }: Props) => {
  return (
    <Box p={6} borderRadius="xl" border="1px" borderColor="gray.100" {...props}>
      {children}
    </Box>
  );
};
export default Container;
