import { Alert, Flex, Icon, useTheme } from '@chakra-ui/react';

import { GoAlert } from 'react-icons/go';
import React from 'react';
import Text from '@/components/shared/Text';

interface Props {
  status: 'warning' | 'error';
}

const BorrowLimitWarning: React.FC<Props> = ({ status }: Props) => {
  const theme = useTheme();

  const iconColors = {
    error: theme.utilityColors.errorColor,
    warning: theme.utilityColors.warningColor,
  };

  return (
    <Alert p="1em" borderBottomRadius="lg" status={status} justifyContent="center">
      <Flex alignItems="center" justifyContent="center">
        <Icon as={GoAlert} boxSize="0.8em" color={iconColors[status]} />
        <Text ml={3} fontSize="sm">
          Your collateral may be liquidated if use over 100% of your limit
        </Text>
      </Flex>
    </Alert>
  );
};
export default BorrowLimitWarning;
