import { Market, Position, PositionTypes } from '@/types';
import { formatPercentage, formatTokenAmount, formatUsdAmount } from './formatHelpers';

import { MIN_INTEREST_RATE_FOR_DISPLAY } from './accountHelpers';

export const MIN_POSITION_BALANCE = 0.01; // one cent USD

interface PositionStats {
  borrowRate: number;
  formattedBorrowRate: string;
  formattedBorrowTokenBalance: string;
  formattedBuyingPower: string;
  formattedInvestRate: string;
  formattedInvestTokenBalance: string;
  formattedLifetimeEarnings: string;
  formattedLifetimeInterest: string;
  formattedNetInvestEarnings: string;
  formattedNetRate: string;
  formattedNetUSDInvestEarnings: string;
  formattedSupplyRate: string;
  formattedSupplyTokenBalance: string;
  formattedUSDBorrowBalance: string;
  formattedUSDInvestBalance: string;
  formattedUSDLifetimeEarnings: string;
  formattedUSDLifetimeInterest: string;
  formattedUSDSupplyBalance: string;
  lifetimeEarnings: number;
  lifetimeInterest: number;
  netInvestEarnings: number;
  netRate: number;
  netUSDInvestEarnings: number;
  supplyRate: number;
  usdBorrowBalance: number;
  usdInvestBalance: number;
  usdSupplyBalance: number;
}

const PERCENTAGE_FORMATTER = '0.0%';
const DOLLAR_FORMATTER = '$0,0.00';

export const calculatePositionStats = (position: Position | null, market: Market): PositionStats => {
  if (position == null) {
    const netRate = market.investRate != null ? market.investRate - market.borrowRate : 0;

    return {
      borrowRate: market.borrowRate,
      formattedBorrowRate: formatRate(market.borrowRate),
      formattedBorrowTokenBalance: '0',
      formattedBuyingPower: '$0',
      formattedInvestRate: formatRate(market.investRate),
      formattedInvestTokenBalance: '0',
      formattedLifetimeEarnings: '0',
      formattedLifetimeInterest: '0',
      formattedUSDLifetimeEarnings: '$0',
      formattedUSDLifetimeInterest: '$0',
      formattedNetInvestEarnings: '0',
      formattedNetRate: formatRate(netRate),
      formattedNetUSDInvestEarnings: '$0',
      formattedSupplyRate: formatRate(market.supplyRate),
      formattedSupplyTokenBalance: '0',
      formattedUSDBorrowBalance: '$0',
      formattedUSDInvestBalance: '$0',
      formattedUSDSupplyBalance: '$0',
      lifetimeEarnings: 0,
      lifetimeInterest: 0,
      netInvestEarnings: 0,
      netRate: netRate,
      netUSDInvestEarnings: 0,
      supplyRate: market.supplyRate,
      usdBorrowBalance: 0,
      usdInvestBalance: 0,
      usdSupplyBalance: 0,
    };
  }

  const { borrowRate, collateralFactor, investRate, supplyRate, underlyingSymbol, underlyingPriceUSD } = market;

  const { borrowBalanceUnderlying, investBalanceUnderlying, supplyBalanceUnderlying } = position;

  const formattedSupplyTokenBalance = position
    ? formatTokenAmount(supplyBalanceUnderlying, underlyingSymbol, 0, 4)
    : '0';

  const formattedBorrowTokenBalance = position
    ? formatTokenAmount(borrowBalanceUnderlying, underlyingSymbol, 0, 4)
    : '0';

  const formattedInvestTokenBalance = position
    ? formatTokenAmount(investBalanceUnderlying, underlyingSymbol, 0, 4)
    : '0';

  const usdSupplyBalance = supplyBalanceUnderlying * underlyingPriceUSD;
  const formattedUSDSupplyBalance = position ? formatUsdAmount(usdSupplyBalance, DOLLAR_FORMATTER) : formatUsdAmount(0);

  const usdBorrowBalance = borrowBalanceUnderlying * underlyingPriceUSD;
  const formattedUSDBorrowBalance = position ? formatUsdAmount(usdBorrowBalance, DOLLAR_FORMATTER) : formatUsdAmount(0);

  const usdInvestBalance = (investBalanceUnderlying ?? 0) * underlyingPriceUSD;
  const formattedUSDInvestBalance = position ? formatUsdAmount(usdInvestBalance, DOLLAR_FORMATTER) : formatUsdAmount(0);

  const lifetimeEarnings = position ? position.lifetimeSupplyInterestAccrued * underlyingPriceUSD : 0;
  const formattedLifetimeEarnings = formatTokenAmount(lifetimeEarnings, underlyingSymbol, 0, 4);
  const formattedUSDLifetimeEarnings = formatUsdAmount(lifetimeEarnings);

  const lifetimeInterest = position ? position.lifetimeBorrowInterestAccrued * underlyingPriceUSD : 0;
  const formattedLifetimeInterest = formatTokenAmount(lifetimeInterest, underlyingSymbol, 0, 4);
  const formattedUSDLifetimeInterest = formatUsdAmount(lifetimeInterest);

  const netRate = investRate - borrowRate;
  const netInvestEarnings = investBalanceUnderlying - borrowBalanceUnderlying;
  const formattedNetInvestEarnings = formatTokenAmount(netInvestEarnings, underlyingSymbol, 0, 4);

  const netUSDInvestEarnings = usdInvestBalance - usdBorrowBalance;
  const formattedNetUSDInvestEarnings = formatUsdAmount(netUSDInvestEarnings);

  const formattedSupplyRate = formatRate(supplyRate);
  const formattedBorrowRate = formatRate(borrowRate);
  const formattedInvestRate = formatRate(investRate);
  const formattedNetRate = formatRate(netRate);

  const formattedBuyingPower = formatUsdAmount(
    supplyBalanceUnderlying * underlyingPriceUSD * parseFloat(collateralFactor),
  );

  return {
    borrowRate,
    formattedBorrowRate,
    formattedBorrowTokenBalance,
    formattedBuyingPower,
    formattedInvestRate,
    formattedInvestTokenBalance,
    formattedLifetimeEarnings,
    formattedLifetimeInterest,
    formattedNetInvestEarnings,
    formattedNetRate,
    formattedNetUSDInvestEarnings,
    formattedSupplyRate,
    formattedSupplyTokenBalance,
    formattedUSDBorrowBalance,
    formattedUSDInvestBalance,
    formattedUSDLifetimeEarnings,
    formattedUSDLifetimeInterest,
    formattedUSDSupplyBalance,
    lifetimeEarnings,
    lifetimeInterest,
    netInvestEarnings,
    netRate,
    netUSDInvestEarnings,
    supplyRate,
    usdBorrowBalance,
    usdInvestBalance,
    usdSupplyBalance,
  };
};

const formatRate = (rate: number) =>
  formatPercentage(Math.abs(rate) < MIN_INTEREST_RATE_FOR_DISPLAY ? 0 : rate, PERCENTAGE_FORMATTER);

export const checkIfPositionHasBalance = (position: Position, positionType: PositionTypes, market: Market): boolean => {
  if (position == null) {
    return false;
  }

  const { borrowBalanceUnderlying, investBalanceUnderlying, supplyBalanceUnderlying } = position;
  const { underlyingPriceUSD } = market;

  switch (positionType) {
    case PositionTypes.Supply:
      return supplyBalanceUnderlying * underlyingPriceUSD > MIN_POSITION_BALANCE;
    case PositionTypes.Borrow:
      return borrowBalanceUnderlying * underlyingPriceUSD > MIN_POSITION_BALANCE;
    case PositionTypes.Invest:
      return (
        borrowBalanceUnderlying * underlyingPriceUSD > MIN_POSITION_BALANCE ||
        investBalanceUnderlying * underlyingPriceUSD > MIN_POSITION_BALANCE
      );
    default:
      throw "Couldn't find position type";
  }
};
