import { ETHEREUM_BURN_ADDRESS, PROXY_REGISTRY_ADDRESS } from '@/constants';
import { EthereumTransactionTypes, TransactionContextInterface } from '@/types';
import { cleanUpStateAndNotify, defaultProvider } from '@/helpers/transactionHelpers';

import dsProxyRegistryAbi from '@/abis/dsProxyRegistryAbi';
import { ethers } from 'ethers';

export const bulidProxy = async (
  chainId: number,
  signer: ethers.Signer,
  transactionContext: TransactionContextInterface,
  setProxyDeployTransactionSubmitted: React.Dispatch<React.SetStateAction<boolean>>,
  setProxyDeployTransactionCompleted: React.Dispatch<React.SetStateAction<boolean>>,
): Promise<void> => {
  try {
    const proxyRegistryAddress = PROXY_REGISTRY_ADDRESS[chainId];

    if (proxyRegistryAddress) {
      const proxyRegistryContract = new ethers.Contract(proxyRegistryAddress, dsProxyRegistryAbi, signer);

      const { hash } = await proxyRegistryContract.build();

      cleanUpStateAndNotify({
        onConfirmed: () => setProxyDeployTransactionCompleted(true),
        hash,
        setIsSubmitting: () => setProxyDeployTransactionSubmitted(true),
        transactionContext,
        type: EthereumTransactionTypes.ProxyWallet,
      });
    }
  } catch (error) {
    setProxyDeployTransactionSubmitted(false);
  }
};

export const getProxyAddress = async (chainId: number, walletAddress: string): Promise<string> => {
  const provider = defaultProvider(chainId);

  const proxyRegistryAddress = PROXY_REGISTRY_ADDRESS[chainId];

  if (proxyRegistryAddress && walletAddress) {
    const proxyRegistryContract = new ethers.Contract(proxyRegistryAddress, dsProxyRegistryAbi, provider);

    const address = await proxyRegistryContract.proxies(walletAddress);

    if (address === ETHEREUM_BURN_ADDRESS) {
      return null;
    } else {
      return address;
    }
  }
};
