import { Account, ButtonType, Comptroller, Market, Position, TransactionStatuses } from '@/types';
import { ButtonGroup, Flex, NumberInput, NumberInputField } from '@chakra-ui/react';
import React, { useContext, useState } from 'react';
import {
  checkMaxRedeemableAmount,
  redeemAmountIsValid,
  submitRedeemTransaction,
} from '@/helpers/supplyTransactionHelpers';
import { formatTokenAmount, formatUsdAmount } from '@/helpers/formatHelpers';
import { inputDisplayAmount, onAmountChange, setPercentageAmount } from '@/helpers/manageModalHelpers';
import { trackEvent, triggerAnalyticsProps } from '@/utils/analytics';

import Button from '@/components/shared/Button';
import Text from '@/components/shared/Text';
import { TransactionContext } from '@/contexts/TransactionContext';
import { calculateBorrowLimitStats } from '@/helpers/borrowLimitHelpers';
import { useWeb3React } from '@web3-react/core';

interface Props {
  account: Account;
  comptroller: Comptroller;
  market: Market;
  onModalClose: () => void;
  position: Position;
  setRedeemAmount: React.Dispatch<React.SetStateAction<string>>;
  redeemAmount: string;
  usdAdditionalCollateral: number;
}

const RedeemTab: React.FC<Props> = ({
  account,
  comptroller,
  market,
  onModalClose,
  position,
  setRedeemAmount,
  usdAdditionalCollateral,
  redeemAmount,
}: Props) => {
  const { account: walletAddress, chainId, library } = useWeb3React();
  const transactionContext = useContext(TransactionContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isRedeemAll, setIsRedeemAll] = useState(false);

  const supplyBalanceUnderlying = position?.supplyBalanceUnderlying;

  const usdWithdrawValue = parseFloat(redeemAmount) * market.underlyingPriceUSD;

  const formattedUnderlyingAmount = position
    ? formatTokenAmount(position.supplyBalanceUnderlying, market.underlyingSymbol, 0, 4)
    : 0;

  const cTokenBalance = position?.cTokenBalance;

  const signer = library?.getSigner(walletAddress);

  const displayWithdrawAmount = inputDisplayAmount(redeemAmount);

  const onRedeem = () => {
    trackEvent('Redeem Clicked', {
      amount: redeemAmount,
      marketId: market.id,
      asset: market.underlyingSymbol,
      ...triggerAnalyticsProps(market.trigger),
    });

    setIsSubmitting(true);
    submitRedeemTransaction({
      chainId,
      cTokenBalance,
      isRedeemAll,
      market,
      multicallAddress: comptroller.multicallAddress,
      proxyAddress: account?.proxyAddress,
      setRedeemAmount,
      setIsSubmitting: ({ status }) => {
        setIsSubmitting(false);
        if (status == TransactionStatuses.Sent) {
          onModalClose();
        }
      },
      signer,
      transactionContext,
      walletAddress: walletAddress,
      redeemAmount,
    });
  };
  const { canRedeemMax, maxRedeemAmountUnderlying } = checkMaxRedeemableAmount(
    account,
    market,
    supplyBalanceUnderlying,
  );

  const { isInLiquidation } = calculateBorrowLimitStats(account, usdAdditionalCollateral, 0);
  const { amountIsValid, errorMessage } = redeemAmountIsValid(
    redeemAmount,
    position?.supplyBalanceUnderlying,
    isInLiquidation,
    market,
  );

  return (
    <Flex direction="column" alignItems="center">
      <Flex direction="column" alignItems="center" my="2em">
        <Flex>
          <NumberInput
            isDisabled={position == null}
            onChange={(value) => {
              setIsRedeemAll(false);
              onAmountChange(setRedeemAmount)(value);
            }}
            value={displayWithdrawAmount}
            variant="unstyled"
            color={parseFloat(redeemAmount) == 0 ? 'gray.500' : null}
          >
            <NumberInputField paddingInlineEnd="0" fontSize="5xl" fontWeight="800" textAlign="center" placeholder="0" />
          </NumberInput>
        </Flex>
        <Text fontSize="lg" color="gray.500">
          Equal to {formatUsdAmount(usdWithdrawValue, '$0,0.00')}
        </Text>
      </Flex>

      <Flex alignItems="baseline" mt="1.5em" mb="2em">
        <Text fontSize="md" fontWeight="600">
          {formattedUnderlyingAmount} {market.underlyingSymbol}
        </Text>
        <Text fontSize="md" ml={1}>
          Available in Cozy Balance
        </Text>
      </Flex>

      <ButtonGroup isAttached variant="outline" mb="2em">
        <Button
          isDisabled={position == null}
          onClick={() => {
            setIsRedeemAll(false);
            setPercentageAmount(supplyBalanceUnderlying.toString(), 25, 0, setRedeemAmount);
          }}
          mr="-px"
        >
          25%
        </Button>
        <Button
          isDisabled={position == null}
          onClick={() => {
            setIsRedeemAll(false);
            setPercentageAmount(supplyBalanceUnderlying.toString(), 50, 0, setRedeemAmount);
          }}
          mr="-px"
        >
          50%
        </Button>
        <Button
          isDisabled={position == null}
          onClick={() => {
            setIsRedeemAll(false);
            setPercentageAmount(supplyBalanceUnderlying.toString(), 75, 0, setRedeemAmount);
          }}
          mr="-px"
        >
          75%
        </Button>
        <Button
          isDisabled={position == null}
          onClick={() => {
            if (canRedeemMax) {
              setIsRedeemAll(true);
              setPercentageAmount(supplyBalanceUnderlying.toString(), 100, 0, setRedeemAmount);
            } else {
              onAmountChange(setRedeemAmount)(maxRedeemAmountUnderlying.toString());
            }
          }}
        >
          {canRedeemMax ? '100%' : 'Max'}
        </Button>
      </ButtonGroup>

      <Button
        appButtonType={ButtonType.Primary}
        isDisabled={position == null || !amountIsValid || isSubmitting}
        isLoading={isSubmitting}
        loadingText={`Withdraw ${market.underlyingSymbol}`}
        mb="2em"
        minW="280px"
        onClick={onRedeem}
      >
        {amountIsValid ? `Withdraw ${market.underlyingSymbol}` : errorMessage}
      </Button>
    </Flex>
  );
};
export default RedeemTab;
