import { Image } from '@/components/shared/Image';
import React from 'react';

interface Props {
  alt: string;
  boxSize?: number;
  src: string;
}

const CHAKRA_DISPLAY_UNITS_TO_PIXELS = 4;

const Logo: React.FC<Props> = ({ alt, src, boxSize = 10 }: Props) => {
  return (
    <Image
      alt={alt}
      loading="eager"
      htmlWidth={boxSize * CHAKRA_DISPLAY_UNITS_TO_PIXELS}
      htmlHeight={boxSize * CHAKRA_DISPLAY_UNITS_TO_PIXELS}
      src={src}
    />
  );
};

export default Logo;
