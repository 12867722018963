import { Market, Position } from '@/types';

import { Box } from '@chakra-ui/react';
import EarningsTabDetails from './EarningsTabDetails';
import Heading from '@/components/shared/Heading';
import React from 'react';
import Text from '@/components/shared/Text';
import { calculatePositionStats } from '@/helpers/positionHelpers';

interface Props {
  market: Market;
  position: Position;
}

const EarningsTab: React.FC<Props> = ({ market, position }: Props) => {
  const { formattedNetUSDInvestEarnings } = calculatePositionStats(position, market);

  return (
    <>
      <Box>
        <Box textAlign="center" mt="2rem" mb="2.5rem">
          <Text color="gray.500" fontSize="lg" fontWeight="600" mb="1rem">
            Net Earnings
          </Text>
          <Heading fontSize="4xl">{formattedNetUSDInvestEarnings}</Heading>
        </Box>

        <EarningsTabDetails market={market} position={position} />
      </Box>
    </>
  );
};
export default EarningsTab;
