import { Box, TabList } from '@chakra-ui/react';

import React from 'react';
import Tab from '@/components/shared/Tab';

interface Props {
  tabs: string[];
}

const ManageModalTabList: React.FC<Props> = ({ tabs }: Props) => {
  return (
    <Box overflowX="auto">
      <TabList>
        {tabs.map((tab, index) => (
          <Tab whiteSpace="nowrap" key={index}>
            {tab}
          </Tab>
        ))}
      </TabList>
    </Box>
  );
};
export default ManageModalTabList;
