import { ButtonType, Market } from '@/types';
import { Flex, Stack } from '@chakra-ui/react';
import React, { useContext } from 'react';

import ApproveButton from '@/components/modals/manage_modal/ApproveButton';
import Button from '@/components/shared/Button';
import Heading from '@/components/shared/Heading';
import PreDepositStep from './PreDepositStep';
import { ReactNode } from 'react';
import { TransactionContext } from '@/contexts/TransactionContext';
import { bulidProxy } from '@/hooks/proxyWallet';
import { checkApprovalStatuses } from '@/helpers/approveTransactionHelpers';
import { trackEvent } from '@/utils/analytics';
import { useWeb3React } from '@web3-react/core';

interface Props {
  depositButton: ReactNode;
  isApproved: boolean;
  isEthMarket: boolean;
  market: Market;
  proxyAddress: string;
  proxyDeployTransactionSubmitted: boolean;
  setProxyDeployTransactionSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  approveTransactionIsSubmitting: boolean;
  setApproveTransactionIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
}

const PreDepositSteps: React.FC<Props> = ({
  depositButton,
  isApproved,
  isEthMarket,
  market,
  proxyAddress,
  approveTransactionIsSubmitting,
  setApproveTransactionIsSubmitting,
  proxyDeployTransactionSubmitted,
  setProxyDeployTransactionSubmitted,
}: Props) => {
  const transactionContext = useContext(TransactionContext);
  const { setProxyDeployTransactionCompleted, transactions } = transactionContext;
  const { account: walletAddress, chainId, library } = useWeb3React();
  const signer = library?.getSigner(walletAddress);

  const { hasPendingApproveTransferTransactions, hasSuccessfulApproveTransferTransactions } = checkApprovalStatuses(
    transactions,
    walletAddress,
    market,
  );

  const proxyDeployed = proxyAddress != null;

  const deployButton = (
    <Button
      appButtonType={ButtonType.Primary}
      onClick={() => {
        trackEvent('Deploy Proxy Wallet Clicked', { marketId: market.id, asset: market.underlyingSymbol });
        setProxyDeployTransactionSubmitted(true);
        bulidProxy(
          chainId,
          signer,
          transactionContext,
          setProxyDeployTransactionSubmitted,
          setProxyDeployTransactionCompleted,
        );
      }}
    >
      Start
    </Button>
  );

  const approveButton = (
    <ApproveButton market={market} proxyAddress={proxyAddress} setIsSubmitting={setApproveTransactionIsSubmitting} />
  );

  return (
    <Flex direction="column" my="2em" mx={2}>
      <Heading fontSize="2xl" mb="2rem">
        To make your first deposit
      </Heading>
      <Stack direction="column" spacing={8}>
        <PreDepositStep
          body="You only have to do this once!"
          button={deployButton}
          completeTitle="Proxy Wallet Deployed"
          isComplete={proxyDeployed}
          isNext={!proxyDeployed}
          isPending={proxyDeployTransactionSubmitted}
          title="Deploy Proxy Wallet"
        />
        {!isEthMarket && (
          <PreDepositStep
            body="Approve proxy to transfer tokens."
            button={approveButton}
            completeTitle="Transfers Approved"
            isComplete={isApproved}
            isNext={proxyDeployed && !isApproved}
            isPending={
              approveTransactionIsSubmitting ||
              hasPendingApproveTransferTransactions ||
              hasSuccessfulApproveTransferTransactions
            }
            title="Approve Transfer"
          />
        )}
        <PreDepositStep
          body={`Deposit funds into the market.`}
          button={depositButton}
          completeTitle="Deposit initiated"
          isNext={isApproved}
          title={`Deposit ${market.underlyingSymbol}`}
        />
      </Stack>
    </Flex>
  );
};
export default PreDepositSteps;
