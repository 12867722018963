import { BoxProps } from '@chakra-ui/react';
import Logo from '@/components/shared/Logo';
import React from 'react';

interface Props extends BoxProps {
  boxSize?: number;
  name: string;
  symbol: string;
}

const TokenLogo: React.FC<Props> = ({ boxSize = 6, name, symbol, ...props }: Props) => {
  return <Logo {...props} alt={`${name} logo`} boxSize={boxSize} src={`/images/tokens/${symbol.toLowerCase()}.png`} />;
};

export default TokenLogo;
