import { BoxProps } from '@chakra-ui/react';
import Logo from './Logo';
import React from 'react';
import { TriggerCreator } from '@/types';

export interface TriggerCreatorLogoProps extends BoxProps {
  boxSize?: number;
  triggerCreator?: TriggerCreator;
}

const TriggerCreatorLogo: React.FC<TriggerCreatorLogoProps> = ({
  triggerCreator,
  boxSize = 6,
  ...props
}: TriggerCreatorLogoProps) => {
  if (triggerCreator == null) return null;

  return (
    <Logo
      alt={`${triggerCreator.name} logo`}
      {...props}
      src={`/images/trigger_creators/${triggerCreator?.logo}`}
      boxSize={boxSize}
    />
  );
};

export default TriggerCreatorLogo;
