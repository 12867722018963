import { Box, Flex, Icon, Progress, SlideFade, useTheme } from '@chakra-ui/react';
import { formatPercentage, formatUsdAmount } from '@/helpers/formatHelpers';

import { HiArrowNarrowRight } from 'react-icons/hi';
import React from 'react';
import Text from '@/components/shared/Text';
import { chooseBorrowingPowerBarColor } from '@/helpers/borrowingPowerHelpers';

interface Props {
  isInLiquidation: boolean;
  isNearLiquidation: boolean;
  percentBorrowed: number;
  projPercentBorrowed: number;
  usdBorrowed: number;
}

const BorrowPowerPercentageDisplay: React.FC<Props> = ({
  isInLiquidation,
  isNearLiquidation,
  percentBorrowed,
  projPercentBorrowed,
  usdBorrowed,
}: Props) => {
  const theme = useTheme();
  const borrowPowerPercentageHasProjection = percentBorrowed != projPercentBorrowed;
  const projectionExists = percentBorrowed != projPercentBorrowed;

  const colorScheme = chooseBorrowingPowerBarColor(isInLiquidation, isNearLiquidation);

  return (
    <Flex mt={2} alignItems="center">
      <Flex fontSize="sm" fontWeight="600" alignItems="center">
        <Text transition="color 0.1s ease-in-out" color={borrowPowerPercentageHasProjection ? 'gray.500' : null}>
          {formatPercentage(percentBorrowed)} {!borrowPowerPercentageHasProjection && 'used'}
        </Text>

        <SlideFade in={borrowPowerPercentageHasProjection} offsetX={-20} offsetY={0}>
          {borrowPowerPercentageHasProjection && (
            <Flex>
              <Icon mx={2} as={HiArrowNarrowRight} boxSize="16px" color="gray.500" />
              {isInLiquidation ? (
                <Text color={theme.utilityColors.errorColor}>Liquidation</Text>
              ) : (
                <Text color={isNearLiquidation ? theme.utilityColors.warningColor : null}>
                  {formatPercentage(projPercentBorrowed)} used
                </Text>
              )}
            </Flex>
          )}
        </SlideFade>
      </Flex>

      <Box flex={1} position="relative">
        <Progress
          position="absolute"
          top="-1"
          value={projPercentBorrowed * 100}
          height="8px"
          ml={4}
          width="100%"
          colorScheme={colorScheme}
          borderRadius="100px"
        />

        {projectionExists && (
          <Progress
            position="absolute"
            top="-1"
            value={percentBorrowed * 100}
            height="8px"
            ml={4}
            background="transparent"
            width="100%"
            opacity="0.52"
            colorScheme="blackAlpha"
            borderRadius="100px"
          />
        )}
      </Box>
      <Flex alignItems="center" ml={8}>
        <Text fontSize="sm" fontWeight="600" color="gray.700">
          {formatUsdAmount(usdBorrowed, '$0,0.00')} used
        </Text>
      </Flex>
    </Flex>
  );
};

export default BorrowPowerPercentageDisplay;
