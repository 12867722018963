import { Account, Comptroller, Market, PositionTypes } from '@/types';
import { Box, Flex, Modal, ModalContent, ModalOverlay, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import React, { useState } from 'react';

import ActivityTab from './manage_modal/ActivityTab/ActivityTab';
import BorrowLimitModalPanel from './manage_modal/BorrowingPowerModalPanel/BorrowingPowerModalPanel';
import ConnectWalletGateModal from './ConnectWalletGateModal';
import DepositTab from './manage_modal/DepositTab/DepositTab';
import ManageModalHeader from './manage_modal/ManageModalHeader';
import ManageModalTabLists from './manage_modal/ManageModalTabList';
import RedeemTab from './manage_modal/RedeemTab/RedeemTab';
import SupplyBalanceTab from './manage_modal/BalanceTabs/SupplyBalanceTab';
import TriggerPanel from './manage_modal/TriggerPanel';
import { calculateAdditionalCollateral } from '@/helpers/borrowingPowerHelpers';
import { findPositionByMarketId } from '@/helpers/findHelpers';
import { useGetModalSize } from '@/helpers/manageModalHelpers';
import useManageRouterParams from '@/hooks/useManageRouterParams';
import { useWeb3React } from '@web3-react/core';

interface Props {
  account: Account;
  comptroller: Comptroller;
  markets: Market[];
  onClose: () => void;
}

export enum SupplyModalTabs {
  SupplyBalance,
  Deposit,
  Withdraw,
  Activity,
}

const TAB_NAMES = {
  [SupplyModalTabs.SupplyBalance]: 'Balance',
  [SupplyModalTabs.Deposit]: 'Deposit',
  [SupplyModalTabs.Withdraw]: 'Withdraw',
  [SupplyModalTabs.Activity]: 'Activity',
};

const TABS = [
  SupplyModalTabs.SupplyBalance,
  SupplyModalTabs.Deposit,
  SupplyModalTabs.Withdraw,
  SupplyModalTabs.Activity,
];

const SHOW_BORROW_LIMIT_TABS = [SupplyModalTabs.Deposit, SupplyModalTabs.Withdraw];

const ManageModal: React.FC<Props> = ({ account, comptroller, markets, onClose }: Props) => {
  const { tab, market, setTab } = useManageRouterParams(markets);
  const { account: walletAccount } = useWeb3React();
  const [depositAmount, setDepositAmount] = useState('0');
  const [redeemAmount, setRedeemAmount] = useState('0');

  const { proxyAddress } = account;
  const hasTrigger = market?.triggerAddress != null;

  const size = useGetModalSize(hasTrigger, walletAccount);

  if (market == null) return null;

  const position = findPositionByMarketId(account, market.id);

  const usdAdditionalCollateral = calculateAdditionalCollateral(tab, depositAmount, redeemAmount, market);

  const showBorrowLimit = SHOW_BORROW_LIMIT_TABS.includes(tab) && market.triggerAddress == null;

  const onModalClose = () => {
    setDepositAmount('0');
    setRedeemAmount('0');
    onClose();
  };

  return (
    <Modal isOpen={market != null} onClose={onModalClose} size={size}>
      <ModalOverlay />
      <ModalContent backgroundColor="transparent">
        <Box backgroundColor="white" borderRadius="lg" mb={6}>
          <Flex direction={{ base: 'column', md: 'row' }}>
            <Box flex={1}>
              {walletAccount == null && <ConnectWalletGateModal />}

              {walletAccount != null && (
                <>
                  <ManageModalHeader market={market} />

                  <Tabs isFitted index={tab} onChange={setTab} borderBottomRadius="2xl">
                    <ManageModalTabLists tabs={TABS.map((tab) => TAB_NAMES[tab])} />
                    <TabPanels>
                      <TabPanel>
                        <SupplyBalanceTab market={market} position={position} />
                      </TabPanel>
                      <TabPanel>
                        <DepositTab
                          account={account}
                          comptroller={comptroller}
                          depositAmount={depositAmount}
                          market={market}
                          markets={markets}
                          onModalClose={onModalClose}
                          position={position}
                          proxyAddress={proxyAddress}
                          setDepositAmount={setDepositAmount}
                        />
                      </TabPanel>
                      <TabPanel>
                        <RedeemTab
                          account={account}
                          comptroller={comptroller}
                          market={market}
                          onModalClose={onModalClose}
                          position={position}
                          setRedeemAmount={setRedeemAmount}
                          redeemAmount={redeemAmount}
                          usdAdditionalCollateral={usdAdditionalCollateral}
                        />
                      </TabPanel>
                      <TabPanel p={0}>
                        <ActivityTab account={account} market={market} positionType={PositionTypes.Supply} />
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </>
              )}
            </Box>

            {market.triggerAddress && walletAccount != null && (
              <TriggerPanel market={market} positionType={PositionTypes.Supply} />
            )}
          </Flex>
        </Box>

        {showBorrowLimit && (
          <BorrowLimitModalPanel account={account} usdAdditionalCollateral={usdAdditionalCollateral} />
        )}
      </ModalContent>
    </Modal>
  );
};

export default ManageModal;
