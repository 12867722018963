import { Account, Comptroller, CozyEvents, EthereumCall, EthereumTransactionTypes, Market, Position } from '@/types';
import {
  catchError,
  cleanUpStateAndNotify,
  determineGasLimit,
  initializeContracts,
  marketHasEnoughCash,
  prepareContractParam,
} from './transactionHelpers';
import { trackEvent, triggerAnalyticsProps } from '@/utils/analytics';

import { BigNumber as BNE } from 'ethers';
import { BaseTransactionParams } from './approveTransactionHelpers';
import BigNumber from 'bignumber.js';
import { MaxUint256 } from '@ethersproject/constants';
import React from 'react';
import { isEth } from './assetHelpers';

// DUST_BUFFER adds a buffer so that the transaction modal button isn't disabled because of rounding errors
const DUST_BUFFER = 0.0000001;

const DEPOSIT_GAS_LIMIT = 400000;
const REDEEM_GAS_LIMIT = 750000;

interface DepositParams extends BaseTransactionParams {
  comptroller: Comptroller;
  cozyIsApproved: boolean;
  depositAmount: string;
  position: Position;
  setDepositAmount: React.Dispatch<React.SetStateAction<string>>;
}

interface RedeemParams extends BaseTransactionParams {
  cTokenBalance: number;
  isRedeemAll: boolean;
  redeemAmount: string;
  setRedeemAmount: React.Dispatch<React.SetStateAction<string>>;
}

export interface AmountValidityCheck {
  amountIsValid: boolean;
  errorMessage?: string;
}

export const submitDepositTransaction = async ({
  chainId,
  comptroller,
  depositAmount,
  cozyIsApproved,
  market,
  multicallAddress,
  onConfirmed,
  position,
  proxyAddress,
  setDepositAmount,
  setIsSubmitting,
  signer,
  transactionContext,
  walletAddress: walletAddress,
}: DepositParams): Promise<void> => {
  const trackingProps = {
    type: EthereumTransactionTypes.Deposit,
    amount: depositAmount,
    marketId: market.id,
    asset: market.underlyingSymbol,
    ...triggerAnalyticsProps(market.trigger),
  };

  const { underlyingAddress } = market;

  const { comptrollerContract, marketContract, multicallContract, proxyContract, tokenContract } = initializeContracts({
    comptrollerAddress: comptroller.address,
    marketAddress: market.id,
    multicallAddress,
    proxyAddress,
    signer,
    underlyingAddress,
  });

  const isEthMarket = isEth(underlyingAddress);

  const depositParam = prepareContractParam(depositAmount, market.underlyingDecimals);

  const calls: EthereumCall[] = [];

  if (position?.enteredMarket != true && market.triggerAddress == null) {
    // Conditionally enter proxy wallet into Cozy market contract so funds can be used as collateral
    calls.push({
      data: comptrollerContract.interface.encodeFunctionData('enterMarkets', [[market.id]]),
      requireSuccess: true,
      target: comptrollerContract.address,
      value: 0,
    });
  }

  if (isEthMarket) {
    calls.push(
      // Call mint on Cozy market contract
      {
        data: marketContract.interface.encodeFunctionData('mint'),
        requireSuccess: true,
        target: market.id,
        value: depositParam,
      },
    );
  } else {
    if (cozyIsApproved != true) {
      // Conditionally approve Cozy market to transfer erc20 on behalf of proxy wallet
      calls.push({
        data: tokenContract.interface.encodeFunctionData('approve', [market.id, MaxUint256]),
        requireSuccess: true,
        target: underlyingAddress,
        value: 0,
      });
    }

    // Transfer funds from personal wallet to proxy wallet
    calls.push({
      data: tokenContract.interface.encodeFunctionData('transferFrom', [walletAddress, proxyAddress, depositParam]),
      requireSuccess: true,
      target: underlyingAddress,
      value: 0,
    });

    // Mint cTokens on Cozy market contrat
    calls.push({
      data: marketContract.interface.encodeFunctionData('mint', [depositParam]),
      requireSuccess: true,
      target: market.id,
      value: 0,
    });
  }

  const encodedCalls = multicallContract.interface.encodeFunctionData('batchCalls', [calls]);

  const overrides = { value: isEthMarket ? depositParam : 0 };
  let estimatedGasLimit = null;
  try {
    estimatedGasLimit = await proxyContract.estimateGas['execute(address,bytes)'](
      multicallContract.address,
      encodedCalls,
      overrides,
    );
  } catch (e) {
    trackEvent('Gas Estimate Errored', trackingProps);
    console.log('Error estimating gas', e);
  }

  let hash;

  try {
    const response = await proxyContract['execute(address,bytes)'](multicallContract.address, encodedCalls, {
      ...overrides,
      ...determineGasLimit(estimatedGasLimit?.toNumber(), chainId, DEPOSIT_GAS_LIMIT),
    });

    hash = response.hash;
    trackEvent('Deposit Transaction Submitted', { transactionHash: hash, ...trackingProps });

    cleanUpStateAndNotify({
      amount: depositAmount,
      cleanUpFunction: setDepositAmount,
      cozyEvent: CozyEvents.Mint,
      hash,
      market,
      marketContract,
      onConfirmed,
      provider: signer.provider,
      setIsSubmitting,
      transactionContext,
      type: EthereumTransactionTypes.Deposit,
    });
  } catch (error) {
    catchError(error, setIsSubmitting, { transactionHash: hash, ...trackingProps });
  }
};

export const submitRedeemTransaction = async ({
  chainId,
  cTokenBalance,
  isRedeemAll,
  market,
  multicallAddress,
  proxyAddress,
  setIsSubmitting,
  setRedeemAmount,
  signer,
  transactionContext,
  walletAddress,
  redeemAmount,
}: RedeemParams): Promise<void> => {
  const trackingProps = {
    type: EthereumTransactionTypes.Redeem,
    amount: redeemAmount,
    marketId: market.id,
    asset: market.underlyingSymbol,
    ...triggerAnalyticsProps(market.trigger),
  };
  let response = null;
  const { underlyingAddress } = market;

  const { marketContract, multicallContract, proxyContract, tokenContract } = initializeContracts({
    marketAddress: market.id,
    multicallAddress,
    proxyAddress,
    signer,
    underlyingAddress,
  });

  const redeemUnderlyingParam = prepareContractParam(redeemAmount, market.underlyingDecimals);

  // Transfer erc20 from proxy wallet to personal wallet
  const erc20Transfer = {
    data: tokenContract.interface.encodeFunctionData('transfer', [walletAddress, redeemUnderlyingParam]),
    requireSuccess: true,
    target: underlyingAddress, // token contract
    value: 0,
  };

  // Transfer eth from proxy wallet to presonal wallet
  const ethTransfer = {
    data: '0x',
    requireSuccess: true,
    target: walletAddress,
    value: redeemUnderlyingParam,
  };
  const calls: EthereumCall[] = [];

  if (isRedeemAll === true) {
    const cTokenBalanceParam = prepareContractParam(cTokenBalance, market.decimals);

    // Redeem cTokens on Cozy market contract
    calls.push({
      target: market.id, // market contract
      data: marketContract.interface.encodeFunctionData('redeem', [cTokenBalanceParam]),
      value: 0,
      requireSuccess: true,
    });
  } else {
    // Redeem cTokens on Cozy market contract
    calls.push({
      data: marketContract.interface.encodeFunctionData('redeemUnderlying', [redeemUnderlyingParam]),
      requireSuccess: true,
      target: market.id, // market contract
      value: 0,
    });
  }
  calls.push(isEth(underlyingAddress) ? ethTransfer : erc20Transfer);
  const encodedCalls = multicallContract.interface.encodeFunctionData('batchCalls', [calls]);

  const overrides = { value: 0 };
  let estimatedGasLimit = null;
  try {
    estimatedGasLimit = await proxyContract.estimateGas['execute(address,bytes)'](
      multicallContract.address,
      encodedCalls,
      overrides,
    );
  } catch (e) {
    trackEvent('Gas Estimate Errored', trackingProps);
    console.log('Error estimating gas', e);
  }

  let hash;

  try {
    response = await proxyContract['execute(address,bytes)'](multicallContract.address, encodedCalls, {
      ...overrides,
      ...determineGasLimit(estimatedGasLimit?.toNumber(), chainId, REDEEM_GAS_LIMIT),
    });

    hash = response.hash;

    trackEvent('Redeem Transaction Submitted', { transactionHash: hash, ...trackingProps });

    cleanUpStateAndNotify({
      amount: redeemAmount,
      cleanUpFunction: setRedeemAmount,
      cozyEvent: CozyEvents.Redeem,
      hash,
      marketContract,
      provider: signer.provider,
      setIsSubmitting,
      transactionContext,
      type: EthereumTransactionTypes.Redeem,
      market,
    });
  } catch (error) {
    catchError(error, setIsSubmitting, { transactionHash: hash, ...trackingProps });
  }
};

export const depositAmountIsValid = (
  depositAmount: string,
  walletBalance: BNE | null,
  market: Market,
): AmountValidityCheck => {
  if (walletBalance == null) return { amountIsValid: true };
  let errorMessage = null;

  const depositAmountBN = new BigNumber(depositAmount).times(Math.pow(10, market.underlyingDecimals));
  const walletBalanceBN = new BigNumber(walletBalance.toString());

  const enoughFunds = depositAmountBN.lte(walletBalanceBN);

  const isTriggered = market.trigger?.triggered;

  if (isTriggered) {
    errorMessage = 'Protection Event Triggered';
  } else if (!enoughFunds) {
    errorMessage = 'Insufficient Funds';
  }

  const amountIsValid = enoughFunds && !isTriggered;

  return { amountIsValid, errorMessage };
};

export const redeemAmountIsValid = (
  redeemAmount: string,
  positionBalance: number,
  isInLiquidation: boolean,
  market: Market,
): AmountValidityCheck => {
  if (positionBalance == null) return { amountIsValid: false, errorMessage: 'Withdraw' };
  let errorMessage = null;

  const hasEnoughCash = marketHasEnoughCash(redeemAmount, market);

  const sufficientBalance = parseFloat(redeemAmount) <= positionBalance + DUST_BUFFER;
  if (!sufficientBalance) {
    errorMessage = 'Insufficient Balance';
  } else if (isInLiquidation) {
    errorMessage = 'Will Cause Liquidation';
  } else if (!hasEnoughCash) {
    errorMessage = 'Market Supply Insufficient';
  }

  const amountIsValid = sufficientBalance && !isInLiquidation && hasEnoughCash;

  return { amountIsValid, errorMessage };
};

export const checkMaxRedeemableAmount = (
  account: Account,
  market: Market,
  supplyBalanceUnderlying: number,
): { canRedeemMax: boolean; maxRedeemAmountUnderlying: number } => {
  const inverseCollateralFactor = 1 / parseFloat(market.collateralFactor);
  const maxRedeemAmountUsd =
    (account.totalCollateralValueInUsd - account.totalBorrowValueInUsd) * inverseCollateralFactor;

  const maxRedeemAmountUnderlying = maxRedeemAmountUsd / market.underlyingPriceUSD;
  const canRedeemMax = supplyBalanceUnderlying <= maxRedeemAmountUnderlying;

  return { canRedeemMax, maxRedeemAmountUnderlying };
};
