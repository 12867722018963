import { Box, Flex, useTheme } from '@chakra-ui/react';
import { Market, Position } from '@/types';

import Divider from '@/components/shared/Divider';
import React from 'react';
import Text from '@/components/shared/Text';
import { calculatePositionStats } from '@/helpers/positionHelpers';

interface Props {
  market: Market;
  position: Position;
}

const BorrowBalanceTab: React.FC<Props> = ({ market, position }: Props) => {
  const theme = useTheme();

  const {
    formattedUSDLifetimeInterest,
    formattedBorrowRate,
    formattedBorrowTokenBalance,
    formattedUSDBorrowBalance,
  } = calculatePositionStats(position, market);

  const color = theme.utilityColors.errorColor;

  return (
    <>
      <Flex textAlign="center" justifyContent="center" alignItems="center" mt={12}>
        <Text fontSize="5xl" fontWeight="800">
          {formattedBorrowTokenBalance}
        </Text>
        <Text fontSize="xl" fontWeight="800" mt={5} ml={2}>
          {market.underlyingSymbol}
        </Text>
      </Flex>

      <Text textAlign="center" color="gray.500">
        Equal to {formattedUSDBorrowBalance}
      </Text>

      <Box mx={4} mt={12} mb={6}>
        <Flex justifyContent="space-between" alignItems="center">
          <Text fontWeight="600" fontSize="lg">
            {market.underlyingSymbol} Interest
          </Text>
          <Text fontWeight="600" fontSize="lg" color={color}>
            {formattedUSDLifetimeInterest}
          </Text>
        </Flex>

        <Divider my={4} />

        <Flex justifyContent="space-between">
          <Text fontWeight="600" fontSize="lg">
            Current Interest APY
          </Text>
          <Text fontWeight="600" fontSize="lg" color={color}>
            {formattedBorrowRate} APY
          </Text>
        </Flex>
      </Box>
    </>
  );
};
export default BorrowBalanceTab;
