import { Alert, Box, Flex } from '@chakra-ui/react';

import Link from '@/components/shared/Link';
import { MIGRATION_DOCS_URL } from '@/constants';
import React from 'react';
import Text from '@/components/shared/Text';

interface Props {
  message: string;
}

const MigrationAlert: React.FC<Props> = ({ message }: Props) => {
  return (
    <Box mx="6" mb="4">
      <Alert status="warning" fontSize="sm" fontWeight="600" borderRadius="lg">
        <Flex flexWrap="wrap">
          <Text>
            {message}
            <Link isExternal={true} fontSize="sm" fontWeight="600" href={MIGRATION_DOCS_URL} ml="1" mt="1px">
              Learn More
            </Link>
          </Text>
        </Flex>
      </Alert>
    </Box>
  );
};
export default MigrationAlert;
