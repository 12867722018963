import Toast, { ToastStatuses } from '../shared/Toast';

import React from 'react';

interface Props {
  transactionDescription: string;
}

const FailedToast: React.FC<Props> = ({ transactionDescription }: Props) => {
  return <Toast status={ToastStatuses.error}>Sorry, your {transactionDescription} transaction failed.</Toast>;
};
export default FailedToast;
