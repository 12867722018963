import { Market, Position } from '@/types';

import { BorrowModalTabs } from '@/components/modals/BorrowModal';
import { SupplyModalTabs } from '@/components/modals/SupplyModal';

export const MAX_BORROW_PERCENTAGE = 0.98;

export const chooseBorrowingPowerBarColor = (isInLiquidation: boolean, isNearLiquidation: boolean): string => {
  if (isInLiquidation) {
    return 'red';
  } else if (isNearLiquidation) {
    return 'orange';
  } else {
    return 'purple';
  }
};

export const caclulateMaxBorrowPercent = (
  borrowLimitUsdTotal: number,
  usdBorrowed: number,
  borrowLimitUsdLeft: number,
): number => {
  return ((MAX_BORROW_PERCENTAGE * borrowLimitUsdTotal - usdBorrowed) / borrowLimitUsdLeft) * 100;
};

export const calculateAdditionalCollateral = (
  tab: SupplyModalTabs,
  depositAmount: string,
  withdrawAmount: string,
  market: Market,
): number => {
  const depositAmountFloat = tab === SupplyModalTabs.Deposit ? parseFloat(depositAmount) : 0;
  const withdrawAmountFloat = tab === SupplyModalTabs.Withdraw ? parseFloat(withdrawAmount) : 0;
  const amount = depositAmountFloat - withdrawAmountFloat;

  return amount * market.underlyingPriceUSD * parseFloat(market.collateralFactor);
};

export const calculateAdditionalBorrow = (
  tab: BorrowModalTabs,
  borrowAmount: string,
  repayAmount: string,
  market: Market,
  position: Position,
): number => {
  const maxRepayAmount = position == null ? 0 : position.borrowBalanceUnderlying * market.underlyingPriceUSD;

  const borrowAmountFloat = tab === BorrowModalTabs.Borrow ? parseFloat(borrowAmount) : 0;
  const repayAmountFloat = tab === BorrowModalTabs.Repay ? parseFloat(repayAmount) : 0;

  const amount = borrowAmountFloat - Math.min(repayAmountFloat, maxRepayAmount);

  return amount * market.underlyingPriceUSD;
};
