import { Box, Flex, useTheme } from '@chakra-ui/react';
import { Market, Position } from '@/types';

import Divider from '@/components/shared/Divider';
import React from 'react';
import Text from '@/components/shared/Text';
import { calculatePositionStats } from '@/helpers/positionHelpers';

interface Props {
  market: Market;
  position: Position;
}

const SupplyBalanceTab: React.FC<Props> = ({ market, position }: Props) => {
  const theme = useTheme();

  const {
    formattedUSDLifetimeEarnings,
    formattedSupplyRate,
    formattedSupplyTokenBalance,
    formattedUSDSupplyBalance,
  } = calculatePositionStats(position, market);

  const color = theme.utilityColors.successColor;

  return (
    <>
      <Flex direction="column" alignItems="center">
        <Flex justifyContent="center" alignItems="center" mt={16}>
          <Text fontSize="5xl" fontWeight="800">
            {formattedSupplyTokenBalance}
          </Text>
          <Text fontSize="xl" fontWeight="800" mt={5} ml={2}>
            {market.underlyingSymbol}
          </Text>
        </Flex>
        <Text color="gray.500">Equal to {formattedUSDSupplyBalance}</Text>
      </Flex>
      <Box mx={4} mt={12} mb={6}>
        <Flex justifyContent="space-between" alignItems="center">
          <Text fontWeight="600" fontSize="lg">
            {market.underlyingSymbol} Earned
          </Text>
          <Text fontWeight="600" fontSize="lg" color={color}>
            {formattedUSDLifetimeEarnings}
          </Text>
        </Flex>

        <Divider my={4} />

        <Flex justifyContent="space-between">
          <Text fontWeight="600" fontSize="lg">
            Currently Earning
          </Text>
          <Text fontWeight="600" fontSize="lg" color={color}>
            {formattedSupplyRate} APY
          </Text>
        </Flex>
      </Box>
    </>
  );
};
export default SupplyBalanceTab;
