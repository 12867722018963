/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createBreakpoints } from '@chakra-ui/theme-tools';
import { extendTheme } from '@chakra-ui/react';

const ACCENT_COLOR = '#5057FA';
const WARNING_COLOR = '#F1A060';
const WARNING_COLOR_SECONDARY = '#FFF3D6';
const ERROR_COLOR = '#F62B6F';
const TEXT_COLOR = '#222B45';

const NumberInput = {
  defaultProps: {
    focusBorderColor: ACCENT_COLOR,
    errorBorderColor: 'gray.100',
  },
};

const Button = {
  variants: {
    outline: {
      _hover: {
        bg: 'gray.50',
      },
    },
    ghost: {
      _hover: {
        bg: 'gray.50',
      },
    },
    link: {
      _hover: {
        bg: 'gray.50',
      },
    },
  },
};

const theme = extendTheme({
  components: {
    // @ts-ignore
    Button,
    // @ts-ignore
    NumberInput,
  },
  maxPageWidth: '74em', // Max width 70em plus 2em padding on each side
  maxContentWidth: '70em',
  styles: {
    global: {
      html: {
        fontFamily: 'Sans-Serif',
      },
      body: {
        color: TEXT_COLOR,
      },
    },
  },
  utilityColors: {
    accentColor: ACCENT_COLOR,
    accentSecondaryColor: '#E9EAFE',
    brandColor: '#E077FF',
    errorColor: ERROR_COLOR,
    errorSecondaryColor: '#FFEAEA',
    warningColor: WARNING_COLOR,
    warningSecondaryColor: '#FFF3D6',
    successColor: '#3EC43B',
    successSecondaryColor: '#E4fBE4',
  },
  fonts: {
    body: 'Poppins',
    heading: 'Italian Plate No1 Expanded',
    mono: 'Poppins',
  },
  fontSizes: {
    xs: '10px',
    sm: '12px',
    md: '14px',
    lg: '16px',
    xl: '18px',
    '2xl': '22px',
    '3xl': '28px',
    '4xl': '36px',
    '5xl': '56px',
    '6xl': '64px',
  },
  fontWeights: {
    normal: 400,
    medium: 500,
    bold: 600,
  },
  // @ts-ignore
  breakpoints: createBreakpoints({
    sm: '23.5em', // just above 320px
    md: '48em', // 768px
    lg: '59em', // 944px
    xl: '75em', // 1200px
  }),
  shadows: {
    highlight: '0px 0px 35px rgba(0, 0, 0, 0.04)',
    sm: '0px 2px 5px rgba(34, 43, 69, 0.06), 0px 0px 0px rgba(0, 0, 0, 0.25)',
    md: '0px 3px 7px rgba(34, 43, 69, 0.06), 0px 5px 35px rgba(34, 43, 69, 0.03)',
    lg: '0px 3px 8px rgba(34, 43, 69, 0.01), 0px 8px 35px rgba(34, 43, 69, 0.05)',
    outline: `0 0 0 2px ${ACCENT_COLOR}`,
  },
  colors: {
    blue: {
      100: '#EFF2FF',
    },
    gray: {
      50: '#FAFBFC',
      100: '#EDEFF2',
      200: '#DBE0E6',
      300: '#B8C2CC',
      500: '#708599',
      700: TEXT_COLOR,
    },
    green: {
      100: '#DBFBF3',
    },
    purple: {
      500: ACCENT_COLOR, // Button
      600: '#3C40B2', // Hover
      700: '#000A96', // Pressed
    },
    red: {
      100: '#FFECEF',
      200: '#FFDDDB',
      500: ERROR_COLOR,
    },
    orange: {
      100: WARNING_COLOR_SECONDARY,
      500: WARNING_COLOR,
    },
  },
});

export default theme;
