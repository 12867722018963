import { Flex, Icon, SlideFade } from '@chakra-ui/react';

import { HiArrowNarrowRight } from 'react-icons/hi';
import Link from '@/components/shared/Link';
import React from 'react';
import Text from '@/components/shared/Text';
import { formatUsdAmount } from '@/helpers/formatHelpers';
import { useRouter } from 'next/router';

interface Props {
  borrowLimitUsdTotal: number;
  projBorrowLimitUsdTotal: number;
}

export const isOnFundsPage = (pathname: string): boolean => {
  return pathname === '/funds';
};

const BorrowPowerDisplay: React.FC<Props> = ({ borrowLimitUsdTotal, projBorrowLimitUsdTotal }: Props) => {
  const router = useRouter();
  const borrowPowerLimitHasProjection = borrowLimitUsdTotal != projBorrowLimitUsdTotal;

  const { pathname } = router;

  return (
    <Flex fontWeight="600" fontSize="lg" justifyContent="space-between">
      <Flex alignItems="center">
        <Text transition="color 0.1s ease-in-out" color={borrowPowerLimitHasProjection ? 'gray.500' : null}>
          {formatUsdAmount(borrowLimitUsdTotal, '$0,0')} {!borrowPowerLimitHasProjection && ' Borrowing Power'}
        </Text>

        <SlideFade in={borrowPowerLimitHasProjection} offsetX={-10} offsetY={0}>
          <Flex alignItems="center">
            <Icon mx={2} as={HiArrowNarrowRight} boxSize="16px" color="gray.500" />
            {borrowPowerLimitHasProjection && (
              <Text>{formatUsdAmount(projBorrowLimitUsdTotal, '$0,0')} New Borrowing Power</Text>
            )}
          </Flex>
        </SlideFade>
      </Flex>

      {borrowLimitUsdTotal > 0 && !isOnFundsPage(pathname) && (
        <Link fontSize="sm" onClick={() => router.push('/funds')}>
          Increase
        </Link>
      )}
    </Flex>
  );
};
export default BorrowPowerDisplay;
