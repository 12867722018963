import { useRouter } from 'next/router';

const useCheckIfQueryParamsHydrated = (): boolean => {
  const router = useRouter();

  const hasQueryParams = router.asPath.includes('?');
  const queryParamsHydrated = Object.keys(router.query).length > 0;

  if (hasQueryParams) {
    return queryParamsHydrated;
  } else {
    return true;
  }
};

export default useCheckIfQueryParamsHydrated;
