import { Box, Flex } from '@chakra-ui/react';
import { Market, PositionTypes } from '@/types';

import Card from '../shared/Card';
import { PLATFORMS } from '@/constants';
import PlatformLogo from '../shared/PlatformLogo';
import React from 'react';
import Text from '@/components/shared/Text';
import TriggerCardLink from './TriggerCardLink';
import TriggerCardRates from './TriggerCardRates';
import TriggerCreatorLogo from '../shared/TriggerCreatorLogo';
import { calculatePositionStats } from '@/helpers/positionHelpers';
import { etherscanAddressUrl } from '@/helpers/activityItemHelpers';
import { findDefaultPlatformByTrigger } from '@/helpers/findHelpers';
import { useWeb3React } from '@web3-react/core';

interface Props {
  positionType: PositionTypes;
  market: Market;
}

const TriggerCard: React.FC<Props> = ({ positionType, market }: Props) => {
  const { chainId } = useWeb3React();
  const { trigger } = market;
  const platform = findDefaultPlatformByTrigger(PLATFORMS, trigger);
  const { creator } = trigger;

  const {
    formattedBorrowRate,
    formattedInvestRate,
    formattedNetRate,
    formattedSupplyRate,
    netRate,
  } = calculatePositionStats(null, market);

  return (
    <Card boxShadow="md">
      <Flex borderTopRadius="lg" backgroundColor="gray.700" justifyContent="center">
        <Text color="white" fontSize="xs" fontWeight="700" my={3}>
          {positionType === PositionTypes.Supply ? 'Supplying Protection For' : 'Included Protection'}
        </Text>
      </Flex>

      <Box p={4}>
        <Flex alignItems="center">
          <PlatformLogo platform={platform} boxSize={5} />

          <Text fontSize="md" fontWeight="600" ml={2}>
            {platform?.name} Protection
          </Text>
        </Flex>
        <Flex justifyContent="space-between" alignItems="flex-start" mt={4}>
          <Text fontSize="sm" color="gray.500" fontWeight="600" mr={4}>
            {trigger?.name} Details
          </Text>
          <TriggerCardLink href={etherscanAddressUrl(trigger.address, chainId, '#code')}>More</TriggerCardLink>
        </Flex>
        <Text fontSize="sm" mt={2} mb={6}>
          {trigger?.description}
        </Text>

        <Flex justifyContent="space-between" alignItems="flex-start">
          <TriggerCardRates
            formattedBorrowRate={formattedBorrowRate}
            formattedInvestRate={formattedInvestRate}
            formattedNetRate={formattedNetRate}
            formattedSupplyRate={formattedSupplyRate}
            netRate={netRate}
            positionType={positionType}
          />

          <TriggerCardLink href={etherscanAddressUrl(market.interestRateModelAddress, chainId, '#code')}>
            More
          </TriggerCardLink>
        </Flex>
      </Box>

      {creator && (
        <Flex
          justifyContent="space-between"
          alignItems="center"
          backgroundColor="gray.50"
          borderBottomRadius="lg"
          px={4}
          minH="2.5em"
        >
          <Flex alignItems="center" py={3}>
            <TriggerCreatorLogo boxSize={3} triggerCreator={creator} />
            <Text fontSize="xs" color="gray.500" ml={2}>
              Created by {creator?.name}
            </Text>
          </Flex>
        </Flex>
      )}
    </Card>
  );
};
export default TriggerCard;
