import { Account, InvestmentOpportunity, Market, MarketTypes } from '@/types';

import { MIN_POSITION_BALANCE } from './positionHelpers';
import investmentPlatforms from '@/investmentPlatforms';

interface BalanceSheetItem {
  balance: number;
  interestRate: number;
}

export const MIN_INTEREST_RATE_FOR_DISPLAY = 0.0000001;

export const calculateAccountStats = (
  account: Account,
  marketType: MarketTypes,
): {
  currentBorrowInterestRate: number;
  netInvestInterestRate: number;
  currentSupplyInterestRate: number;
  totalBorrowBalance: number;
  totalBorrowInterestAccrued: number;
  totalInvestBalance: number;
  totalInvestInterestAccrued: number;
  totalSupplyBalance: number;
  totalSupplyInterestAccrued: number;
} => {
  const totalSupplyBalance = calculateTotalSupplyBalance(account, marketType);
  const totalSupplyInterestAccrued = calculateTotalSupplyInterestAccrued(account, marketType);
  const currentSupplyInterestRate = calculateSupplyInterestRate(account, marketType);

  const totalBorrowBalance = calculateTotalBorrowBalance(account, marketType);
  const totalBorrowInterestAccrued = calculateTotalBorrowInterestAccrued(account, marketType);
  const currentBorrowInterestRate = calculateBorrowInterestRate(account, marketType);

  const { investBalance: totalInvestBalance } = calculateTotalInvestBalance(account);
  const totalInvestInterestAccrued = calculateTotalInvestInterestAccrued(account);
  const netInvestInterestRate = calculateNetInvestInterestRate(account);

  return {
    currentBorrowInterestRate,
    netInvestInterestRate,
    currentSupplyInterestRate,
    totalBorrowBalance,
    totalBorrowInterestAccrued,
    totalInvestBalance,
    totalInvestInterestAccrued,
    totalSupplyBalance,
    totalSupplyInterestAccrued,
  };
};

export const calculateTotalCozyBalance = (account: Account): number => {
  const { investBalance } = calculateTotalInvestBalance(account);
  const totalSupplyBalance = calculateTotalSupplyBalance(account);
  const totalBorrowBalance = calculateTotalBorrowBalance(account);
  return investBalance + totalSupplyBalance - totalBorrowBalance;
};

export const calculateTotalCozyAssets = (account: Account): number => {
  const { investBalance } = calculateTotalInvestBalance(account);
  const totalSupplyBalance = calculateTotalSupplyBalance(account);
  return investBalance + totalSupplyBalance;
};

export const calculateTotalBalance = (account: Account, balanceType: string, marketType?: MarketTypes): number => {
  if (noPositionFound(account)) {
    return 0;
  }

  return account.positions.reduce((acc, position) => {
    if (marketTypeDoesNotMatch(position.market, marketType)) {
      return acc;
    }

    return acc + position[balanceType] * position.market.underlyingPriceUSD;
  }, 0);
};

export const calculateTotalInvestBalance = (
  account: Account,
): { investBalance: number; investBorrowBalance: number } => {
  if (noPositionFound(account)) {
    return { investBalance: 0, investBorrowBalance: 0 };
  }

  return account.positions.reduce(
    (acc, position) => {
      const {
        borrowBalanceUnderlying,
        investBalanceUnderlying,
        market: { underlyingPriceUSD },
      } = position;

      const investBalance = investBalanceUnderlying * underlyingPriceUSD;
      if (investBalance != null && investBalance > 0) {
        const investBorrowBalance = borrowBalanceUnderlying * underlyingPriceUSD;

        return {
          investBalance: acc.investBalance + investBalance,
          investBorrowBalance: acc.investBorrowBalance + investBorrowBalance,
        };
      } else {
        return acc;
      }
    },
    { investBalance: 0, investBorrowBalance: 0 },
  );
};

export const calculateTotalSupplyBalance = (account: Account, marketType?: MarketTypes): number => {
  return calculateTotalBalance(account, 'supplyBalanceUnderlying', marketType);
};

export const calculateTotalBorrowBalance = (account: Account, marketType?: MarketTypes): number => {
  return calculateTotalBalance(account, 'borrowBalanceUnderlying', marketType);
};

export const calculateTotalInterestAccrued = (
  account: Account,
  interestType: string,
  marketType?: MarketTypes,
): number => {
  if (noPositionFound(account)) {
    return 0;
  }

  const earnings = account.positions.reduce((acc, currToken) => {
    if (marketTypeDoesNotMatch(currToken.market, marketType)) {
      return acc;
    }

    return acc + currToken[interestType] * currToken.market.underlyingPriceUSD;
  }, 0);

  if (isBelowDisplayThreshold(earnings)) {
    return 0;
  } else {
    return earnings;
  }
};

export const calculateTotalSupplyInterestAccrued = (account: Account, marketType?: MarketTypes): number => {
  return calculateTotalInterestAccrued(account, 'lifetimeSupplyInterestAccrued', marketType);
};

export const calculateTotalBorrowInterestAccrued = (account: Account, marketType?: MarketTypes): number => {
  return calculateTotalInterestAccrued(account, 'lifetimeBorrowInterestAccrued', marketType);
};

export const calculateSupplyInterestRate = (account: Account, marketType?: MarketTypes): number => {
  const totalBalance = calculateTotalSupplyBalance(account, marketType);

  return calculateCurrentInterestRate(account, totalBalance, 'supply', marketType);
};

export const calculateBorrowInterestRate = (account: Account, marketType?: MarketTypes): number => {
  const totalBalance = calculateTotalBorrowBalance(account, marketType);

  return calculateCurrentInterestRate(account, totalBalance, 'borrow', marketType);
};

export const calculateInvestInterestRate = (
  account: Account,
  totalInvestBalance: number,
  totalInvestBorrowBalance: number,
): { investInterestRate: number; investBorrowInterestRate: number } => {
  if (noPositionFound(account)) {
    return { investInterestRate: 0, investBorrowInterestRate: 0 };
  }

  const rates = account.positions.reduce(
    (acc, position) => {
      const {
        borrowBalanceUnderlying,
        investBalanceUnderlying,
        market: { borrowRate, investRate, underlyingPriceUSD },
      } = position;

      const investBalance = investBalanceUnderlying * underlyingPriceUSD;
      if (investBalance != null && investBalance > 0) {
        const positionInvestBorrowBalance = borrowBalanceUnderlying * underlyingPriceUSD;
        const investBorrowBalanceWeight =
          totalInvestBorrowBalance > 0 ? positionInvestBorrowBalance / totalInvestBorrowBalance : 0;

        const positionInvestBalance = investBalanceUnderlying * underlyingPriceUSD;
        const investBalanceWeight = totalInvestBalance > 0 ? positionInvestBalance / totalInvestBalance : 0;

        return {
          investInterestRate: acc.investInterestRate + investRate * investBalanceWeight,
          investBorrowInterestRate: acc.investBorrowInterestRate + borrowRate * investBorrowBalanceWeight,
        };
      } else {
        return acc;
      }
    },
    { investInterestRate: 0, investBorrowInterestRate: 0 },
  );

  return {
    investBorrowInterestRate: isBelowDisplayThreshold(rates.investBorrowInterestRate)
      ? 0
      : rates.investBorrowInterestRate,
    investInterestRate: isBelowDisplayThreshold(rates.investInterestRate) ? 0 : rates.investInterestRate,
  };
};

export const calculateCurrentInterestRate = (
  account: Account,
  totalBalance: number,
  type: string,
  marketType?: MarketTypes,
): number => {
  if (noPositionFound(account)) {
    return 0;
  }

  const rate = account.positions.reduce((acc, currToken) => {
    const { market } = currToken;
    const balanceForToken = currToken[`${type}BalanceUnderlying`] * market.underlyingPriceUSD;
    const weightOfToken = balanceForToken / totalBalance;

    if (marketTypeDoesNotMatch(currToken.market, marketType)) {
      return acc;
    }

    return acc + weightOfToken * market[`${type}Rate`];
  }, 0);

  if (isBelowDisplayThreshold(rate)) {
    return 0;
  }

  return rate;
};

export const calculateTotalInvestInterestAccrued = (account: Account): number => {
  if (noPositionFound(account)) {
    return 0;
  }

  return account.positions.reduce((acc, position) => {
    const {
      borrowBalanceUnderlying,
      investBalanceUnderlying,
      market: { underlyingPriceUSD },
    } = position;

    if (investBalanceUnderlying != null && investBalanceUnderlying > 0) {
      const balanceDiff = investBalanceUnderlying - borrowBalanceUnderlying;

      return acc + balanceDiff * underlyingPriceUSD;
    } else {
      return acc;
    }
  }, 0);
};

export const calculateNetInterestRate = (account: Account): number => {
  const supplyInterestRate = calculateSupplyInterestRate(account);
  const totalSupplyBalance = calculateTotalSupplyBalance(account);

  const borrowInterestRate = calculateBorrowInterestRate(account);
  const totalBorrowBalance = calculateTotalBorrowBalance(account);

  const { investBalance } = calculateTotalInvestBalance(account);
  const { investInterestRate } = calculateInvestInterestRate(account, investBalance, 0);

  const totalAssetsValue = totalSupplyBalance + investBalance;

  return weightInterestRates(
    [
      { interestRate: supplyInterestRate, balance: totalSupplyBalance },
      { interestRate: investInterestRate, balance: investBalance },
    ],
    [{ interestRate: borrowInterestRate, balance: totalBorrowBalance }],
    totalAssetsValue,
  );
};

export const calculateNetInvestInterestRate = (account: Account): number => {
  const { investBalance, investBorrowBalance } = calculateTotalInvestBalance(account);
  const { investInterestRate, investBorrowInterestRate } = calculateInvestInterestRate(
    account,
    investBalance,
    investBorrowBalance,
  );

  const investBalanceIsDust = investBalance <= MIN_POSITION_BALANCE;
  const borrowBalanceIsDust = investBorrowBalance <= MIN_POSITION_BALANCE;

  if (investBalanceIsDust && borrowBalanceIsDust) {
    return 0;
  }

  return weightInterestRates(
    [{ interestRate: investInterestRate, balance: investBalance }],
    [{ interestRate: investBorrowInterestRate, balance: investBorrowBalance }],
    // If invest balance is dust but borrow balance isn't, use borrow balance as the denominator for calculating net APY
    investBalanceIsDust ? investBorrowBalance : investBalance,
  );
};

const weightInterestRates = (
  assets: BalanceSheetItem[],
  liabilities: BalanceSheetItem[],
  balanceDenominator: number,
): number => {
  const positiveInterest = assets.reduce(reduceBalanceSheetItems, 0);
  const negativeInterest = liabilities.reduce(reduceBalanceSheetItems, 0);

  const netInterest = positiveInterest - negativeInterest;
  const netInterestRate = netInterest / balanceDenominator;

  if (Math.abs(netInterestRate) < MIN_INTEREST_RATE_FOR_DISPLAY) {
    return 0;
  }

  return netInterestRate;
};

const noPositionFound = (account: Account) => {
  return account == null || account.positions == null;
};

const isBelowDisplayThreshold = (rate) => {
  return Math.abs(rate) < MIN_INTEREST_RATE_FOR_DISPLAY;
};

const reduceBalanceSheetItems = (acc, asset) => {
  return acc + asset.interestRate * asset.balance;
};

const marketTypeDoesNotMatch = (market: Market, marketType: MarketTypes) => {
  const lookingForProtectionMarketButUnprotected =
    marketType === MarketTypes.ProtectionMarket && market.triggerAddress == null;

  const lookingForNonProtectedMarketButProtected =
    marketType === MarketTypes.NonProtectionMarket && market.triggerAddress != null;

  const investmentOpportunityIds = investmentPlatforms.flatMap((platform) => {
    const opportunities: InvestmentOpportunity[] = platform.investmentOpportunities;
    return opportunities.map((opty) => opty.cozyMarketAddress);
  });

  const lookingForInvestMarketButNoOpportunity =
    marketType === MarketTypes.InvestmentMarket && !investmentOpportunityIds.includes(market.id);

  return (
    lookingForProtectionMarketButUnprotected ||
    lookingForNonProtectedMarketButProtected ||
    lookingForInvestMarketButNoOpportunity
  );
};
