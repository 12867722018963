import { Account, Comptroller, Market, Position } from '@/types';
import { ButtonGroup, Flex, NumberInput, NumberInputField, useDisclosure } from '@chakra-ui/react';
import React, { useState } from 'react';
import { inputDisplayAmount, onAmountChange, setPercentageAmount } from '@/helpers/manageModalHelpers';
import { useBalance, useErc20Balance } from '@/hooks/web3React';

import BalanceText from '@/components/wallet/BalanceText';
import Button from '@/components/shared/Button';
import DepositTabButton from './DepositTabButton';
import MigrationAlert from '../MigrationAlert';
import PreDepositStepsModal from '../PreDepositSteps/PreDepositStepsModal';
import Text from '@/components/shared/Text';
import { formatUsdAmount } from '@/helpers/formatHelpers';
import { isEth } from '@/helpers/assetHelpers';
import useCheckApprovals from '@/hooks/useCheckApprovals';
import { useWeb3React } from '@web3-react/core';

interface Props {
  account: Account;
  depositAmount: string;
  comptroller: Comptroller;
  market: Market;
  markets: Market[];
  onModalClose: () => void;
  position: Position;
  proxyAddress: string | null;
  setDepositAmount: React.Dispatch<React.SetStateAction<string>>;
}

const DepositTab: React.FC<Props> = ({
  account,
  depositAmount,
  comptroller,
  market,
  markets,
  onModalClose,
  position,
  proxyAddress,
  setDepositAmount,
}: Props) => {
  const { underlyingAddress } = market;
  const { account: walletAddress, chainId } = useWeb3React();
  const [proxyDeployTransactionSubmitted, setProxyDeployTransactionSubmitted] = useState(false);
  const [approveTransactionIsSubmitting, setApproveTransactionIsSubmitting] = useState(false);

  const { cozyIsApproved, proxyIsApproved } = useCheckApprovals({
    chainId,
    marketContractAddress: market.id,
    proxyAddress,
    walletAddress,
    underlyingAddress,
  });

  const {
    isOpen: preDepositStepsModalIsOpen,
    onClose: preDepositStepsModalOnClose,
    onOpen: preDepositStepsModalOnOpen,
  } = useDisclosure();

  const ethBalance = useBalance();
  const erc20Balance = useErc20Balance(underlyingAddress);

  const displayDepositAmount = inputDisplayAmount(depositAmount);

  const isEthMarket = isEth(underlyingAddress);
  const relevantBalance = isEthMarket ? ethBalance : erc20Balance;

  const usdDepositValue = parseFloat(depositAmount) * market.underlyingPriceUSD;
  const balanceNotReady = relevantBalance == null;

  const depositButton = (
    buttonText?: string,
    marginBottom?: string,
    minWidth?: string,
    showTextOnLoading?: boolean,
  ) => (
    <DepositTabButton
      buttonText={buttonText}
      comptroller={comptroller}
      cozyIsApproved={cozyIsApproved}
      depositAmount={depositAmount}
      marginBottom={marginBottom}
      market={market}
      markets={markets}
      minWidth={minWidth}
      onModalClose={onModalClose}
      position={position}
      preDepositStepsModalOnOpen={preDepositStepsModalOnOpen}
      proxyAddress={account?.proxyAddress}
      proxyIsApproved={proxyIsApproved}
      relevantBalance={relevantBalance}
      setDepositAmount={setDepositAmount}
      showTextOnLoading={showTextOnLoading}
    />
  );

  return (
    <Flex direction="column" alignItems="center">
      <PreDepositStepsModal
        approveTransactionIsSubmitting={approveTransactionIsSubmitting}
        depositButton={depositButton('Start', null, null, false)}
        isApproved={proxyIsApproved}
        isEthMarket={isEthMarket}
        isOpen={preDepositStepsModalIsOpen}
        market={market}
        onClose={preDepositStepsModalOnClose}
        proxyAddress={account?.proxyAddress}
        proxyDeployTransactionSubmitted={proxyDeployTransactionSubmitted}
        setApproveTransactionIsSubmitting={setApproveTransactionIsSubmitting}
        setProxyDeployTransactionSubmitted={setProxyDeployTransactionSubmitted}
      />

      <Flex direction="column" alignItems="center" my="2em">
        <Flex>
          <NumberInput
            color={parseFloat(depositAmount) == 0 ? 'gray.500' : null}
            onChange={onAmountChange(setDepositAmount)}
            value={displayDepositAmount}
            variant="unstyled"
          >
            <NumberInputField paddingInlineEnd="0" fontSize="5xl" fontWeight="800" textAlign="center" placeholder="0" />
          </NumberInput>
        </Flex>
        <Text fontSize="lg" color="gray.500">
          Equal to {formatUsdAmount(usdDepositValue, '$0,0.00')}
        </Text>
      </Flex>

      {walletAddress && (
        <Flex alignItems="center" mt="1.5em" mb="2em">
          <BalanceText
            balanceLabel={market.underlyingSymbol}
            fontSize="md"
            fontWeight="600"
            tokenAddress={market.underlyingAddress}
            tokenDecimals={market.underlyingDecimals}
            tokenSymbol={market.underlyingSymbol}
          />
          <Text fontSize="md" ml={1}>
            Available in wallet
          </Text>
        </Flex>
      )}
      <ButtonGroup isAttached variant="outline" isDisabled={balanceNotReady} mb="2em">
        <Button
          onClick={() =>
            setPercentageAmount(relevantBalance.toString(), 25, market.underlyingDecimals, setDepositAmount)
          }
          mr="-px"
        >
          25%
        </Button>
        <Button
          onClick={() =>
            setPercentageAmount(relevantBalance.toString(), 50, market.underlyingDecimals, setDepositAmount)
          }
          mr="-px"
        >
          50%
        </Button>
        <Button
          onClick={() =>
            setPercentageAmount(relevantBalance.toString(), 75, market.underlyingDecimals, setDepositAmount)
          }
          mr="-px"
        >
          75%
        </Button>
        <Button
          onClick={() =>
            setPercentageAmount(relevantBalance.toString(), 100, market.underlyingDecimals, setDepositAmount)
          }
        >
          100%
        </Button>
      </ButtonGroup>

      {depositButton()}
      <MigrationAlert message="You are on a deprecated version of Cozy. Deposits are no longer allowed on this version." />
    </Flex>
  );
};
export default DepositTab;
