import { Account, ButtonType, Comptroller, Market, TransactionStatuses } from '@/types';
import { ButtonGroup, Flex, NumberInput, NumberInputField } from '@chakra-ui/react';
import React, { useContext, useState } from 'react';
import { formatNumber, formatUsdAmount } from '@/helpers/formatHelpers';
import { inputDisplayAmount, onAmountChange, setPercentageAmount } from '@/helpers/manageModalHelpers';
import { trackEvent, triggerAnalyticsProps } from '@/utils/analytics';

import BorrowCapAlert from '../BorrowTab/BorrowCapAlert';
import Button from '@/components/shared/Button';
import MigrationAlert from '../MigrationAlert';
import Text from '@/components/shared/Text';
import { TransactionContext } from '@/contexts/TransactionContext';
import { borrowAmountIsValid } from '@/helpers/borrowTransactionHelpers';
import { caclulateMaxBorrowPercent } from '@/helpers/borrowingPowerHelpers';
import { calculateBorrowLimitStats } from '@/helpers/borrowLimitHelpers';
import { submitInvestTransaction } from '@/helpers/investTransactionHelpers';
import { useWeb3React } from '@web3-react/core';

interface Props {
  account: Account;
  investAmount: string;
  comptroller: Comptroller;
  market: Market;
  onModalClose: () => void;
  setInvestAmount: React.Dispatch<React.SetStateAction<string>>;
  usdAdditionalBorrow: number;
}

const InvestTab: React.FC<Props> = ({
  account,
  investAmount,
  comptroller,
  market,
  onModalClose,
  setInvestAmount,
  usdAdditionalBorrow,
}: Props) => {
  const { account: walletAddress, chainId, library } = useWeb3React();
  const signer = library?.getSigner(walletAddress);

  const transactionContext = useContext(TransactionContext);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const proxyAddress = account?.proxyAddress;

  const { borrowLimitUsdTotal, isInLiquidation, usdBorrowed, borrowLimitUsdLeft } = calculateBorrowLimitStats(
    account,
    0,
    usdAdditionalBorrow,
  );
  const borrowLimitLeft = borrowLimitUsdLeft / market.underlyingPriceUSD;
  const usdBorrowValue = parseFloat(investAmount) * market.underlyingPriceUSD;
  const formattedBorrowLimitLeft = formatNumber(borrowLimitLeft, '0,0.0000');
  const maxBorrowPercent = caclulateMaxBorrowPercent(borrowLimitUsdTotal, usdBorrowed, borrowLimitUsdLeft);

  const onInvest = () => {
    trackEvent('Invest Clicked', {
      amount: investAmount,
      marketId: market.id,
      asset: market.underlyingSymbol,
      ...triggerAnalyticsProps(market.trigger),
    });
    setIsSubmitting(true);
    submitInvestTransaction({
      chainId,
      market,
      multicallAddress: comptroller.multicallAddress,
      proxyAddress,
      setInvestAmount,
      setIsSubmitting: ({ status }) => {
        setIsSubmitting(false);

        if (status === TransactionStatuses.Sent) {
          onModalClose();
        }
      },
      signer,
      transactionContext,
      walletAddress: walletAddress,
      investAmount,
    });
  };

  const { amountIsValid, errorMessage } = borrowAmountIsValid(investAmount, market, isInLiquidation);

  const displayBorrowAmount = inputDisplayAmount(investAmount);

  return (
    <Flex direction="column" alignItems="center">
      <Flex direction="column" alignItems="center" my="2em">
        <Flex>
          <NumberInput
            onChange={onAmountChange(setInvestAmount)}
            value={displayBorrowAmount}
            variant="unstyled"
            color={parseFloat(investAmount) == 0 ? 'gray.500' : null}
          >
            <NumberInputField paddingInlineEnd="0" fontSize="5xl" fontWeight="800" textAlign="center" placeholder="0" />
          </NumberInput>
        </Flex>
        <Text fontSize="lg" color="gray.500">
          Equal to {formatUsdAmount(usdBorrowValue, '$0,0.00')}
        </Text>
      </Flex>

      <Flex alignItems="center" mt={8} mb="2em">
        <Text fontSize="md" fontWeight="600">
          {formattedBorrowLimitLeft} {market.underlyingSymbol}
        </Text>
        <Text fontSize="md" ml={1}>
          Investing Limit
        </Text>
      </Flex>

      <ButtonGroup isAttached variant="outline" mb="2em">
        <Button onClick={() => setPercentageAmount(borrowLimitLeft.toString(), 25, 0, setInvestAmount)} mr="-px">
          25%
        </Button>
        <Button onClick={() => setPercentageAmount(borrowLimitLeft.toString(), 50, 0, setInvestAmount)} mr="-px">
          50%
        </Button>
        <Button onClick={() => setPercentageAmount(borrowLimitLeft.toString(), 75, 0, setInvestAmount)} mr="-px">
          75%
        </Button>
        <Button onClick={() => setPercentageAmount(borrowLimitLeft.toString(), maxBorrowPercent, 0, setInvestAmount)}>
          100%
        </Button>
      </ButtonGroup>

      <Button
        appButtonType={ButtonType.Primary}
        isDisabled={true}
        isLoading={isSubmitting}
        loadingText={`Borrow and Invest ${market.underlyingSymbol}`}
        mb="2em"
        minW="280px"
        onClick={onInvest}
      >
        {amountIsValid ? `Borrow and Invest ${market.underlyingSymbol}` : errorMessage}
      </Button>

      <MigrationAlert message="You are on a deprecated version of Cozy. Investments are no longer allowed on this version." />

      <BorrowCapAlert borrowAmount={investAmount} setBorrowAmount={setInvestAmount} market={market} />
    </Flex>
  );
};
export default InvestTab;
