import { NextRouter, useRouter } from 'next/router';

export const setHowItWorksModalOpen = (router: NextRouter, isOpen: boolean): void => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { howItWorksModalIsOpen, ...remainingQuery } = router.query;
  const newState = isOpen ? { ...remainingQuery, howItWorksModalIsOpen: true } : remainingQuery;

  router.push({ query: newState }, undefined, { shallow: true });
};

export const useModalRouterParams = (): { howItWorksModalIsOpen: boolean } => {
  const router = useRouter();

  const { howItWorksModalIsOpen } = router.query;

  return {
    howItWorksModalIsOpen: howItWorksModalIsOpen == 'true',
  };
};
