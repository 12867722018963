import { Box, BoxProps, useTheme } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

import Text from '../shared/Text';
import dayjs from 'dayjs';

interface Props extends BoxProps {
  children: ReactNode;
  status: ToastStatuses;
}

export enum ToastStatuses {
  error,
  info,
  success,
}

const selectBgColor = (status, theme) => {
  const { utilityColors } = theme;

  switch (status) {
    case ToastStatuses.error: {
      return utilityColors.errorSecondaryColor;
    }
    case ToastStatuses.info: {
      return 'white';
    }
    case ToastStatuses.success: {
      return utilityColors.successSecondaryColor;
    }
  }
};

const Toast: React.FC<Props> = ({ children, status }: Props) => {
  const now = dayjs();
  const theme = useTheme();
  const bgColor = selectBgColor(status, theme);
  const border = status === ToastStatuses.info ? '1px solid #EEEFFF' : null;

  return (
    <Box width="350px" p={4} borderRadius="md" bg={bgColor} boxShadow="sm" border={border}>
      <Text fontSize="md" fontWeight="600">
        {children}
      </Text>
      <Text fontSize="sm" fontWeight="500" mt={1}>
        {now.format('h:mm A')}
      </Text>
    </Box>
  );
};
export default Toast;
