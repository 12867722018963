import { Flex, Stack } from '@chakra-ui/react';
import React, { useContext } from 'react';

import ApproveButton from '@/components/modals/manage_modal/ApproveButton';
import Heading from '@/components/shared/Heading';
import { Market } from '@/types';
import PreDepositStep from '../PreDepositSteps/PreDepositStep';
import { ReactNode } from 'react';
import { TransactionContext } from '@/contexts/TransactionContext';
import { checkApprovalStatuses } from '@/helpers/approveTransactionHelpers';
import { useWeb3React } from '@web3-react/core';

interface Props {
  approveTransactionIsSubmitting: boolean;
  isApproved: boolean;
  market: Market;
  proxyAddress: string;
  repayButton: ReactNode;
  setApproveTransactionIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
}

const PreRepaySteps: React.FC<Props> = ({
  approveTransactionIsSubmitting,
  isApproved,
  market,
  proxyAddress,
  repayButton,
  setApproveTransactionIsSubmitting,
}: Props) => {
  const transactionContext = useContext(TransactionContext);
  const { transactions } = transactionContext;
  const { account: walletAddress } = useWeb3React();

  const { hasPendingApproveTransferTransactions, hasSuccessfulApproveTransferTransactions } = checkApprovalStatuses(
    transactions,
    walletAddress,
    market,
  );

  const approveButton = (
    <ApproveButton market={market} proxyAddress={proxyAddress} setIsSubmitting={setApproveTransactionIsSubmitting} />
  );

  return (
    <Flex direction="column" my="2em" mx={2}>
      <Heading fontSize="2xl" mb="2rem">
        To pay back the funds
      </Heading>
      <Stack direction="column" spacing={8}>
        <PreDepositStep
          body="Approve proxy to transfer tokens."
          button={approveButton}
          completeTitle="Transfers Approved"
          isComplete={isApproved}
          isNext={!isApproved}
          isPending={
            approveTransactionIsSubmitting ||
            hasPendingApproveTransferTransactions ||
            hasSuccessfulApproveTransferTransactions
          }
          title="Approve Transfer"
        />
        <PreDepositStep
          body={`Pay back the borrowed funds.`}
          button={repayButton}
          completeTitle="Pay back initiated"
          isNext={isApproved}
          title={`Pay back ${market.underlyingSymbol}`}
        />
      </Stack>
    </Flex>
  );
};
export default PreRepaySteps;
