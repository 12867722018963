import { BoxProps, Flex } from '@chakra-ui/react';
import { ButtonType, Market } from '@/types';
import React, { useContext } from 'react';

import Button from '@/components/shared/Button';
import { TransactionContext } from '@/contexts/TransactionContext';
import erc20abi from '@/abis/erc20Abi';
import { ethers } from 'ethers';
import { submitApproveTransaction } from '@/helpers/approveTransactionHelpers';
import { trackEvent } from '@/utils/analytics';
import { useWeb3React } from '@web3-react/core';

interface Props extends BoxProps {
  market: Market;
  proxyAddress: string;
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
}

const HALF_SECOND = 500;

const ApproveButton: React.FC<Props> = ({ market, proxyAddress, setIsSubmitting, ...props }: Props) => {
  const transactionContext = useContext(TransactionContext);
  const { account, library } = useWeb3React();
  const signer = library?.getSigner(account);

  const tokenContract = new ethers.Contract(market.underlyingAddress, erc20abi, signer);

  const onClick = () => {
    trackEvent('Approve Proxy Clicked', { marketId: market.id, asset: market.underlyingSymbol });
    setIsSubmitting(true);
    submitApproveTransaction({
      market,
      proxyAddress,
      setIsSubmitting: () => {
        // Timeout to avoid button popping from pending to active back to pending
        setTimeout(() => setIsSubmitting(false), HALF_SECOND);
      },
      tokenContract,
      transactionContext,
    });
  };

  const disabled = account == null;

  return (
    <Flex {...props}>
      <Button disabled={disabled} onClick={onClick} fontSize="md" size="md" flex={1} appButtonType={ButtonType.Primary}>
        Start
      </Button>
    </Flex>
  );
};
export default ApproveButton;
