import { Flex } from '@chakra-ui/react';
import { Market } from '@/types';
import React from 'react';
import Text from '@/components/shared/Text';
import TokenLogo from '@/components/shared/TokenLogo';

interface Props {
  market: Market;
}

const ManageModalHeader: React.FC<Props> = ({ market }: Props) => {
  return (
    <Flex alignItems="center" justifyContent="center" mt={4} mb={4}>
      <TokenLogo boxSize={6} name={market.underlyingName} symbol={market.underlyingSymbol} />
      <Text fontSize="lg" fontWeight="600" ml={1}>
        {market.underlyingSymbol}
      </Text>
    </Flex>
  );
};
export default ManageModalHeader;
