import { Flex, Icon, useTheme } from '@chakra-ui/react';
import { PositionTypes, Trigger } from '@/types';

import { GoAlert } from 'react-icons/go';
import React from 'react';
import { RiShieldCheckFill } from 'react-icons/ri';
import Text from '@/components/shared/Text';
import { formatDate } from '@/helpers/formatHelpers';

interface Props {
  maxW?: string;
  positionType: PositionTypes;
  size?: 'sm' | 'lg';
  trigger: Trigger;
}

const TriggeredHighlightCell: React.FC<Props> = ({ maxW, positionType, size = 'lg', trigger }: Props) => {
  const theme = useTheme();
  const isSupplySide = positionType === PositionTypes.Supply;
  const primaryColor = isSupplySide ? theme.utilityColors.warningColor : theme.utilityColors.successColor;
  const secondaryColor = isSupplySide
    ? theme.utilityColors.warningSecondaryColor
    : theme.utilityColors.successSecondaryColor;
  const IconComp = isSupplySide ? GoAlert : RiShieldCheckFill;
  const message = isSupplySide ? 'Event Triggered' : 'Protection Triggered';
  const date =
    size === 'lg'
      ? formatDate(trigger.triggeredAt, 'MMMM D, YYYY h:mma')
      : formatDate(trigger.triggeredAt, 'MMM D, YYYY');

  return (
    <Flex
      maxW={maxW}
      alignItems="center"
      backgroundColor={secondaryColor}
      borderRadius={size === 'lg' ? 'md' : 'full'}
      py={3}
      px={3}
    >
      <Icon color={primaryColor} as={IconComp} boxSize="14px" mt="1px" />
      <Flex direction={size === 'lg' ? 'column' : 'row'} ml={3}>
        <Text fontSize="sm" fontWeight="600" mb={size === 'lg' ? 1 : 0}>
          {message}
        </Text>
        <Text fontSize="sm" ml={size === 'sm' ? 2 : 0}>
          {date}
        </Text>
      </Flex>
    </Flex>
  );
};
export default TriggeredHighlightCell;
