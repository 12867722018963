import { Account, Comptroller, Market, PositionTypes } from '@/types';
import { Box, Flex, Modal, ModalContent, ModalOverlay, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import React, { useState } from 'react';

import ActivityTab from './manage_modal/ActivityTab/ActivityTab';
import BorrowLimitModalPanel from './manage_modal/BorrowingPowerModalPanel/BorrowingPowerModalPanel';
import BorrowLimitModalPanelNoFundsState from './manage_modal/BorrowTab/BorrowTabNoFundsState';
import ConnectWalletGateModal from './ConnectWalletGateModal';
import EarningsTab from './manage_modal/EarningsTab/EarningsTab';
import InvestTab from './manage_modal/InvestTab/InvestTab';
import ManageModalHeader from './manage_modal/ManageModalHeader';
import ManageModalTabLists from './manage_modal/ManageModalTabList';
import TriggerPanel from './manage_modal/TriggerPanel';
import WithdrawSummary from './manage_modal/WithdrawTab/WithdrawSummary';
import WithdrawTab from './manage_modal/WithdrawTab/WithdrawTab';
import { calculateBorrowLimitStats } from '@/helpers/borrowLimitHelpers';
import { findPositionByMarketId } from '@/helpers/findHelpers';
import { useGetModalSize } from '@/helpers/manageModalHelpers';
import useManageRouterParams from '@/hooks/useManageRouterParams';
import { useWeb3React } from '@web3-react/core';

interface Props {
  account: Account;
  comptroller: Comptroller;
  markets: Market[];
  onClose: () => void;
}

export enum InvestModalTabs {
  Earnings,
  Invest,
  Withdraw,
  Activity,
}

const TAB_NAMES = {
  [InvestModalTabs.Earnings]: 'Earnings',
  [InvestModalTabs.Invest]: 'Invest',
  [InvestModalTabs.Withdraw]: 'Withdraw',
  [InvestModalTabs.Activity]: 'Activity',
};

const TABS = [InvestModalTabs.Earnings, InvestModalTabs.Invest, InvestModalTabs.Withdraw, InvestModalTabs.Activity];

const SHOW_BORROW_LIMIT_TABS = [InvestModalTabs.Invest, InvestModalTabs.Withdraw];

const InvestModal: React.FC<Props> = ({ account, comptroller, markets, onClose }: Props) => {
  const { tab, market, setTab } = useManageRouterParams(markets);
  const { account: walletAccount } = useWeb3React();
  const [investAmount, setInvestAmount] = useState('0');
  const hasTrigger = market?.triggerAddress != null;

  const { borrowLimitUsdTotal: investLimitUsdTotal } = calculateBorrowLimitStats(account, 0, 0);

  const size = useGetModalSize(hasTrigger, walletAccount, investLimitUsdTotal);

  const onModalClose = () => {
    setInvestAmount('0');
    onClose();
  };

  if (market == null) return null;

  const position = findPositionByMarketId(account, market.id);

  const withdrawAmount = position?.investBalanceUnderlying;

  const usdAdditionalBorrow = determineUsdAdditionalBorrow(tab, { investAmount, market, withdrawAmount });

  const showBorrowLimit = SHOW_BORROW_LIMIT_TABS.includes(tab);

  return (
    <Modal isOpen={market != null} onClose={onModalClose} size={size}>
      <ModalOverlay />
      <ModalContent backgroundColor="transparent">
        <Box backgroundColor="white" borderRadius="lg" mb={6}>
          <Flex direction={{ base: 'column', md: 'row' }}>
            <Box flex={1}>
              {walletAccount == null && <ConnectWalletGateModal />}
              {walletAccount != null && investLimitUsdTotal === 0 && <BorrowLimitModalPanelNoFundsState />}

              {walletAccount != null && investLimitUsdTotal != 0 && (
                <>
                  <ManageModalHeader market={market} />

                  <Tabs isFitted index={tab} onChange={setTab} borderBottomRadius="2xl">
                    <ManageModalTabLists tabs={TABS.map((tab) => TAB_NAMES[tab])} />
                    <TabPanels>
                      <TabPanel>
                        <EarningsTab market={market} position={position} />
                      </TabPanel>
                      <TabPanel>
                        <InvestTab
                          account={account}
                          investAmount={investAmount}
                          comptroller={comptroller}
                          onModalClose={onModalClose}
                          market={market}
                          setInvestAmount={setInvestAmount}
                          usdAdditionalBorrow={usdAdditionalBorrow}
                        />
                      </TabPanel>
                      <TabPanel>
                        <WithdrawTab
                          account={account}
                          comptroller={comptroller}
                          market={market}
                          onModalClose={onModalClose}
                          position={position}
                          withdrawAmount={withdrawAmount}
                        />
                      </TabPanel>
                      <TabPanel p={0}>
                        <ActivityTab account={account} market={market} positionType={PositionTypes.Borrow} />
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </>
              )}
            </Box>

            {investLimitUsdTotal > 0 &&
              market.triggerAddress &&
              (tab === InvestModalTabs.Withdraw ? (
                <WithdrawSummary market={market} position={position} />
              ) : (
                <TriggerPanel market={market} positionType={PositionTypes.Invest} />
              ))}
          </Flex>
        </Box>

        {showBorrowLimit && <BorrowLimitModalPanel account={account} usdAdditionalBorrow={usdAdditionalBorrow} />}
      </ModalContent>
    </Modal>
  );
};

const determineUsdAdditionalBorrow = (tab, { investAmount, market, withdrawAmount }) => {
  if (tab === InvestModalTabs.Invest) {
    return parseFloat(investAmount) * market.underlyingPriceUSD;
  } else if (tab === InvestModalTabs.Withdraw) {
    return -withdrawAmount * market.underlyingPriceUSD;
  } else {
    return 0;
  }
};

export default InvestModal;
