import {
  DELEGATECALL_ADDRESS,
  catchError,
  cleanUpStateAndNotify,
  determineGasLimit,
  initializeContracts,
  prepareContractParam,
} from './transactionHelpers';
import { EthereumCall, EthereumTransactionTypes } from '@/types';
import { trackEvent, triggerAnalyticsProps } from '@/utils/analytics';

import { BaseTransactionParams } from './approveTransactionHelpers';
import { MaxUint256 } from '@ethersproject/constants';
import { findInvestInfoForMarket } from './findHelpers';
import { isEth } from './assetHelpers';

interface InvestParams extends BaseTransactionParams {
  investAmount: string;
  proxyAddress: string;
  setInvestAmount: React.Dispatch<React.SetStateAction<string>>;
}

interface WithdrawParams extends BaseTransactionParams {
  cozyIsApproved: boolean;
  maximillionAddress: string;
  proxyAddress: string;
  withdrawAmount: number;
}

const INVEST_GAS_LIMIT = 800000;
const WITHDRAW_GAS_LIMIT = 450000;

export const submitInvestTransaction = async ({
  chainId,
  investAmount,
  market,
  multicallAddress,
  proxyAddress,
  setInvestAmount,
  setIsSubmitting,
  signer,
  transactionContext,
}: InvestParams): Promise<void> => {
  const trackingProps = {
    type: EthereumTransactionTypes.Invest,
    amount: investAmount,
    marketId: market.id,
    asset: market.underlyingSymbol,
    ...triggerAnalyticsProps(market.trigger),
  };

  const { underlyingAddress } = market;
  const { investmentOpportunity, investmentPlatform } = findInvestInfoForMarket(market);
  const isEthMarket = isEth(underlyingAddress);

  const investParam = prepareContractParam(investAmount, market.underlyingDecimals);
  const { investmentContractAddress } = investmentOpportunity;

  const { marketContract, multicallContract, proxyContract, tokenContract } = initializeContracts({
    marketAddress: market.id,
    multicallAddress,
    proxyAddress,
    signer,
    underlyingAddress,
  });

  const calls: EthereumCall[] = [
    // Call borrow on Cozy market contract
    {
      data: marketContract.interface.encodeFunctionData('borrow', [investParam]),
      requireSuccess: true,
      target: market.id,
      value: 0,
    },
  ];

  // TODO: DETERMINE IF THE PLATFORM IS APPOVED OR NOT
  if (!isEthMarket) {
    // && !investPlatformApproved) {
    calls.push({
      data: tokenContract.interface.encodeFunctionData('approve', [investmentContractAddress, MaxUint256]),
      requireSuccess: true,
      target: underlyingAddress,
      value: 0,
    });
  }

  // Call deposit on target platform
  calls.push(
    isEthMarket
      ? investmentPlatform.ethDepositCall(investmentOpportunity, investParam, signer)
      : investmentPlatform.erc20DepositCall(investmentOpportunity, investParam, signer, proxyAddress),
  );

  const encodedCalls = multicallContract.interface.encodeFunctionData('batchCalls', [calls]);

  const overrides = { value: 0 };
  let estimatedGasLimit = null;
  try {
    estimatedGasLimit = await proxyContract.estimateGas['execute(address,bytes)'](
      multicallContract.address,
      encodedCalls,
      overrides,
    );
  } catch (e) {
    trackEvent('Gas Estimate Errored', trackingProps);
    console.log('Error estimating gas', e);
  }

  let hash;

  try {
    const response = await proxyContract['execute(address,bytes)'](multicallContract.address, encodedCalls, {
      ...overrides,
      ...determineGasLimit(estimatedGasLimit?.toNumber(), chainId, INVEST_GAS_LIMIT),
    });

    hash = response.hash;

    trackEvent('Invest Transaction Submitted', { transactionHash: hash, ...trackingProps });

    cleanUpStateAndNotify({
      amount: investAmount,
      cleanUpFunction: setInvestAmount,
      hash,
      setIsSubmitting,
      transactionContext,
      type: EthereumTransactionTypes.Invest,
      market,
    });
  } catch (error) {
    catchError(error, setIsSubmitting, { transactionHash: hash, ...trackingProps });
  }
};

export const submitWithdrawTransaction = async ({
  chainId,
  cozyIsApproved,
  market,
  maximillionAddress,
  multicallAddress,
  proxyAddress,
  setIsSubmitting,
  signer,
  transactionContext,
  withdrawAmount,
  walletAddress,
}: WithdrawParams): Promise<void> => {
  const trackingProps = {
    type: EthereumTransactionTypes.Withdraw,
    amount: withdrawAmount,
    marketId: market.id,
    asset: market.underlyingSymbol,
    ...triggerAnalyticsProps(market.trigger),
  };

  const { underlyingAddress } = market;
  const { investmentOpportunity, investmentPlatform } = findInvestInfoForMarket(market);
  const isEthMarket = isEth(underlyingAddress);

  const { multicallContract, proxyContract, tokenContract } = initializeContracts({
    marketAddress: market.id,
    multicallAddress,
    proxyAddress,
    signer,
    underlyingAddress,
  });

  const withdrawAmountParam = prepareContractParam(withdrawAmount, market.underlyingDecimals);

  const calls: EthereumCall[] = [];

  // Call withdraw all on target platform
  calls.push(investmentPlatform.withdrawCall(investmentOpportunity, withdrawAmountParam, signer));

  // Pay back debt on Cozy market
  if (isEthMarket) {
    calls.push({
      data: multicallContract.interface.encodeFunctionData('repayBorrowCozyEther', [market.id, maximillionAddress]),
      requireSuccess: true,
      target: DELEGATECALL_ADDRESS,
      value: 0,
    });
  } else {
    if (cozyIsApproved !== true) {
      // Conditionally approve Cozy market to transfer erc20 on behalf of proxy wallet
      calls.push({
        data: tokenContract.interface.encodeFunctionData('approve', [market.id, MaxUint256]),
        requireSuccess: true,
        target: underlyingAddress,
        value: 0,
      });
    }

    calls.push({
      data: multicallContract.interface.encodeFunctionData('repayBorrowCozyToken', [market.id]),
      requireSuccess: true,
      target: DELEGATECALL_ADDRESS,
      value: 0,
    });
  }

  // Transfer all remaining underlying to personal wallet
  calls.push({
    data: multicallContract.interface.encodeFunctionData('transferAll', [underlyingAddress, walletAddress]),
    requireSuccess: true,
    target: DELEGATECALL_ADDRESS,
    value: 0,
  });

  // TODO: CLAIM AND TRANSFER REWARD TOKENS

  const encodedCalls = multicallContract.interface.encodeFunctionData('batchCalls', [calls]);

  const overrides = { value: 0 };
  const estimatedGasLimit = await proxyContract.estimateGas['execute(address,bytes)'](
    multicallContract.address,
    encodedCalls,
    overrides,
  );

  let hash;

  try {
    const response = await proxyContract['execute(address,bytes)'](multicallContract.address, encodedCalls, {
      ...overrides,
      ...determineGasLimit(estimatedGasLimit?.toNumber(), chainId, WITHDRAW_GAS_LIMIT),
    });

    hash = response.hash;

    trackEvent('Withdraw Investment Transaction Submitted', { transactionHash: hash, ...trackingProps });

    cleanUpStateAndNotify({
      amount: withdrawAmount.toString(),
      cleanUpFunction: () => null, // TODO: CLEAN UP PROPERLY
      hash,
      setIsSubmitting,
      transactionContext,
      type: EthereumTransactionTypes.Withdraw,
      market,
    });
  } catch (error) {
    catchError(error, setIsSubmitting, { transactionHash: hash, ...trackingProps });
  }
};
