import { CozyEvents, EthereumCall, EthereumTransactionTypes, Market } from '@/types';
import {
  DELEGATECALL_ADDRESS,
  catchError,
  cleanUpStateAndNotify,
  determineGasLimit,
  initializeContracts,
  marketHasEnoughCash,
  prepareContractParam,
} from './transactionHelpers';
import { trackEvent, triggerAnalyticsProps } from '@/utils/analytics';

import { AmountValidityCheck } from './supplyTransactionHelpers';
import { BigNumber as BNE } from 'ethers';
import { BaseTransactionParams } from './approveTransactionHelpers';
import BigNumber from 'bignumber.js';
import { MaxUint256 } from '@ethersproject/constants';
import React from 'react';
import { isEth } from './assetHelpers';

interface BorrowParams extends BaseTransactionParams {
  borrowAmount: string;
  proxyAddress: string;
  setBorrowAmount: React.Dispatch<React.SetStateAction<string>>;
}

interface RepayParams extends BaseTransactionParams {
  borrowBalanceUnderlying: number;
  cozyIsApproved: boolean;
  isRepayAll: boolean;
  maximillionAddress: string;
  proxyAddress: string;
  repayAmount: string;
  setRepayAmount: React.Dispatch<React.SetStateAction<string>>;
}

const BORROW_GAS_LIMIT = 700000;
const REPAY_GAS_LIMIT = 450000;

export const submitBorrowTransaction = async ({
  borrowAmount,
  chainId,
  market,
  multicallAddress,
  proxyAddress,
  setBorrowAmount,
  setIsSubmitting,
  signer,
  transactionContext,
  walletAddress: walletAddress,
}: BorrowParams): Promise<void> => {
  const trackingProps = {
    type: EthereumTransactionTypes.Borrow,
    amount: borrowAmount,
    marketId: market.id,
    asset: market.underlyingSymbol,
    ...triggerAnalyticsProps(market.trigger),
  };

  const { underlyingAddress } = market;

  const borrowParam = prepareContractParam(borrowAmount, market.underlyingDecimals);

  const { marketContract, multicallContract, proxyContract } = initializeContracts({
    marketAddress: market.id,
    multicallAddress,
    proxyAddress,
    signer,
    underlyingAddress,
  });

  const calls: EthereumCall[] = [
    // Call borrow on Cozy market contract
    {
      data: marketContract.interface.encodeFunctionData('borrow', [borrowParam]),
      requireSuccess: true,
      target: market.id,
      value: 0,
    },
    // Transfer all borrowed funds to personal wallet
    {
      target: DELEGATECALL_ADDRESS,
      data: multicallContract.interface.encodeFunctionData('transferAll', [underlyingAddress, walletAddress]),
      value: 0,
      requireSuccess: true,
    },
  ];

  const encodedCalls = multicallContract.interface.encodeFunctionData('batchCalls', [calls]);

  const overrides = { value: 0 };
  let estimatedGasLimit = null;
  try {
    estimatedGasLimit = await proxyContract.estimateGas['execute(address,bytes)'](
      multicallContract.address,
      encodedCalls,
      overrides,
    );
  } catch (e) {
    trackEvent('Gas Estimate Errored', trackingProps);
    console.log('Error estimating gas', e);
  }

  let hash;

  try {
    const response = await proxyContract['execute(address,bytes)'](multicallContract.address, encodedCalls, {
      ...overrides,
      ...determineGasLimit(estimatedGasLimit?.toNumber(), chainId, BORROW_GAS_LIMIT),
    });

    hash = response.hash;

    trackEvent('Borrow Transaction Submitted', { transactionHash: hash, ...trackingProps });

    cleanUpStateAndNotify({
      amount: borrowAmount,
      cleanUpFunction: setBorrowAmount,
      cozyEvent: CozyEvents.Borrow,
      hash,
      marketContract,
      provider: signer.provider,
      setIsSubmitting,
      transactionContext,
      type: EthereumTransactionTypes.Borrow,
      market,
    });
  } catch (error) {
    catchError(error, setIsSubmitting, { transactionHash: hash, ...trackingProps });
  }
};

export const submitRepayTransaction = async ({
  borrowBalanceUnderlying,
  chainId,
  cozyIsApproved,
  isRepayAll,
  maximillionAddress,
  market,
  multicallAddress,
  proxyAddress,
  setRepayAmount,
  setIsSubmitting,
  signer,
  transactionContext,
  walletAddress: walletAddress,
  repayAmount,
}: RepayParams): Promise<void> => {
  const trackingProps = {
    type: EthereumTransactionTypes.Repay,
    amount: repayAmount,
    marketId: market.id,
    asset: market.underlyingSymbol,
    ...triggerAnalyticsProps(market.trigger),
  };

  const { underlyingAddress } = market;
  const isEthMarket = isEth(underlyingAddress);
  const repayAmountParam = prepareContractParam(repayAmount, market.underlyingDecimals);

  const { marketContract, multicallContract, proxyContract, tokenContract } = initializeContracts({
    marketAddress: market.id,
    maximillionAddress,
    multicallAddress,
    proxyAddress,
    signer,
    underlyingAddress,
  });

  const borrowBalanceUnderlyingParam = prepareContractParam(borrowBalanceUnderlying, market.underlyingDecimals);

  const repayParam = isRepayAll ? borrowBalanceUnderlyingParam : repayAmountParam;

  const calls: EthereumCall[] = [];

  if (isEthMarket) {
    // Call repay behalf explicit on maximillion contract to allow for elimination of dust
    calls.push({
      data: multicallContract.interface.encodeFunctionData('repayBorrowCozyEther', [market.id, maximillionAddress]),
      requireSuccess: true,
      target: DELEGATECALL_ADDRESS,
      value: 0,
    });
  } else {
    if (cozyIsApproved !== true) {
      // Conditionally approve Cozy market to transfer erc20 on behalf of proxy wallet
      calls.push({
        data: tokenContract.interface.encodeFunctionData('approve', [market.id, MaxUint256]),
        requireSuccess: true,
        target: underlyingAddress,
        value: 0,
      });
    }

    // Transfer from funds personal wallet to proxy wallet
    calls.push({
      data: tokenContract.interface.encodeFunctionData('transferFrom', [walletAddress, proxyAddress, repayParam]),
      requireSuccess: true,
      target: underlyingAddress,
      value: 0,
    });

    // Repay funds from proxy wallet
    calls.push({
      data: multicallContract.interface.encodeFunctionData('repayBorrowCozyToken', [market.id]),
      requireSuccess: true,
      target: DELEGATECALL_ADDRESS,
      value: 0,
    });
  }

  calls.push({
    target: DELEGATECALL_ADDRESS,
    data: multicallContract.interface.encodeFunctionData('transferAll', [underlyingAddress, walletAddress]),
    value: 0,
    requireSuccess: true,
  });

  const encodedCalls = multicallContract.interface.encodeFunctionData('batchCalls', [calls]);

  const overrides = { value: isEthMarket ? repayParam : 0 };
  let estimatedGasLimit = null;
  try {
    estimatedGasLimit = await proxyContract.estimateGas['execute(address,bytes)'](
      multicallContract.address,
      encodedCalls,
      overrides,
    );
  } catch (e) {
    trackEvent('Gas Estimate Errored', trackingProps);
    console.log('Error estimating gas', e);
  }
  let hash;

  try {
    const response = await proxyContract['execute(address,bytes)'](multicallContract.address, encodedCalls, {
      ...overrides,
      ...determineGasLimit(estimatedGasLimit?.toNumber(), chainId, REPAY_GAS_LIMIT),
    });

    hash = response.hash;

    trackEvent('Repay Transaction Submitted', { transactionHash: hash, ...trackingProps });

    cleanUpStateAndNotify({
      amount: repayAmount,
      cleanUpFunction: setRepayAmount,
      cozyEvent: CozyEvents.RepayBorrow,
      hash,
      marketContract,
      provider: signer.provider,
      setIsSubmitting,
      transactionContext,
      type: EthereumTransactionTypes.Repay,
      market,
    });
  } catch (error) {
    catchError(error, setIsSubmitting, { transactionHash: hash, ...trackingProps });
  }
};

export const borrowAmountIsValid = (
  borrowAmount: string,
  market: Market,
  isInLiquidation: boolean,
): AmountValidityCheck => {
  let errorMessage = null;

  const hasEnoughCash = marketHasEnoughCash(borrowAmount, market);
  const sufficientFunds = !isInLiquidation;
  const isTriggered = market.trigger?.triggered;
  const sufficientBorrowCap = marketHasEnoughBorrowCap(borrowAmount, market);

  const amountIsValid = hasEnoughCash && sufficientFunds && !isTriggered && sufficientBorrowCap;

  if (isTriggered) {
    errorMessage = 'Protection Event Triggered';
  } else if (!hasEnoughCash) {
    errorMessage = 'Market Supply Insufficient';
  } else if (!sufficientFunds) {
    errorMessage = 'Insuccifient Borrowing Power';
  } else if (!sufficientBorrowCap) {
    errorMessage = 'Max Funds Borrowed in Market';
  }

  return { amountIsValid, errorMessage };
};

export const repayAmountIsValid = (
  repayAmount: string,
  walletBalance: BNE | null,
  market: Market,
): AmountValidityCheck => {
  if (walletBalance == null) return { amountIsValid: false, errorMessage: 'Pay Back' };
  let errorMessage = null;

  const repayAmountBN = new BigNumber(repayAmount).times(Math.pow(10, market.underlyingDecimals));
  const walletBalanceBN = new BigNumber(walletBalance.toString());

  const enoughFunds = repayAmountBN.lte(walletBalanceBN);

  const isTriggered = market.trigger?.triggered;

  if (isTriggered) {
    errorMessage = 'Protection Event Triggered';
  } else if (!enoughFunds) {
    errorMessage = 'Insufficient Funds';
  }

  const amountIsValid = enoughFunds && !isTriggered;

  return { amountIsValid, errorMessage };
};

const marketHasEnoughBorrowCap = (borrowAmount, market): boolean => {
  const { borrowCap, totalBorrows } = market;
  if (borrowCap == null) {
    return true;
  }

  return borrowCap - totalBorrows - borrowAmount > 0;
};
