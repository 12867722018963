import { Account, ButtonType, Comptroller, Market, Position, TransactionStatuses } from '@/types';
import React, { useContext, useState } from 'react';
import { trackEvent, triggerAnalyticsProps } from '@/utils/analytics';

import Button from '@/components/shared/Button';
// import { Flex, ListItem, OrderedList } from '@chakra-ui/react';
import { Flex } from '@chakra-ui/react';
import Text from '@/components/shared/Text';
import { TransactionContext } from '@/contexts/TransactionContext';
import { submitWithdrawTransaction } from '@/helpers/investTransactionHelpers';
import useCheckApprovals from '@/hooks/useCheckApprovals';
import { useWeb3React } from '@web3-react/core';

interface Props {
  account: Account;
  comptroller: Comptroller;
  market: Market;
  onModalClose: () => void;
  position: Position;
  withdrawAmount: number;
}

const WithdrawTab: React.FC<Props> = ({
  account,
  comptroller,
  market,
  onModalClose,
  position,
  withdrawAmount,
}: Props) => {
  const { account: walletAddress, chainId, library } = useWeb3React();
  const { underlyingAddress } = market;
  const transactionContext = useContext(TransactionContext);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { cozyIsApproved } = useCheckApprovals({
    chainId,
    marketContractAddress: market.id,
    proxyAddress: account?.proxyAddress,
    walletAddress,
    underlyingAddress,
  });

  const { proxyAddress } = account;
  const { maximillionAddress, multicallAddress } = comptroller;

  const signer = library?.getSigner(walletAddress);

  const onWithdraw = () => {
    trackEvent('Withdraw All Clicked', {
      amount: withdrawAmount,
      marketId: market.id,
      asset: market.underlyingSymbol,
      ...triggerAnalyticsProps(market.trigger),
    });
    setIsSubmitting(true);
    submitWithdrawTransaction({
      chainId,
      cozyIsApproved,
      market,
      maximillionAddress,
      multicallAddress,
      proxyAddress,
      signer,
      transactionContext,
      walletAddress,
      withdrawAmount,
      setIsSubmitting: ({ status }) => {
        setIsSubmitting(false);
        if (status == TransactionStatuses.Sent) {
          onModalClose();
        }
      },
    });
  };

  return (
    <Flex direction="column" alignItems="center">
      <Text textAlign="center" fontWeight="600" fontSize="xl" my="2rem">
        Withdraw All
      </Text>

      <Text fontSize="md" mx="2rem" mb={4}>
        Your invested amount will be withdrawn and your max debt paid back automatically.
      </Text>

      {/* TODO: ADD THIS BACK IN ONCE WE'RE ACTUALLY CLAIMING REWARDS
      <OrderedList fontSize="md" textAlign="left" mx="2rem">
        <ListItem></ListItem>
        
        <ListItem mt="1rem">Your rewards will be claimed to your funds.</ListItem>
      </OrderedList> */}

      <Button
        appButtonType={ButtonType.Primary}
        isDisabled={position == null || isSubmitting}
        isLoading={isSubmitting}
        loadingText="Confirm"
        minW="280px"
        my="2em"
        onClick={onWithdraw}
      >
        Confirm
      </Button>
    </Flex>
  );
};
export default WithdrawTab;
