import React, { ReactNode } from 'react';

import { BiLinkExternal } from 'react-icons/bi';
import { Icon } from '@chakra-ui/react';
import Link from '../shared/Link';

interface Props {
  children: ReactNode;
  href: string;
}

const TriggerCardLink: React.FC<Props> = ({ children, href }: Props) => {
  return (
    <Link minWidth="48px" fontSize="sm" lineHeight="15px" isExternal href={href} onClick={(e) => e.stopPropagation()}>
      {children}
      <Icon as={BiLinkExternal} ml={2} mb="1px" boxSize="10px" />
    </Link>
  );
};
export default TriggerCardLink;
