import { NextRouter, useRouter } from 'next/router';

import { Market } from '@/types';

interface ManageModalState {
  market: Market;
  setTab: (tab: number) => void;
  tab: number;
}

export const setMarketBeingManaged = (router: NextRouter, market: Market, tab: number): void => {
  if (market == null) {
    router.push({ query: {} }, undefined, { shallow: true });
    return;
  }

  const newState = { ...router.query, market: market.id, tab };

  router.push({ query: newState }, undefined, { shallow: true });
};

const setTab = (router: NextRouter) => (tab: number): void => {
  const newState = { ...router.query, tab, muteProgressBar: true };
  router.push({ query: newState }, undefined, { shallow: true });
};

const useManageRouterParams = (markets: Market[]): ManageModalState => {
  const router = useRouter();

  const { tab, market: marketId } = router.query;

  const market = markets.find((v) => v.id === marketId);

  return {
    market,
    setTab: setTab(router),
    tab: Number(tab),
  };
};

export default useManageRouterParams;
