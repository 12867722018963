import { BoxProps } from '@chakra-ui/react';
import Logo from './Logo';
import { Platform } from '@/types';
import React from 'react';

export interface PlatformLogoProps extends BoxProps {
  boxSize?: number;
  platform?: Platform;
}

const PlatformLogo: React.FC<PlatformLogoProps> = ({ platform, boxSize = 10, ...props }: PlatformLogoProps) => {
  return (
    <Logo alt={`${platform?.name} logo`} {...props} src={`/images/platforms/${platform?.logo}`} boxSize={boxSize} />
  );
};

export default PlatformLogo;
