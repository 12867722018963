import { Box, Flex } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

import { BiCheck } from 'react-icons/bi';
import Button from '@/components/shared/Button';
import { ButtonType } from '@/types';
import { Icon } from '@chakra-ui/react';
import Spinner from '@/components/shared/Spinner';
import Text from '@/components/shared/Text';

interface Props {
  button?: ReactNode;
  completeTitle?: string;
  body: string;
  isComplete?: boolean;
  isNext: boolean;
  isPending?: boolean;
  title: string;
}

const PreDepositStep: React.FC<Props> = ({
  body,
  button,
  completeTitle,
  isComplete,
  isNext,
  isPending,
  title,
}: Props) => {
  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Box>
        <Text fontSize="xl" fontWeight="600" mb={1} color={isComplete ? 'gray.500' : null}>
          {isComplete ? completeTitle : title}
        </Text>
        <Text fontSize="md" color="gray.500">
          {body}
        </Text>
      </Box>

      {chooseButton({ button, isComplete, isNext, isPending })}
    </Flex>
  );
};

const chooseButton = ({ button, isComplete, isNext, isPending }) => {
  if (isComplete) {
    return (
      <Button minW="67px" isDisabled>
        <Icon as={BiCheck} boxSize={8} />
      </Button>
    );
  } else if (isPending) {
    return (
      <Button minW="67px" appButtonType={ButtonType.Primary} isDisabled isLoading>
        <Spinner speed="0.5s" color="white" size="sm" />
      </Button>
    );
  } else if (isNext && button != null) {
    return button;
  } else {
    return (
      <Button appButtonType={ButtonType.Primary} isDisabled>
        Start
      </Button>
    );
  }
};

export default PreDepositStep;
