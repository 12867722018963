import { Spinner as ChakraSpinner, SpinnerProps } from '@chakra-ui/react';

import React from 'react';

type Props = SpinnerProps;

const Spinner: React.FC<Props> = ({ children, ...props }: Props) => {
  return (
    <ChakraSpinner speed="0.65s" thickness="3px" color="gray.500" {...props}>
      {children}
    </ChakraSpinner>
  );
};
export default Spinner;
