import { Comptroller, Market, Trigger } from '@/types';
import { DEFAULT_CHAIN_ID, SUBGRAPH_URLS } from '@/constants';

import { COZY_MARKETS_QUERY } from './fetchQueries';
import { VerifiedAddress } from '@/helpers/preloadDataHelpers';
import { graphQLFetcher } from '@/utils/fetcher';
import { supplementWithTriggerData } from './useFetchAccountData';
import useSWR from 'swr';
import { useWeb3React } from '@web3-react/core';

export interface SubgraphData {
  comptroller: Comptroller;
  loading: boolean;
  markets: Market[];
  triggers: Trigger[];
}

export const BLANK_DATA = {
  comptroller: null,
  loading: true,
  markets: [],
  triggers: [],
};

const useFetchMarketData = (
  initialData?: SubgraphData,
  verifiedAddresses?: Record<string, VerifiedAddress>,
): SubgraphData => {
  const { chainId: activeChainId } = useWeb3React();
  const activeChainMatchesDefault = activeChainId === DEFAULT_CHAIN_ID || activeChainId == null;

  const chainId = activeChainId ? activeChainId : DEFAULT_CHAIN_ID;

  const API = SUBGRAPH_URLS[chainId];

  const shouldQuery = chainId != null && API != null;

  const options = { initialData: activeChainMatchesDefault ? initialData : null };

  const { data: cozyData, error } = useSWR(
    () => (shouldQuery ? `cozy-${chainId}` : null),
    graphQLFetcher(API, COZY_MARKETS_QUERY),
    options,
  );

  if (error) {
    console.log('Error loading market data:', error);
  }

  if (cozyData) {
    const { comptroller, markets, triggers } = cozyData;

    return {
      comptroller,
      loading: false,
      markets: markets?.map((market) => {
        return {
          ...market,
          trigger: supplementWithTriggerData(market, triggers, verifiedAddresses),
        };
      }),
      triggers,
    };
  } else {
    return { ...BLANK_DATA, loading: true };
  }
};

export default useFetchMarketData;
