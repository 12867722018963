import BigNumber from 'bignumber.js';
import { Market } from '@/types';
import { NextRouter } from 'next/router';
import { setMarketBeingManaged } from '@/hooks/useManageRouterParams';
import { useBreakpointValue } from '@chakra-ui/react';

const MAX_INPUT_DECIMALS = 6;
const MAX_UNDERLYING_DECIMALS = 27;
const MAX_DIGITS = 10;

export const onManageModalOpen = (router: NextRouter) => (market: Market, tab: number) => (): void => {
  setMarketBeingManaged(router, market, tab);
};

export const onManageModalClose = (router: NextRouter) => (): void => {
  setMarketBeingManaged(router, null, null);
};

export const inputDisplayAmount = (value: string): string => {
  const postDecimal = value?.split('.')[1];
  if (postDecimal != null && postDecimal.length < MAX_INPUT_DECIMALS) {
    return value;
  }

  const bal = new BigNumber(value);
  const amount = bal.toFixed(MAX_INPUT_DECIMALS).replace(/\.?0+$/, '');

  return formatNumberInputValue(amount);
};

export const setPercentageAmount = (
  value: string,
  percentage: number,
  decimals: number,
  setter: (string) => void,
): void => {
  if (value) {
    const bal = new BigNumber(value);
    const amount = bal
      .times(percentage)
      .div(100)
      .div(Math.pow(10, decimals))
      .toFixed(MAX_UNDERLYING_DECIMALS)
      .replace(/\.?0+$/, '');

    setter(amount);
  }
};

export const onAmountChange = (setter: (string) => void) => (value: string): void => {
  const newValue = formatNumberInputValue(value);

  if (newValue != null) {
    setter(newValue);
  }
};

export const formatNumberInputValue = (value: string): null | string => {
  // Numbers and one decimal point only
  const regEx = /^\d*\.?\d*$/;

  if (!regEx.test(value)) {
    return;
  }

  if (value.length === 0) {
    return '0';
  } else if (value.length === 0 && value[0] === '.') {
    return '0.';
  } else if (value.length === 2 && value[0] === '0' && value[1] !== '.') {
    return value[1];
  } else if (value.length < MAX_DIGITS) {
    return value;
  } else {
    return value.substr(0, MAX_DIGITS);
  }
};

export const useGetModalSize = (hasTrigger: boolean, walletAccount: string, borrowLimitUsdTotal?: number): string => {
  return useBreakpointValue({
    base: 'xs',
    sm: 'sm',
    md: hasTrigger && walletAccount && borrowLimitUsdTotal !== 0 ? '3xl' : 'lg',
    lg: hasTrigger && walletAccount && borrowLimitUsdTotal !== 0 ? '4xl' : 'lg',
  });
};
