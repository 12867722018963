import { Box, Flex } from '@chakra-ui/react';
import { Market, Position } from '@/types';

import EarningsTabDetails from '../EarningsTab/EarningsTabDetails';
import React from 'react';

interface Props {
  market: Market;
  position: Position;
}

const WithdrawSummary: React.FC<Props> = ({ market, position }: Props) => {
  return (
    <Flex backgroundColor="gray.50" px={10} alignItems="center">
      <Box minW="20em" background="white">
        <EarningsTabDetails market={market} position={position} isWithdrawSummary />
      </Box>
    </Flex>
  );
};
export default WithdrawSummary;
