import { BoxProps, Skeleton as ChakraSkeleton, SkeletonProps, useTheme } from '@chakra-ui/react';

import React from 'react';

const Skeleton: React.FC<SkeletonProps> = ({ children, ...props }: BoxProps) => {
  const theme = useTheme();

  return (
    <ChakraSkeleton {...props} startColor={theme.colors.gray[100]} endColor={theme.colors.gray[200]}>
      {children}
    </ChakraSkeleton>
  );
};
export default Skeleton;
