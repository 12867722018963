import BigNumber from 'bignumber.js';
import { PositionTypes } from '@/types';
import dayjs from 'dayjs';
import numeral from 'numeral';

export const MIN_DISPLAY_AMOUNT = 0.0001;
export const MIN_NUMERAL_FORMATTING_AMOUNT = 0.000001;

export const formatTokenAmount = (
  amount: number | string | BigNumber,
  symbol: string,
  decimals: number,
  fixedPrecision = 27,
  needsConversion = true,
  showSymbol = false,
): string => {
  if (amount == NaN || amount == 'NaN' || amount == '0') return '0';

  const displayAmount = needsConversion
    ? new BigNumber(amount).dividedBy(Math.pow(10, decimals))
    : new BigNumber(amount);

  const fixedAmount = displayAmount.toFixed(fixedPrecision).replace(/\.?0+$/, '');

  const finalFormatted = numeral(fixedAmount).format('0,0.[0000]');

  if (Math.abs(parseFloat(finalFormatted)) < MIN_DISPLAY_AMOUNT) {
    const minDisplayAmount = MIN_DISPLAY_AMOUNT;
    return `<${minDisplayAmount} ${showSymbol ? symbol : ''}`;
  } else {
    return `${finalFormatted} ${showSymbol ? symbol : ''}`;
  }
};

export const convertToPlainDecimal = (amount: string, decimals: number): string => {
  const bn = new BigNumber(amount).dividedBy(Math.pow(10, decimals));
  return bn.toFixed(decimals).replace(/\.?0+$/, '');
};

export const formatLargeDollarAmounts = (amount: number): string => {
  if (amount > Math.pow(10, 9)) {
    return numeral(amount).format('$0,000a');
  } else {
    return numeral(amount).format('$0,0');
  }
};

export const formatDate = (timestamp: number, formatter = 'MMMM D, YYYY'): string => {
  return dayjs(timestamp * 1000).format(formatter);
};

export const formatUsdAmount = (amount: number, formatter = '$0,0'): string => {
  if (amount == 0) {
    return '$0';
  }

  if (Math.abs(amount) < 0.01) {
    return '<$0.01';
  }

  if (Math.abs(amount) < 1000) {
    formatter = '$0,0.00';
  }

  return numeral(amount).format(formatter);
};

export const formatPercentage = (amount: number, formatter = '0.0%'): string => {
  if (Math.abs(amount) < MIN_DISPLAY_AMOUNT) {
    return '0%';
  } else {
    return numeral(amount).format(formatter);
  }
};

export const formatNumber = (amount: number, formatter = '0,0.00'): string => {
  if (Math.abs(amount) < MIN_NUMERAL_FORMATTING_AMOUNT) {
    return '0';
  } else {
    return numeral(amount).format(formatter);
  }
};

export const formatList = (items: string[]): string => {
  if (items.length === 1) {
    return items[0];
  } else if (items.length === 2) {
    return `${items[0]} and ${items[1]}`;
  } else {
    return items.slice(0, items.length - 1).join(', ') + ', and ' + items.slice(-1);
  }
};

export const conditionallyAddParantheses = (amount: string, positionType: PositionTypes): string => {
  return positionType === PositionTypes.Borrow ? `(${amount})` : amount;
};
