import { Box, Collapse, Flex } from '@chakra-ui/react';

import { Account } from '@/types';
import BorrowLimitModalDepositButton from './BorrowingPowerModalDepositButton';
import BorrowLimitWarning from './BorrowingPowerWarning';
import BorrowPowerDisplay from './BorrowingPowerDisplay';
import BorrowPowerPercentageDisplay from './BorrowingPowerPercentageDisplay';
import Card from '@/components/shared/Card';
import React from 'react';
import Text from '@/components/shared/Text';
import { calculateBorrowLimitStats } from '@/helpers/borrowLimitHelpers';

interface Props {
  account?: Account;
  usdAdditionalCollateral?: number;
  usdAdditionalBorrow?: number;
}

const BorrowLimitModalPanel: React.FC<Props> = ({
  account,
  usdAdditionalCollateral = 0,
  usdAdditionalBorrow = 0,
}: Props) => {
  const {
    borrowLimitUsdTotal,
    isInLiquidation,
    isNearLiquidation,
    percentBorrowed,
    projBorrowLimitUsdTotal,
    projPercentBorrowed,
    usdBorrowed,
  } = calculateBorrowLimitStats(account, usdAdditionalCollateral, usdAdditionalBorrow);

  return (
    <Flex dir="column" justifyContent="center" alignItems="center">
      <Card width="100%">
        <Flex alignItems="center" p={6}>
          <Box flex={1} mr={4}>
            <BorrowPowerDisplay
              borrowLimitUsdTotal={borrowLimitUsdTotal}
              projBorrowLimitUsdTotal={projBorrowLimitUsdTotal}
            />

            {borrowLimitUsdTotal === 0 ? (
              <Text color="gray.500" fontSize="sm">
                Increase your borrowing power by depositing funds
              </Text>
            ) : (
              <BorrowPowerPercentageDisplay
                isNearLiquidation={isNearLiquidation}
                isInLiquidation={isInLiquidation}
                percentBorrowed={percentBorrowed}
                projPercentBorrowed={projPercentBorrowed}
                usdBorrowed={usdBorrowed}
              />
            )}
          </Box>

          {borrowLimitUsdTotal === 0 && <BorrowLimitModalDepositButton />}
        </Flex>

        <Collapse in={isInLiquidation}>
          <BorrowLimitWarning status="error" />
        </Collapse>

        <Collapse in={isNearLiquidation && !isInLiquidation}>
          <BorrowLimitWarning status="warning" />
        </Collapse>
      </Card>
    </Flex>
  );
};
export default BorrowLimitModalPanel;
