import { NextRouter, useRouter } from 'next/router';

export enum ActivityFilters {
  All = 'all',
  FundsActivity = 'fundsActivity',
  ProtectionActivity = 'protectionActivity',
  Liquidations = 'liquidations',
}

export const setActivityFilter = (router: NextRouter, filterBy: ActivityFilters): void => {
  const newState = { ...router.query, filterBy: filterBy };

  router.push({ query: newState }, undefined, { shallow: true });
};

export const useActivityFilterRouterParams = (): {
  filterBy: ActivityFilters;
} => {
  const router = useRouter();

  const { filterBy } = router.query;

  const filterEnum = filterBy as ActivityFilters;

  return {
    filterBy: filterEnum,
  };
};
