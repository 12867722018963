import React, { createContext, useState } from 'react';

import { EthereumTransaction } from '@/types';
import { useToast } from '@chakra-ui/react';

export const TransactionContext = createContext({
  blocknative: undefined,
  proxyCompletedCheckTryNumber: undefined,
  proxyDeployTransactionCompleted: undefined,
  setBlocknative: undefined,
  setProxyDeployTransactionCompleted: undefined,
  setProxyCompletedCheckTryNumber: undefined,
  setTransactions: undefined,
  toast: undefined,
  transactions: [],
});

interface Props {
  children: React.ReactNode;
}

export const TransactionContextProvider = ({ children }: Props): JSX.Element => {
  const [blocknative, setBlocknative] = useState<any | undefined>(undefined);
  const [transactions, setTransactions] = useState<EthereumTransaction[]>([]);
  const [proxyDeployTransactionCompleted, setProxyDeployTransactionCompleted] = useState(false);
  const [proxyCompletedCheckTryNumber, setProxyCompletedCheckTryNumber] = useState(0);
  const toast = useToast();

  return (
    <TransactionContext.Provider
      value={{
        blocknative,
        proxyCompletedCheckTryNumber,
        proxyDeployTransactionCompleted,
        setBlocknative,
        setProxyCompletedCheckTryNumber,
        setProxyDeployTransactionCompleted,
        transactions,
        setTransactions,
        toast,
      }}
    >
      {children}
    </TransactionContext.Provider>
  );
};
