import { Account, Market, PositionTypes } from '@/types';

import { Box } from '@chakra-ui/react';
import React from 'react';
import RecentActivity from '@/components/activity/RecentActivity';

interface Props {
  account: Account;
  market: Market;
  positionType: PositionTypes;
}

const ActivityTab: React.FC<Props> = ({ account, market, positionType }: Props) => {
  return (
    <Box minHeight="22em" maxHeight="25em" overflowY="auto" py={2}>
      <RecentActivity account={account} market={market} positionType={positionType} />
    </Box>
  );
};
export default ActivityTab;
