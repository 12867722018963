import Toast, { ToastStatuses } from '../shared/Toast';

import React from 'react';

interface Props {
  transactionDescription: string;
}

const SentToast: React.FC<Props> = ({ transactionDescription }: Props) => {
  return (
    <Toast status={ToastStatuses.info}>Your {transactionDescription} transaction has been sent to the network.</Toast>
  );
};
export default SentToast;
