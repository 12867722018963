import { Alert, Box } from '@chakra-ui/react';

import Link from '@/components/shared/Link';
import { Market } from '@/types';
import React from 'react';
import Text from '@/components/shared/Text';
import { formatUsdAmount } from '@/helpers/formatHelpers';

interface Props {
  borrowAmount: string;
  market: Market;
  setBorrowAmount: React.Dispatch<React.SetStateAction<string>>;
}

const LEARN_MORE_URL = 'https://docs.cozy.finance/cozy/faq#what-is-a-temporary-borrow-limit';

const BorrowCapAlert: React.FC<Props> = ({ borrowAmount, market, setBorrowAmount }: Props) => {
  if (market.borrowCap == null) return null;

  const borrowCapUsd = market.borrowCap * market.underlyingPriceUSD;

  const borrowCapRemaining = Math.max(market.borrowCap - market.totalBorrows, 0);
  const borrowCapRemainingUsd = borrowCapRemaining * market.underlyingPriceUSD;

  if (parseFloat(borrowAmount) < borrowCapRemaining) {
    return null;
  }

  const setToBorrowAmountRemaining = () => {
    setBorrowAmount(borrowCapRemaining.toString());
  };

  const limitReached = (
    <Text>
      This market has reached its {formatUsdAmount(borrowCapUsd)} temporary total borrow limit. There is currently no
      remaining supply left to borrow.{' '}
      <Link isExternal href="#">
        Learn more
      </Link>
      .
    </Text>
  );

  const nearLimit = (
    <Text>
      This market currently has a {formatUsdAmount(borrowCapUsd)} temporary total borrow limit and there is currently
      only <Link onClick={setToBorrowAmountRemaining}>{formatUsdAmount(borrowCapRemainingUsd)}</Link> remaining to
      borrow.{' '}
      <Link isExternal href={LEARN_MORE_URL}>
        Learn more
      </Link>
      .
    </Text>
  );

  return (
    <Box mx="6" mb="4">
      <Alert status="warning" fontSize="sm" fontWeight="600" borderRadius="lg">
        {borrowCapRemaining == 0 ? limitReached : nearLimit}
      </Alert>
    </Box>
  );
};

export default BorrowCapAlert;
