import { Account, ButtonType, Comptroller, Market, TransactionStatuses } from '@/types';
import { ButtonGroup, Flex, NumberInput, NumberInputField } from '@chakra-ui/react';
import React, { useContext, useState } from 'react';
import { borrowAmountIsValid, submitBorrowTransaction } from '@/helpers/borrowTransactionHelpers';
import { formatNumber, formatUsdAmount } from '@/helpers/formatHelpers';
import { inputDisplayAmount, onAmountChange, setPercentageAmount } from '@/helpers/manageModalHelpers';
import { trackEvent, triggerAnalyticsProps } from '@/utils/analytics';

import BorrowCapAlert from './BorrowCapAlert';
import Button from '@/components/shared/Button';
import MigrationAlert from '../MigrationAlert';
import Text from '@/components/shared/Text';
import { TransactionContext } from '@/contexts/TransactionContext';
import { caclulateMaxBorrowPercent } from '@/helpers/borrowingPowerHelpers';
import { calculateBorrowLimitStats } from '@/helpers/borrowLimitHelpers';
import { useWeb3React } from '@web3-react/core';

interface Props {
  account: Account;
  borrowAmount: string;
  comptroller: Comptroller;
  market: Market;
  onModalClose: () => void;
  setBorrowAmount: React.Dispatch<React.SetStateAction<string>>;
  usdAdditionalBorrow: number;
}

const BorrowTab: React.FC<Props> = ({
  account,
  borrowAmount,
  comptroller,
  market,
  onModalClose,
  setBorrowAmount,
  usdAdditionalBorrow,
}: Props) => {
  const { account: walletAddress, chainId, library } = useWeb3React();
  const signer = library?.getSigner(walletAddress);

  const transactionContext = useContext(TransactionContext);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { proxyAddress } = account;

  const { borrowLimitUsdLeft, borrowLimitUsdTotal, isInLiquidation, usdBorrowed } = calculateBorrowLimitStats(
    account,
    0,
    usdAdditionalBorrow,
  );
  const maxBorrowPercent = caclulateMaxBorrowPercent(borrowLimitUsdTotal, usdBorrowed, borrowLimitUsdLeft);

  const borrowLimitLeft = borrowLimitUsdLeft / market.underlyingPriceUSD;
  const usdBorrowValue = parseFloat(borrowAmount) * market.underlyingPriceUSD;
  const formattedBorrowLimitLeft = formatNumber(borrowLimitLeft, '0,0.0000');

  const onBorrow = () => {
    trackEvent('Borrow Clicked', {
      amount: borrowAmount,
      marketId: market.id,
      asset: market.underlyingSymbol,
      ...triggerAnalyticsProps(market.trigger),
    });
    setIsSubmitting(true);
    submitBorrowTransaction({
      chainId,
      market,
      multicallAddress: comptroller.multicallAddress,
      proxyAddress,
      setBorrowAmount,
      setIsSubmitting: ({ status }) => {
        setIsSubmitting(false);

        if (status === TransactionStatuses.Sent) {
          onModalClose();
        }
      },
      signer,
      transactionContext,
      walletAddress: walletAddress,
      borrowAmount,
    });
  };

  const { amountIsValid, errorMessage } = borrowAmountIsValid(borrowAmount, market, isInLiquidation);

  const displayBorrowAmount = inputDisplayAmount(borrowAmount);

  return (
    <Flex direction="column" alignItems="center">
      <Flex direction="column" alignItems="center" my="2em">
        <Flex>
          <NumberInput
            onChange={onAmountChange(setBorrowAmount)}
            value={displayBorrowAmount}
            variant="unstyled"
            color={parseFloat(borrowAmount) == 0 ? 'gray.500' : null}
          >
            <NumberInputField paddingInlineEnd="0" fontSize="5xl" fontWeight="800" textAlign="center" placeholder="0" />
          </NumberInput>
        </Flex>
        <Text fontSize="lg" color="gray.500">
          Equal to {formatUsdAmount(usdBorrowValue, '$0,0.00')}
        </Text>
      </Flex>

      <Flex alignItems="center" mt={8} mb="2em">
        <Text fontSize="md" fontWeight="600">
          {formattedBorrowLimitLeft} {market.underlyingSymbol}
        </Text>
        <Text fontSize="md" ml={1}>
          Borrow Limit
        </Text>
      </Flex>

      <ButtonGroup isAttached variant="outline" mb="2em">
        <Button onClick={() => setPercentageAmount(borrowLimitLeft.toString(), 25, 0, setBorrowAmount)} mr="-px">
          25%
        </Button>
        <Button onClick={() => setPercentageAmount(borrowLimitLeft.toString(), 50, 0, setBorrowAmount)} mr="-px">
          50%
        </Button>
        <Button onClick={() => setPercentageAmount(borrowLimitLeft.toString(), 75, 0, setBorrowAmount)} mr="-px">
          75%
        </Button>
        <Button onClick={() => setPercentageAmount(borrowLimitLeft.toString(), maxBorrowPercent, 0, setBorrowAmount)}>
          100%
        </Button>
      </ButtonGroup>

      <Button
        minW="280px"
        appButtonType={ButtonType.Primary}
        onClick={onBorrow}
        isDisabled={true}
        isLoading={isSubmitting}
        loadingText={amountIsValid ? `Borrow ${market.underlyingSymbol}` : errorMessage}
        mb="2em"
      >
        {amountIsValid ? `Borrow ${market.underlyingSymbol}` : errorMessage}
      </Button>

      <MigrationAlert message="You are on a deprecated version of Cozy. Borrows are no longer allowed on this version." />
      <BorrowCapAlert borrowAmount={borrowAmount} setBorrowAmount={setBorrowAmount} market={market} />
    </Flex>
  );
};
export default BorrowTab;
