import {
  CozyEvents,
  EthereumTransaction,
  EthereumTransactionTypes,
  Market,
  Position,
  Toast,
  TransactionContextInterface,
  TransactionStatuses,
} from '@/types';
import { capitalizeFirstLetter, trackEvent, triggerAnalyticsProps } from '@/utils/analytics';
import { parseLog, showErrorToast } from './errorCodeHelpers';

import BigNumber from 'bignumber.js';
import ConfirmedToast from '@/components/transactions/ConfirmedToast';
import { ETH_NETWORKS } from '@/constants';
import FailedToast from '@/components/transactions/FailedToast';
import React from 'react';
import SentToast from '@/components/transactions/SentToast';
import SyncedToast from '@/components/transactions/SyncedToast';
import cErc20Abi from '@/abis/cErc20Abi';
import cEthAbi from '@/abis/cEthAbi';
import comptrollerAbi from '@/abis/comptrollerAbi';
import dsProxy from '@/abis/dsProxyAbi';
import dsProxyMulticall from '@/abis/dsProxyMulticallAbi';
import erc20abi from '@/abis/erc20Abi';
import { ethers } from 'ethers';
import { isEth } from './assetHelpers';
import maximillionAbi from '@/abis/maximillionAbi';

interface CleanUpParams {
  amount?: string;
  cleanUpFunction?: (number) => void;
  cozyEvent?: CozyEvents;
  hash: string;
  market?: Market;
  marketContract?: ethers.Contract;
  onConfirmed?: () => void;
  setIsSubmitting: ({ status: TransactionStatuses }) => void;
  provider?: ethers.providers.Provider;
  transactionContext: TransactionContextInterface;
  type: string;
}

interface InitializeContractsParams {
  comptrollerAddress?: string;
  marketAddress: string;
  maximillionAddress?: string;
  multicallAddress: string;
  proxyAddress: string;
  signer: ethers.Signer;
  underlyingAddress: string;
}

interface InitiailzedContracts {
  comptrollerContract: ethers.Contract | null;
  marketContract: ethers.Contract;
  maximillionContract: ethers.Contract | null;
  multicallContract: ethers.Contract;
  proxyContract: ethers.Contract;
  tokenContract: ethers.Contract;
}

const ONE_HOUR = 60 * 60;
const ONE_HUNDRED_YEARS = 60 * 60 * 24 * 356 * 100;

export const DELEGATECALL_ADDRESS = '0xde1Ede1Ede1eDE1edE1edE1EdE1EDE1EDe1EdE1e';

export const TRANSACTION_DESCRIPTIONS = {
  [EthereumTransactionTypes.Deposit]: 'deposit',
  [EthereumTransactionTypes.Redeem]: 'withdraw',
  [EthereumTransactionTypes.Borrow]: 'borrow',
  [EthereumTransactionTypes.Repay]: 'pay back',
  [EthereumTransactionTypes.Withdraw]: 'withdraw',
  [EthereumTransactionTypes.Invest]: 'invest',
  [EthereumTransactionTypes.Approve]: 'approve',
  [EthereumTransactionTypes.ProxyWallet]: 'proxy wallet deploy',
};

// Don't ever return decimal values for contract params
export const prepareContractParam = (amount: number | string, decimals: number): string => {
  return new BigNumber(amount).times(Math.pow(10, decimals)).toFixed(0);
};

export const initializeContracts = ({
  comptrollerAddress,
  marketAddress,
  maximillionAddress,
  multicallAddress,
  proxyAddress,
  signer,
  underlyingAddress,
}: InitializeContractsParams): InitiailzedContracts => {
  const comptrollerContract = comptrollerAddress
    ? new ethers.Contract(comptrollerAddress, comptrollerAbi, signer)
    : null;
  const marketContract = isEth(underlyingAddress)
    ? new ethers.Contract(marketAddress, cEthAbi, signer)
    : new ethers.Contract(marketAddress, cErc20Abi, signer);
  const maximillionContract = maximillionAddress
    ? new ethers.Contract(maximillionAddress, maximillionAbi, signer)
    : null;
  const multicallContract = new ethers.Contract(multicallAddress, dsProxyMulticall, signer);
  const proxyContract = new ethers.Contract(proxyAddress, dsProxy, signer);
  const tokenContract = new ethers.Contract(underlyingAddress, erc20abi, signer);

  return {
    comptrollerContract,
    marketContract,
    maximillionContract,
    multicallContract,
    proxyContract,
    tokenContract,
  };
};

export const determineGasLimit = (
  estimatedGasLimit: number | null,
  chainId: number,
  defaultGasLimit: number,
): Record<string, never> | { gasLimit: number } => {
  if (chainId === 1 && estimatedGasLimit != null) {
    return { gasLimit: estimatedGasLimit };
  }

  return {
    gasLimit: defaultGasLimit,
  };
};

export const defaultDeadline = (chainId: number): number => {
  const chainBuffer = chainId === 1 ? 0 : ONE_HUNDRED_YEARS;
  return Math.round(Date.now() / 1000) + ONE_HOUR + chainBuffer;
};

export const conditionallyToastVaultJustSynced = (
  transactions: EthereumTransaction[],
  positions: Position[],
  setTransactions: React.Dispatch<React.SetStateAction<EthereumTransaction[]>>,
  toast: Toast,
): EthereumTransaction[] => {
  if (positions == null || positions?.length === 0) return null;

  let needsUpdate = false;

  const transactionsWithSyncedStatus = transactions.map((transaction) => {
    const position = positions.find((position) => position.marketId === transaction.marketId);

    const uiIsNotSynced = !transaction.uiSynced;
    const transactionIsConfirmed = transaction.status === TransactionStatuses.Confirmed;
    const cozySubgraphHasBeenUpdated = transaction.blockNumber <= parseInt(position?.accrualBlockNumber);
    const investSubgraphHasBeenUpdated = [EthereumTransactionTypes.Invest, EthereumTransactionTypes.Withdraw].includes(
      transaction.type as EthereumTransactionTypes,
    )
      ? transaction.blockNumber <= position?.investLastUpdatedBlockNumber
      : true;

    if (
      position &&
      uiIsNotSynced &&
      transactionIsConfirmed &&
      cozySubgraphHasBeenUpdated &&
      investSubgraphHasBeenUpdated
    ) {
      needsUpdate = true;
      return { ...transaction, uiSynced: true };
    } else {
      return transaction;
    }
  });

  if (needsUpdate) {
    setTransactions(transactionsWithSyncedStatus);

    const everythingSynced = transactionsWithSyncedStatus.every((transaction) => transaction.uiSynced);
    if (everythingSynced) {
      toast.closeAll();

      toast({
        position: 'bottom-right',
        render: () => <SyncedToast />,
      });
    }
  }
  return transactionsWithSyncedStatus;
};

export const updateTransactionsInContext = (
  transaction: EthereumTransaction,
  transactionContext: TransactionContextInterface,
): void => {
  const { setTransactions } = transactionContext;

  setTransactions((transactions) => {
    const transactionInContext = transactions.find((tic) => tic.hash === transaction.hash);

    if (transactionInContext) {
      return transactions.map((tic) => {
        if (tic.hash === transaction.hash) {
          return { ...tic, ...transaction };
        } else {
          return tic;
        }
      });
    } else {
      return [...transactions, transaction];
    }
  });
};

export const conditionallyShowToast = (
  transaction: EthereumTransaction,
  transactionContext: TransactionContextInterface,
): void => {
  const { toast } = transactionContext;

  const transactionDescription = TRANSACTION_DESCRIPTIONS[transaction.type];

  if (transaction.status === (TransactionStatuses.Sent as string)) {
    toast({
      position: 'bottom-right',
      render: () => <SentToast transactionDescription={transactionDescription} />,
    });
  } else if (transaction.status === TransactionStatuses.Confirmed) {
    toast({
      position: 'bottom-right',
      render: () => <ConfirmedToast transactionDescription={transactionDescription} />,
    });
  } else if (transaction.status === TransactionStatuses.Failed) {
    toast({
      position: 'bottom-right',
      render: () => <FailedToast transactionDescription={transactionDescription} />,
    });
  }
};

export const catchError = (
  error: string,
  setIsSubmitting: ({ status: TransactionStatuses }) => void,
  properties: Record<string, unknown> = {},
): void => {
  console.log('Error submitting transaction:', error);

  const name = properties.type ? capitalizeFirstLetter(properties.type as EthereumTransactionTypes) : 'Unknown';

  trackEvent(`${name} Transaction Errored`, { ...properties, error: error });
  setIsSubmitting({ status: TransactionStatuses.Failed });
};

export const cleanUpStateAndNotify = async ({
  amount,
  cleanUpFunction,
  cozyEvent,
  setIsSubmitting,
  hash,
  marketContract,
  onConfirmed,
  provider,
  transactionContext,
  type,
  market,
}: CleanUpParams): Promise<void> => {
  const { blocknative } = transactionContext;

  setIsSubmitting({ status: TransactionStatuses.Sent });
  if (cleanUpFunction) {
    cleanUpFunction('0');
  }

  const { emitter } = blocknative.transaction(hash);

  emitter.on(
    'all',
    // TODO: FIGURE OUT WHY DECLARATION OF EmitterListener ISN'T BEING INTERPRETED PROPERLY
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    async (transaction: EthereumTransaction): Promise<void> => {
      const transactionWithMetadata: EthereumTransaction = {
        ...transaction,
        id: transaction.id,
        marketId: market?.id,
        type: type,
        uiSynced: false,
      };

      conditionallyShowToast(transactionWithMetadata, transactionContext);

      updateTransactionsInContext(transactionWithMetadata, transactionContext);

      if (transaction.status === TransactionStatuses.Failed) {
        trackEvent(
          `${capitalizeFirstLetter(type)} Transaction Failed`,
          transactionTrackingProps(amount, market, transaction),
        );
      }

      if (transaction.status === TransactionStatuses.Confirmed) {
        if (onConfirmed) {
          onConfirmed();
        }

        trackEvent(
          `${capitalizeFirstLetter(type)} Transaction Confirmed`,
          transactionTrackingProps(amount, market, transaction),
        );

        if (provider && cozyEvent) {
          const { errorCodes } = await findLog(hash, marketContract, cozyEvent, provider);

          if (errorCodes.length > 0) {
            trackEvent(`${capitalizeFirstLetter(type)} Transaction Failed Silently with codes`, {
              errorCodes,
              ...transactionTrackingProps(amount, market, transaction),
            });

            const errorTransaction: EthereumTransaction = { ...transaction, uiSynced: true };
            updateTransactionsInContext(errorTransaction, transactionContext);
            showErrorToast(errorCodes, transactionContext);
          }
        }
      }
    },
  );
};

const transactionTrackingProps = (amount: string, market: Market | null, transaction: EthereumTransaction) => {
  return {
    amount,
    marketId: market?.id,
    asset: market?.underlyingSymbol,
    transactionHash: transaction.hash,
    ...triggerAnalyticsProps(market?.trigger),
  };
};

/**
 * @notice: Returns true if event named `logName` was emitted by `contract`
 * in the provided `tx
 * @param hash: transaction hash
 * @param contract: Instance of an ethers Contract
 * @param logName: Name of the log to look for
 * @param provider: Provider to use
 * @returns receipt if Log was found, throws and prints error codes if not
 */
export const findLog = async (
  hash: string,
  contract: ethers.Contract,
  logName: string,
  provider: ethers.providers.Provider,
): Promise<{ errorCodes: any; log: any; receipt: ethers.providers.TransactionReceipt }> => {
  // Wait for the transaction to be mined, then get the transaction receipt
  const tx = await provider.getTransaction(hash);
  await tx.wait();

  const receipt = await provider.getTransactionReceipt(hash);

  // Use our custom parseLog method to parse logs, that way it does not throw on failure
  const logs = receipt.logs.map(parseLog(contract));

  // For each log in logs, find the first one with a name equal to our target `logName`
  const log = logs.filter((log) => log?.name === logName)[0];

  // Found, return the parsed log information and the receipt
  if (log) return { errorCodes: [], log, receipt };

  // If not found, let's search for Failure logs. If we find one, log the error codes and throw since we should
  // assume it's unsafe to continue execution
  const failureLog = logs.filter((log) => log?.name === 'Failure')[0];

  if (failureLog) {
    return {
      errorCodes: failureLog?.args.map((code) => code.toNumber()),
      log: failureLog,
      receipt: receipt,
    };
  }

  throw `Couldn't find log name or failure logs for transaction: ${tx.hash}`;
};

export const defaultProvider = (chainId: number): ethers.providers.BaseProvider => {
  return ethers.getDefaultProvider(ETH_NETWORKS[chainId], {
    infura: process.env.NEXT_PUBLIC_INFURA_PROJECT_ID,
    etherscan: process.env.NEXT_PUBLIC_ETHERSCAN_API_KEY,
    alchemy: process.env.NEXT_PUBLIC_ALCHEMY_API_KEY,
  });
};

export const checkIfTransactionsHavePendingTransaction = (
  transactions: EthereumTransaction[],
  transactionTypes: EthereumTransactionTypes[],
  marketId: string,
): boolean => {
  return (
    transactions?.filter((transaction) => {
      return (
        transaction.marketId == marketId &&
        transactionTypes.includes(transaction.type as EthereumTransactionTypes) &&
        (transaction.status === TransactionStatuses.Pending ||
          transaction.status === TransactionStatuses.Sent ||
          (transaction.status === TransactionStatuses.Confirmed && !transaction.uiSynced))
      );
    }).length > 0
  );
};

export const marketHasEnoughCash = (amount: string, market: Market): boolean => {
  return market.cash >= parseFloat(amount);
};
