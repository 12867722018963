import { Flex } from '@chakra-ui/react';
import { PositionTypes } from '@/types';
import React from 'react';
import SmallStat from '../shared/SmallStat';
import theme from '@/styles/theme';

interface Props {
  formattedBorrowRate: string;
  formattedInvestRate: string;
  formattedNetRate: string;
  formattedSupplyRate: string;
  netRate: number;
  positionType: PositionTypes;
}

const TriggerCardRates: React.FC<Props> = ({
  formattedBorrowRate,
  formattedInvestRate,
  formattedNetRate,
  formattedSupplyRate,
  netRate,
  positionType,
}: Props) => {
  return (
    <Flex justifyContent="space-between" alignItems="flex-start" flex={1}>
      {positionType === PositionTypes.Supply && (
        <SmallStat
          amount={formattedSupplyRate}
          label="Earning"
          amountColor={theme.utilityColors.successColor}
          unit="APY"
        />
      )}

      {positionType === PositionTypes.Borrow && (
        <SmallStat amount={formattedBorrowRate} unit="APY" label="Borrow Rate" />
      )}

      {positionType === PositionTypes.Invest && (
        <Flex flex={1}>
          <SmallStat amount={formattedInvestRate} label="Gross" amountColor="gray.500" />
          <SmallStat amount={formattedBorrowRate} label="Cost" />
          <SmallStat
            amount={formattedNetRate}
            label="Net"
            amountColor={netRate > 0 ? theme.utilityColors.successColor : null}
          />
        </Flex>
      )}
    </Flex>
  );
};
export default TriggerCardRates;
