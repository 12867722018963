import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

import { Market } from '@/types';
import PreRepaySteps from './PreRepaySteps';

interface Props {
  approveTransactionIsSubmitting: boolean;
  isApproved: boolean;
  isOpen: boolean;
  market: Market;
  onClose: () => void;
  proxyAddress: string;
  repayButton: ReactNode;
  setApproveTransactionIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
}

const PreRepayStepsModal: React.FC<Props> = ({
  approveTransactionIsSubmitting,
  isApproved,
  isOpen,
  market,
  onClose,
  proxyAddress,
  repayButton,
  setApproveTransactionIsSubmitting,
}: Props) => {
  return (
    <Modal size="md" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent borderRadius="2xl">
        <ModalBody px="2em" pb="1.5em" pt="-0.5em">
          <PreRepaySteps
            approveTransactionIsSubmitting={approveTransactionIsSubmitting}
            isApproved={isApproved}
            market={market}
            proxyAddress={proxyAddress}
            repayButton={repayButton}
            setApproveTransactionIsSubmitting={setApproveTransactionIsSubmitting}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PreRepayStepsModal;
