import { Market, PositionTypes } from '@/types';

import HowItWorks from './HowItWorks';
import React from 'react';
import { Stack } from '@chakra-ui/react';
import TriggerCard from '@/components/protection/TriggerCard';
import TriggeredHighlightCell from '@/components/funds/ProtectionTriggeredAlert';

interface Props {
  market: Market;
  positionType: PositionTypes;
}

const TriggerPanel: React.FC<Props> = ({ market, positionType }: Props) => {
  const { trigger } = market;

  return (
    <Stack
      flex={0.65}
      borderBottomRightRadius="xl"
      borderTopRightRadius="xl"
      spacing={6}
      backgroundColor="gray.50"
      py={16}
      px={10}
      justifyContent="center"
    >
      <TriggerCard positionType={positionType} market={market} />
      {trigger?.triggered && <TriggeredHighlightCell positionType={positionType} trigger={trigger} />}
      <HowItWorks positionType={positionType} />
    </Stack>
  );
};
export default TriggerPanel;
